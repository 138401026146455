<template>
  <div>
    <div style="width: 32px; height: 32px">
      <svg viewBox="0 0 312 312">
        <defs>
          <linearGradient
            x1="3.967%"
            y1="3.362%"
            x2="82.02%"
            y2="94.266%"
            id="2_linearGradient-1"
          >
            <stop stop-color="#FFF" stop-opacity="0.085" offset="0%"></stop>
            <stop stop-color="#FFF" stop-opacity="0.03" offset="20%"></stop>
            <stop stop-color="#FFF" stop-opacity="0.1" offset="40%"></stop>
            <stop stop-color="#FFF" stop-opacity="0.03" offset="60%"></stop>
            <stop stop-color="#FFF" stop-opacity="0.06" offset="80%"></stop>
            <stop stop-color="#FFF" stop-opacity="0" offset="100%"></stop>
          </linearGradient>
          <path
            d="M156.55 16.76h26.58c5.06 0 6.75 2.6 9.78 4.58 1.6 0 7.04 2.88 11.71 5.03 4.67 2.14 35.4 15.24 48.96 20.8 13.56 5.58 16.52 16.46 18.16 20.57 5.45 10.84 4.32 29.53 4.32 37.16v27.13c0 2.15-.62 2.76-3.12 3.74-12.74 4.35-32.02 3.2-36.3 3.2-4.27 0-4.33-2.02-5.15-4.13-.54-1.41-.54-7.1 0-17.08-.55-2.82-1.08-3.64-1.6-2.48-3.62 7.08-8.92 44.57-10 55.91-1.1 11.34-.87 30-.87 36.29 0 6.29 2.2 21.94 2.92 28.22a971 971 0 0 0 3.31 24.54c.68 4.52 1.74 8.66 2.79 12.85.74 1.9 0 3.79-1.05 5.2-1.05 1.43-5.97 4.68-12.55 8.14-6.59 3.46-14.81 5.27-20.91 6.12-6.1.86-21.65 2.3-36.98 2.3-15.32 0-29.61-1.19-36.97-2.3-7.36-1.1-13.74-2.66-20.91-6.12-7.18-3.46-11.5-6.71-12.55-8.13-1.05-1.42-1.79-3.3-1.05-5.21 1.05-4.19 2.1-8.33 2.78-12.85a971.72 971.72 0 0 0 3.32-24.54c.72-6.28 2.92-21.93 2.92-28.22 0-6.29.22-24.95-.87-36.29-1.08-11.34-6.38-48.83-10-55.91-.52-1.16-1.06-.34-1.6 2.48.54 9.98.54 15.67 0 17.08-.83 2.1-.89 4.13-5.16 4.13-4.27 0-23.56 1.15-36.3-3.2-2.5-.98-3.1-1.6-3.1-3.74V104.9c0-7.63-1.14-26.32 4.3-37.16 1.65-4.1 4.6-15 18.17-20.56a2812.85 2812.85 0 0 0 48.96-20.81c4.67-2.15 10.1-5.03 11.7-5.03 3.04-1.97 4.73-4.58 9.8-4.58h26.56z"
            id="2_path-2"
          ></path>
          <filter
            x="-5.7%"
            y="-3%"
            width="111.4%"
            height="109.6%"
            filterUnits="objectBoundingBox"
            id="2_filter-4"
          >
            <feMorphology
              radius="0.578"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dy="5"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="3.5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <path
            d="M2.898 88.597c.07-.1-1.93-12-2.08-14.96-.84-16.8-.84-30.15 6.2-43.9 3.98-7.8 13.96-18.28 23.71-24.04 3.3-1.95 5.73-2.915 9.48-4.711 12.93-4.076 28.283-3.374 35.94-3.555 5.29-.035 23.164-.456 35.723 3.39 4.836 1.48 7.697 2.766 11.277 4.876 9.76 5.77 19.73 16.25 23.71 24.03 7.04 13.76 7.04 27.1 6.2 43.91-.15 2.92-2.08 14.5-2.08 14.95-.23-.26-.46-.13-.69.38-3.62 7.08-8.92 44.57-10 55.91-1.09 11.34-.87 30-.87 36.29 0 6.28 2.2 21.94 2.92 28.22.4 3.39 1.86 14.76 3.32 24.54.67 4.52 1.73 8.66 2.78 12.85.74 1.9 0 3.79-1.05 5.2-1.05 1.43-5.96 4.68-12.55 8.14-6.58 3.46-14.8 5.27-20.9 6.12-6.1.86-21.66 2.3-36.98 2.3-15.33 0-29.62-1.19-36.98-2.3-7.36-1.1-13.73-2.66-20.9-6.12-7.18-3.46-11.5-6.71-12.55-8.13-1.05-1.42-1.8-3.3-1.05-5.21 1.05-4.19 2.1-8.33 2.78-12.85a972.812 972.812 0 0 0 3.31-24.54c.73-6.28 2.92-21.94 2.92-28.22 0-6.29.22-24.95-.86-36.29-1.09-11.34-6.39-48.83-10-55.91-.24-.53-.48-.65-.72-.36"
            id="2_path-5"
          ></path>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <mask id="2_mask-3" fill="#fff">
            <use xlink:href="#2_path-2"></use>
          </mask>
          <use
            fill="#000"
            filter="url(#2_filter-4)"
            xlink:href="#2_path-2"
          ></use>
          <!-- color de la camiseta -->
          <use
            class="sr-jersey3d__base"
            :fill="color"
            xlink:href="#2_path-2"
          ></use>
          <path
            stroke-opacity="0.15"
            stroke="#000"
            stroke-width="0.58"
            d="M156.15 13.29c8.47 0 16.94-.2 25.42-.58 1.88 0 3.3.93 5.1 2.98.4.44 1.76 2.09 2.02 2.4 2.13 2.46 3.91 3.87 6.36 4.63 2.85.86 4.98 1.7 9.3 3.67 4.99 2.3 36.13 15.55 48.94 20.81a29.63 29.63 0 0 1 16.42 15.93c.39.86.73 1.7 1.1 2.7l.79 2.07c2.08 4.14 3.34 9.63 3.96 16.3.48 5.12.55 9.7.43 16.98a216.2 216.2 0 0 0-.05 4v27.14c0 2.23-.62 2.96-3.3 4.02a61.23 61.23 0 0 1-12.88 2.63c-6.33.7-12.43.82-20.08.65l-2.01-.05a67.8 67.8 0 0 0-1.42-.02c-3.2 0-4.22-.87-5.09-3.38l-.33-.94c-.57-1.47-.57-7.15-.02-17.12a9.18 9.18 0 0 0-.77-2.64c-.12-.18-.11-.18-.3.23-2.97 5.82-8.3 38.36-9.97 55.8-.37 3.88-.61 8.81-.75 14.66-.12 5.58-.15 10.68-.12 18.18v3.43c0 2.77.42 7.49 1.2 14.07.11 1.04.24 2.12.38 3.26.2 1.64 1.18 9.4 1.35 10.86a968.4 968.4 0 0 0 3.3 24.52 112.43 112.43 0 0 0 2.72 12.55l.05.24c.7 1.8.22 3.72-1.08 5.49-1.12 1.52-6.2 4.83-12.65 8.21-5.77 3.04-13.24 5.07-21 6.16-8.51 1.2-24.02 2.3-37.02 2.3-13.76 0-28.36-1-37.02-2.3-8.28-1.25-14.37-2.97-20.99-6.15-6.34-3.06-11.32-6.42-12.65-8.22-1.3-1.77-1.79-3.68-1.1-5.45l.07-.28.28-1.13c1.28-5.1 1.92-8.02 2.43-11.42 1.22-8.2 2.74-19.63 3.3-24.52l1.35-10.86.4-3.26c.76-6.58 1.18-11.3 1.18-14.07v-3.43c.03-7.5.01-12.6-.12-18.18a212.2 212.2 0 0 0-.74-14.65c-1.67-17.45-7-49.99-9.98-55.82-.18-.4-.17-.4-.29-.22-.24.37-.5 1.26-.77 2.56.55 10.05.55 15.73-.02 17.2-.1.25-.1.23-.34.94-.86 2.51-1.87 3.38-5.08 3.38-.33 0-.77 0-1.42.02l-2 .05c-7.67.17-13.76.05-20.1-.65a61.22 61.22 0 0 1-12.88-2.64c-2.67-1.05-3.3-1.78-3.3-4.01v-27.13c0-.9 0-1.17-.05-4.01-.11-7.28-.04-11.86.43-16.97.63-6.68 1.88-12.17 3.96-16.29l.79-2.09c.38-1 .72-1.84 1.1-2.7A29.63 29.63 0 0 1 59.02 47.2a2821.3 2821.3 0 0 0 48.96-20.8 66.13 66.13 0 0 1 5.59-2.35l.1-.04c1.53-.55 1.64-.6 2.48-.92 3.09-.65 5.25-2.19 7.63-4.94.34-.39 1.92-2.31 2.35-2.8 1.6-1.8 2.92-2.64 4.63-2.64 8.47.39 16.94.58 25.4.58z"
          ></path>
          <g mask="url(#2_mask-3)">
            <g transform="translate(79.333 28.022)">
              <mask id="2_mask-6" fill="#fff">
                <use xlink:href="#2_path-5"></use>
              </mask>
            </g>
          </g>
          <use fill="url(#2_linearGradient-1)" xlink:href="#2_path-2"></use>
        </g>
        <text
          y="35%"
          x="50%"
          text-anchor="middle"
          class="sr-jersey3d__player-number srt-stroke-soccer-promotion-5"
          font-size="80"
          stroke-width="4"
          font-weight="700"
          dy="0.4em"
          dx="0em"
          letter-spacing="2"
          fill="#000000"
        ></text>
        <text
          y="35%"
          x="50%"
          text-anchor="middle"
          class="sr-jersey3d__player-number-overlay"
          font-size="80"
          stroke-width="0"
          font-weight="700"
          dy="0.4em"
          dx="0em"
          letter-spacing="2"
          fill="#000000"
          stroke="transparent"
        ></text>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "shirt_prematch",
  props:["color"]
};
</script>

<style>
</style>