<template>
<div>
    <div class="accordion" id="accordionTeaser">
        <div class="accordion-item" style="border:none">
            <div class="accordion-header" :style="skin.prematch_preview.fondo" id="headingTeaser">
                <button :style="skin.prematch_lobby.bar" class="accordion-button p-2 collapsed" data-bs-toggle="collapse" data-bs-target="#d-teaser" aria-expanded="false" aria-controls="d-teaser">
                    <div>TEASER</div>
                </button>
            </div>
            <div id="d-teaser" class="accordion-collapse collapse"  aria-labelledby="headingTeaser" :data-bs-parent="'#accordionTeaser'">
                <div class="accordion-body p-0" :style="skin.prematch_preview.fondo">
                    <div :style="skin.prematch_lobby_main.bar">
                        <ul class="nav p-1" :style="nav_active">
                            <li v-for="deporte in deportes.main"
                                v-bind:key="deporte.id"
                                :id="deporte.id+'teaser'"
                                style="cursor:pointer"
                                class="nav-item nav-teaser" :class="deporte.id == 3 ? 'nav_active': ''"
                                @click="select_sport(deporte.id)">
                                <span class="m-2">{{ deporte.name }}</span>
                            </li>
                        </ul> 
                    </div>
                    <div>
                        <div class="accordion p-1" id="accordionPaises">
                            <div class="accordion-item" style="border:none">
                                 <div class="accordion-header" :style="skin.prematch_preview.fondo" id="headingPaises">
                                    <button id="countries_button" :style="skin.prematch_lobby_main.bar+skin.sport_table.text" class="accordion-button p-2 collapsed" data-bs-toggle="collapse" data-bs-target="#paises" aria-expanded="true" aria-controls="paises">
                                        <div>{{translate("select_country")}}</div>
                                    </button>
                                </div>
                                <div id="paises" class="accordion-collapse collapse show"  aria-labelledby="headingPaises" :data-bs-parent="'#accordionPaises'">
                                    <div class="accordion-body p-0" :style="skin.prematch_preview.fondo">
                                        <div
                                        v-for="pais in regiones"
                                        v-bind:key="pais.id"
                                        class="p-1"
                                        >
                                            <div style="display:flex;width:100%;cursor:pointer" @click="select_country(pais.id)">
                                                <div style="width:5%" class="mx-1"><span class="rounded-circle" :class="'phoca-flag ' + country_flag(pais.name,pais.alias)" style="max-width:20px;height:20px"></span></div>
                                                <div style="width:95%"><span>{{ pais.name }}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-1" v-if="selected_country!=0">
                            <schedule
                                v-bind:skin="skin"
                                v-bind:language="language"
                                v-bind:translation="translation"
                            />
                        </div>
                        <div v-if="selected_sport==6 && selected_country!=0" class="d-flex py-3" :style="hoverCss" style="justify-content: center">
                            <div v-if="teaser_type==6||teaser_type==0" 
                                :class="teaser_selection==6||teaser_selection==4?'hover':''" 
                                @click="select_teaser(6,'6points')" style="cursor:pointer;" 
                                id="6points" 
                                @mouseover="teaser_selection==6||teaser_selection==4?'':hover('6points')" 
                                @mouseleave="teaser_selection==6||teaser_selection==4?'':hover2('6points')" 
                                :style="skin.casino.button" class="m-2 p-1 text-center">{{translate("6_point_taster")}}
                            </div>
                            <div v-if="teaser_type==6.5||teaser_type==0" 
                                :class="teaser_selection==6.5||teaser_selection==4.5?'hover':''" 
                                @click="select_teaser(6.5,'6-5points')" 
                                style="cursor:pointer;" id="6-5points" 
                                @mouseover="teaser_selection==6.5||teaser_selection==4.5?'':hover('6-5points')" 
                                @mouseleave="teaser_selection==6.5||teaser_selection==4.5?'':hover2('6-5points')" 
                                :style="skin.casino.button" class="m-2 p-1 text-center">{{translate("65_point_taster")}}
                            </div>
                            <div v-if="teaser_type==7||teaser_type==0" 
                                :class="teaser_selection==7||teaser_selection==5?'hover':''" 
                                @click="select_teaser(7,'7points')" 
                                style="cursor:pointer;" id="7points" 
                                @mouseover="teaser_selection==7||teaser_selection==5?'':hover('7points')" 
                                @mouseleave="teaser_selection==7||teaser_selection==5?'':hover2('7points')" 
                                :style="skin.casino.button" class="m-2 p-1 text-center">{{translate("7_point_taster")}}
                            </div>
                        </div>
                        <div v-if="selected_sport==3 && selected_country!=0" class="d-flex py-3" :style="hoverCss" style="justify-content: center">
                            <div v-if="teaser_type==4||teaser_type==0" 
                                :class="teaser_selection==4||teaser_selection==6?'hover':''" 
                                @click="select_teaser(4,'4points')" 
                                style="cursor:pointer;" id="4points" 
                                @mouseover="teaser_selection==4||teaser_selection==6?'':hover('4points')" 
                                @mouseleave="teaser_selection==4||teaser_selection==6?'':hover2('4points')" 
                                :style="skin.casino.button" class="m-2 p-1 text-center">{{translate("4_point_taster")}}
                            </div>
                            <div v-if="teaser_type==4.5||teaser_type==0" 
                                :class="teaser_selection==4.5||teaser_selection==6.5?'hover':''" 
                                @click="select_teaser(4.5,'4-5points')" 
                                style="cursor:pointer;" id="4-5points" 
                                @mouseover="teaser_selection==4.5||teaser_selection==6.5?'':hover('4-5points')" 
                                @mouseleave="teaser_selection==4.5||teaser_selection==6.5?'':hover2('4-5points')" 
                                :style="skin.casino.button" class="m-2 p-1 text-center">{{translate("45_point_taster")}}
                            </div>
                            <div v-if="teaser_type==5||teaser_type==0" 
                                :class="teaser_selection==5||teaser_selection==7?'hover':''" 
                                @click="select_teaser(5,'5points')" 
                                style="cursor:pointer;" id="5points" 
                                @mouseover="teaser_selection==5||teaser_selection==7?'':hover('5points')" 
                                @mouseleave="teaser_selection==5||teaser_selection==7?'':hover2('5points')" 
                                :style="skin.casino.button" class="m-2 p-1 text-center">{{translate("5_point_taster")}}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div v-if="!timeLoading">
                            <div v-if="screen_width>skin.break_point && selected_country!=0">
                                <div class="px-3">
                                    <Teaser
                                        :Skin="skin"
                                        :translation="translation"
                                        :language="language"
                                    ></Teaser>
                                </div>
                            </div>
                            <encuentro v-for="game in events"
                            v-bind:game="game"
                            v-bind:sport="selected_sport"
                            v-bind:Skin="skin"
                            v-bind:key="game.id"
                            v-bind:language="language"
                            v-bind:translation="translation"
                            v-bind:teaserVal="teaser_selection"
                            />
                            <div v-if="selected_country!=0" @click="more_events()" class="text-center p-3">
                                <span :style="skin.prematch_lobby_main.more_events" style="cursor:pointer" class="p-2">{{translate("load_more_events")}}</span>
                            </div>
                        </div>
                        <div v-else class="text-center py-5">
                            <div class="spinner-border" :style="skin.loader" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import encuentro from '../main_events_prematch2/teaser_encuentro.vue';
import Teaser from '../sport_tables/teaser.vue';
import schedule from '../main_menu/schedule.vue';
const flags = require('../../../../assets/countries.json')
export default {
    name:"teaser",
    props:['skin','translation','language'],
    components:{
        encuentro,
        Teaser,
        schedule
    },
    data(){
        return{
            selected_sport:3,
            selected_country:0,
            screen_width:0,
            events_limit:11,
            teaser_selection:4
        }
    },
    computed:{       
        deportes(){
            var main = [3,6];
            var list={
                main:[]
            };
            let data = this.$store.getters['prematch_2/getSports'];
                Object.keys(data).forEach(sport=>{
                    if(main.includes(data[sport].id)){
                        list.main.push({
                            name:data[sport].name,
                            id:data[sport].id
                        })
                    }
                });
            return list;
        },
        regiones(){
            let regions = [];
            let regions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideRegionsPrematch;
            let data = this.$store.getters['prematch_2/getSports'];
            Object.keys(data).forEach(sport=>{
                if(data[sport].id == this.selected_sport){
                    Object.keys(data[sport].region).forEach(region=>{
                        if(data[sport].region[region]){
                            if(!regions_banned.includes(data[sport].region[region].alias) && data[sport].region[region].competition){
                                let exists_games = false;
                                Object.keys(data[sport].region[region].competition).forEach(league=>{
                                    if(data[sport].region[region].competition[league]){
                                        if(data[sport].region[region].competition[league].name){
                                            if(!data[sport].region[region].competition[league].name.includes('Resultado')){
                                                Object.keys(data[sport].region[region].competition[league].game).forEach(events=>{
                                                    if(data[sport].region[region].competition[league].game[events]){
                                                        exists_games = true;
                                                    }
                                                })
                                            }
                                        }
                                    }
                                })
                                if(exists_games){
                                    regions.push(data[sport].region[region])
                                }
                            }
                        }
                    })
                }
            })
            regions.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            return regions
        },
        events(){
            let events = [];
            let ligas = [];
            let count = 0;
            let data = this.$store.getters['prematch_2/getSports'];
            let regions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideRegionsPrematch;
            let words_banned = this.$store.getters['getConfig'].SkinConfig.main.hideWords;
            let ligas_banned = this.$store.getters['getConfig'].SkinConfig.main.hideCompetitionsPrematch;
            Object.keys(data).forEach(sport=>{
                if(data[sport].id == this.selected_sport){
                    Object.keys(data[sport].region).forEach(pais=>{
                    if(data[sport].region[pais]){
                        if(!regions_banned.includes(data[sport].region[pais].alias) && data[sport].region[pais].id == this.selected_country){
                            if(data[sport].region[pais].competition){
                                Object.keys(data[sport].region[pais].competition).forEach(liga=>{
                                    data[sport].region[pais].competition[liga].sport = data[sport].id;
                                    data[sport].region[pais].competition[liga].sport_alias = data[sport].alias;
                                    data[sport].region[pais].competition[liga].country = data[sport].region[pais].name;
                                    data[sport].region[pais].competition[liga].country_id = data[sport].region[pais].id;
                                    ligas.push(data[sport].region[pais].competition[liga]);
                                });
                            }
                        }
                    }
                });
                }
                
            });
            ligas.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            ligas.forEach(liga=>{
                if((liga ? (liga.game ? true : false) : false)){
                    Object.keys(liga.game).forEach(element=>{
                        if(liga.game[element]){
                            if(liga.game[element] && liga.game[element].info && !liga.game[element].favorite){
                                let banned = false;
                                words_banned.forEach(word => {
                                    if(liga.game[element].team1_name){
                                        if(liga.game[element].team1_name.includes(word)){
                                            banned = true;
                                        }
                                    }
                                    if(liga.game[element].team2_name){
                                        if(liga.game[element].team2_name.includes(word)){
                                            banned = true;
                                        }
                                    }            
                                });
                                ligas_banned.forEach(word => {
                                    if(liga.name){
                                        if(liga.name.includes(word)){
                                            banned = true;
                                        }
                                    }           
                                });
                                if(!banned && liga.game[element].market){
                                    liga.game[element]['sport'] = this.$props.deporte;
                                    if(count<this.events_limit && Object.keys(liga.game[element].market).length>0){
                                        liga.game[element].liga = liga.name;
                                        liga.game[element].liga_id = liga.id;
                                        liga.game[element].sport = liga.sport;
                                        liga.game[element].country = liga.country;
                                        liga.game[element].country_id = liga.country_id;
                                        liga.game[element].sport_alias = liga.sport_alias;
                                        events.push(liga.game[element]);
                                        count++;
                                    }
                                }
                            }  
                        } 
                    });
                }
            });
            return events; 
        },
        teaser_type(){
            let data = this.$store.getters['getBets'];
            let sport = this.selected_sport;
            let value = 0;
            if(Object.keys(data).length>0){
            Object.keys(data).forEach(bet => {
                if(data[bet]){
                if(data[bet].extra_data.market=='Teaser'){
                    value = data[bet].teaser.value;
                }
                }
            })
            }
            if(sport==3){
            if(value==6){
                value = 4
            }
            else if(value==6.5){
                value = 4.5
            }
            else if(value==7){
                value = 5
            }
            }
            if(sport==6){
            if(value==4){
                value = 6
            }
            else if(value==4.5){
                value = 6.5
            }
            else if(value==5){
                value = 7
            }
            }
            if(value!=0){this.teaser_selection=value;}
            return value;
        },
        nav_active(){
            return {
                '--nav-active-color':this.$props.skin.prematch_lobby_main.nav_active_color,
                '--nav-active-bar':this.$props.skin.prematch_lobby_main.nav_active_bar
            };
        },
        hoverCss(){
            return {
                '--fondoH':this.$props.skin.prematch_all_odds.hover_fondo,
                '--textH':this.$props.skin.prematch_all_odds.hover_text,
            }
        },
        timeLoading(){
            return this.$store.getters['prematch_2/getTimeLoading'];
        },
    },
    methods:{
        country_flag(name,alias) {
            let flag = []
            flags.countries.forEach(country => {
                if(country.name_es == name || country.name_en == alias){
                    flag.push(country.code.toLowerCase());
                }
                else if(country.name_en == alias){
                    flag.push(country.code.toLowerCase());
                }
            });
            return flag;
        },
        select_country(id){
            this.selected_country=id;
            document.getElementById('countries_button').click();
        },
        select_sport(id){
            this.selected_country=0;
            let items = document.getElementsByClassName("nav-teaser");
            [...items].forEach(item=>{
                item.classList.remove('nav_active');
            });
            if(document.getElementById(id+'teaser')){
                 document.getElementById(id+'teaser').classList.add('nav_active');
            }
            if(id==6){
                if(this.teaser_selection==4){this.teaser_selection=6}
                if(this.teaser_selection==4.5){this.teaser_selection=6.5}
                if(this.teaser_selection==5){this.teaser_selection=7}
            }
            if(id==3){
                if(this.teaser_selection==6){this.teaser_selection=4}
                if(this.teaser_selection==6.5){this.teaser_selection=4.5}
                if(this.teaser_selection==7){this.teaser_selection=5}
            }
            this.selected_sport = id;
        },
        sizeControl(){
            this.screen_width=window.innerWidth;
        },
        more_events(){
            this.events_limit *= this.events_limit * 2 > 100 ? 1 : 2;
        },
        hover: function (id) {
            document.getElementById(id).classList.add('hover');
        },
        hover2: function (id) {
            document.getElementById(id).classList.remove('hover');
        },
        select_teaser(val,id){
            if(this.teaser_selection != val){
                document.getElementById(id).classList.add('hover');
                this.teaser_selection = val;
            }
        },
        translate(text){
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
    },
    mounted() {
        window.addEventListener('resize', ()=>{this.sizeControl();});
    },
    created(){
        this.sizeControl();
    }
}
</script>

<style scoped>
  .nav_active{
      color:var(--nav-active-color);
      border-bottom: var(--nav-active-bar);
  }
  .hover{
    background-color: var(--fondoH) !important;
    color:var(--textH) !important;
}
</style>