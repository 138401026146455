import axios from 'axios';
export default {
    namespaced: true,
    state:{
        eventos_deportes:{},
        ligas_array:[],
        ligas_array2:[],
        deporte_liga:true,
        first_load:false,
        search_result:{},
        time:'',
        search_text:""
    },
    mutations:{
        load_prematch_events(state,payload){
            var list={
                main:[],
                other:[]
            };
            let data = state.eventos_deportes;
            state.ligas_array = [];
            let main_countries=["ec","es","it","gb","de", "int"];
            let second_countries=["co","ar","br","cl"];
            let main_leagues=[
                "Ecuador",
                "UEFA Champions League",
                "UEFA Europa League",
                "Copa Libertadores",
                "Italy Serie A",
                "England Premier League",
                "Spain Copa del Rey",
                "Germany Bundesliga I",
            ];
            function get_ligas(type,countries_list, leagues_list){
                let count = 0;
                Object.keys(data).forEach(element => {
                    if(data[element].id == payload){
                        data[element].countries.forEach(country => {
                            if('leagues' in country){
                                Object.keys(country.leagues).forEach(liga=>{
                                    country.leagues[liga]['country'] = {
                                        name_es: country.name_es,
                                        name_en: country.name_en,
                                        code: country.code
                                    },
                                    country.leagues[liga]['sport'] = {
                                        name_es: data[element].name_es,
                                        name_en: data[element].name_en,
                                        id: data[element].id
                                    }
                                    if(type == "main"){
                                        leagues_list.forEach(name => {
                                            if(country.leagues[liga].name.includes(name) && countries_list.includes(country.code)){
                                                list.main.push(country.leagues[liga]);
                                                count++;
                                            }
                                        });
                                    }
                                    else if(type=="second"){
                                        if(countries_list.includes(country.code) && count < 8){
                                            list.other.push(country.leagues[liga]);
                                            count++;
                                        }
                                    }
                                    else if(type=="other" && count < 5){
                                        list.other.push(country.leagues[liga]);
                                        count++;
                                    }
                                });
                            }
                        });
                    }
                });
                return count;
            }
            let count_main = get_ligas('main',main_countries,main_leagues);
            let count_second = count_main < 5 ? get_ligas('second',second_countries,[]) : 6;
            let count_others = count_second < 6 ? get_ligas('other',[],[]) : 0
            list.other.forEach(liga => {
               let datos = {
                    pais:{
                        name_es:liga.country.name_es,
                        name_en:liga.country.name_en},
                    liga:liga,
                    deporte:{
                        name_es:liga.sport.name_es,
                        name_en:liga.sport.name_en,
                        id:liga.sport.id},
                    pais_code:liga.country.code
                };
                let payload = {"type":1,"datos":datos}
                this.commit('prematch/add_liga', payload);
            });
            list.main.forEach(liga => {
               let datos = {
                    pais:{
                        name_es:liga.country.name_es,
                        name_en:liga.country.name_en},
                    liga:liga,
                    deporte:{
                        name_es:liga.sport.name_es,
                        name_en:liga.sport.name_en,
                        id:liga.sport.id},
                    pais_code:liga.country.code
                };
                let payload = {"type":1,"datos":datos}
                this.commit('prematch/add_liga', payload);
            });    
        },
        getData(state, payload){
            state.eventos_deportes = payload;
            if(!state.first_load){
                this.commit('prematch/load_prematch_events',1);
                state.first_load = true;
            }
        },
        select_sport(state, payload){
            state.selected_sport = payload;
        },
        setTime(state,payload){
            state.time = payload;
        },
        add_liga(state, payload){
            if(payload.type == 1){
                state.deporte_liga=false;
                state.search_result=false;
                state.ligas_array.unshift(payload.datos);
            }
            else{
                state.ligas_array2.unshift(payload.datos);
            }
        },
        delete_liga(state, payload){
            if(payload.type==1){
                state.ligas_array = state.ligas_array.filter(liga => liga.liga != payload.datos.liga);
                if(state.ligas_array.length < 1){
                    state.deporte_liga=true;
                    state.search_result=false;
                    state.ligas_array2=[];
                }
            }
            else{
                state.ligas_array2 = state.ligas_array2.filter(liga => liga.liga != payload.datos.liga);
            }
        },
        delete_all_ligas(state){
            state.ligas2_array = [];
        },
        show_ligas(state){
            state.deporte_liga=false;
            state.search_result=false;
            state.ligas_array = state.ligas_array2;
        },
        close_search(state){
            state.deporte_liga=true;
            state.search_result=false;
            state.search_text="";
        },
        search(state,payload){
            state.search_result=payload.results;
            state.deporte_liga=false;
            state.search_text = payload.text;
        }
    },
    actions:{
        async getData(state,payload){
            try {
                const response = await axios.get(
                  process.env.VUE_APP_SERVER_URL+"get_upcoming_events/time/"+payload
                );
                state.commit("setTime",payload);
                state.commit("getData", response.data);
              } catch (error) {
                console.log(error);
              }
        },
        async add_liga(state, payload){
            state.commit("add_liga",payload);
        },
        async add_liga(state, payload){
            state.commit("add_liga",payload);
        },
        async delete_liga(state, payload){
            state.commit("delete_liga",payload);
        },
        async select_sport(state, payload){
            state.commit("select_sport",payload);
        },
        async delete_all_ligas(state){
            state.commit("delete_all_ligas");
        },
        async load_prematch_events(state, payload){
            state.commit("load_prematch_events",payload);
        },
        async show_ligas(state){
            state.commit("show_ligas");
        },
        async close_search(state){
            state.commit("close_search");
        },
        async search(state,payload){
            try {
                const response = await axios.get(
                process.env.VUE_APP_SERVER_URL+"search/text/" + payload.text +
                    "/time/"+payload.time
                );
                let data = {
                    results:response.data,
                    text: payload.time,
                }
                state.commit("search",data);
            } catch (error) {
                console.log(error);
            }
        },
    },
    getters:{
        getPrematchData: state => state.eventos_deportes,
        getTime: state => state.time,
        getLigasArray: state => state.ligas_array,
        getLigasArray2: state => state.ligas_array2,
        getDeporteLiga: state => state.deporte_liga,
        getSearchResult: state => state.search_result,
        getSearchText: state => state.search_text,
    }
}