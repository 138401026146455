<template>
  <div
    :id="'collapse-' + encuentro.id"
    class="accordion-collapse"
    :class="collapsed ? 'collapse':'show'"
    aria-labelledby="headingOne"
    :data-bs-parent="'#match-' + encuentro.id"
  >
    <div class="accordion-body p-0" :style="Skin.fondo1">
      <div>
        <ul class="nav p-1" :style="Skin.fondo3">
            <li 
                v-for="(market, key) in markets"
                v-bind:key="key"
                :id="'pills-' + key + '-tab-' + encuentro.id"
                data-bs-toggle="pill"
                :data-bs-target="'#pills-' + key + '-' + encuentro.id"
                role="tab"
                :aria-controls="'pills-' + key"
                aria-selected="true"
                style="cursor:pointer"
                v-on:click="active('pills-' + key + '-tab-' + encuentro.id)"
                class="nav-item"
                :class="'mk-'+encuentro.id + ' ' + (key=='main' ? 'nav_active' : '')">
                <span class="m-2">{{ translate(key) }}</span>
            </li>
        </ul> 
      </div>
      <div class="tab-content m-1" id="pills-tabContent">
        <div
          v-for="(group, key) in markets"
          v-bind:key="key + 'content'"
          class="tab-pane fade"
          :class="key == 'main' ? 'show active' : ''"
          :id="'pills-' + key + '-' + encuentro.id"
          role="tabpanel"
          :aria-labelledby="'pills-' + key + '-tab-' + encuentro.id"
        >
          <div class="row">
            <div
              v-for="(column,idx) in group"
              v-bind:key="key +'-'+ idx"
              :class="(group.length>1 ? 'col-6' : 'col-12')+' '+Skin.borde_round2"
            >
              <table
                v-for="(market, idx2) in column"
                v-bind:key="idx2"
                :style="Skin.fondo2+Skin.borde_round2"
                style="overflow:hidden"
                class="
                  table
                  table-borderless
                  text1
                  align-middle
                "
              >
                <thead class="align-middle" :style="Skin.fondo4">
                  <tr>
                    <th class="text-start p-0">
                      <span style="color: white; font-size: medium" class="p-2" :style="Skin.text3">{{
                        translate(market.market_type)
                      }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="row px-2 py-2 pt-0 pb-0">
                        <div
                          v-for="logro in market.odds"
                          v-bind:key="logro.id + 'content'"
                          class="col col-4 pe-2 px-2 py-1 pb-1"
                          :style="Skin.borde_round4"
                          :class="(selected_odds[logro.id] ? ' hover' : '')"
                          :id="logro.id + 'content'"
                          @mouseover="
                            selected_odds[logro.id]
                              ? ''
                              : hover(logro.id + 'content')
                          "
                          @mouseleave="
                            selected_odds[logro.id]
                              ? ''
                              : hover2(logro.id + 'content')
                          "
                          @click="
                            odd_selected(
                              encuentro.id,
                              logro,
                              market.market_type,
                              encuentro.players[0].name,
                              encuentro.players[1].name,
                              encuentro.time,
                              key
                            )
                          "
                        >
                          <div v-if="screen_width>Skin.break_point" class="row px-2 pe-2">
                            <div class="col-9 text-left p-0">
                              <span :title="odd_header(logro.header,logro.name,logro.handicap,logro.team,encuentro.players[0].name,encuentro.players[1].name,market.market_type)" 
                              class="long_text">
                              {{ odd_header(logro.header,logro.name,logro.handicap,logro.team,encuentro.players[0].name,encuentro.players[1].name,market.market_type) }}
                              </span>
                            </div>
                            <div class="col-3 text-end p-0"> 
                              <span>
                                {{ logro.odds[logro.odds.length-4]=="."?logro.odds.slice(0, -1):logro.odds }}
                              </span>
                            </div>
                          </div>
                          <div v-else class="text-center">
                            {{
                              odd_header(logro.header,logro.name,logro.handicap,logro.team,encuentro.players[0].name,encuentro.players[1].name,market.market_type)
                            }}<br />
                            {{ logro.odds[logro.odds.length-4]=="."?logro.odds.slice(0, -1):logro.odds }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "all_odds",
  props: ["encuentro", "liga", "Skin", "translation", "language","collapsed"],
  data() {
    return {
      qty_columns:1,
      screen_width:0,
    };
  },
  computed:{
    selected_odds(){
        let odds = {};
        let data = this.$store.getters['getBets'];
        Object.keys(data).forEach(bet => {
          if(data[bet]){
            odds[bet] = true;
          }
        });
        return odds;
      },
    markets(){
      let data2 = {};
      let data = [];
      let data3 = [];
      Object.entries(this.$props.encuentro.markets.odds.results[0]).forEach(market => {
          if(market[1] != 'undefined' && market[1]){
              data[market[0]] = {"order":market[0]=="main"?1:2,"markets":[]};
          }
      });
      let order = Object.keys(data).sort((a,b)=>{return data[a].order - data[b].order});
      order.forEach(item=>{
        data3[item] = data[item]; 
      });
      Object.entries(this.$props.encuentro.markets.odds.results[0]).forEach(market => {
          if(market[1].sp != 'undefined' && market[1].sp){
            Object.keys(market[1].sp).forEach(market2=>{
              market[1].sp[market2].market_type = market2;
              data3[market[0]].markets.push(market[1].sp[market2]);
            })
          }
      });
      Object.entries(data3).forEach(element => {
        let columns = [];
        let mid = Math.ceil(element[1].markets.length / this.qty_columns);
        for (let col = 0; col < this.qty_columns; col++) {
          columns.push(element[1].markets.slice(col * mid, col * mid + mid))
        }
        data2[element[0]] = columns;
      });
      return data2;
    }
  },
  methods: {
    sizeControl(){
      this.screen_width=window.innerWidth;
      if(window.innerWidth<450){
        this.qty_columns = 1;
      }
      else{
        this.qty_columns = 1;
      }
    },
    hover(id){
            document.getElementById(id).classList.add('hover');
        },
    hover2(id){
        document.getElementById(id).classList.remove('hover');
    },
    translate(text) {
      if (this.$props.translation[text]) {
        return this.$props.translation[text][this.$props.language];
      }
      else{
        var new_text_serched = text.replace(/ /g, "_");
        if (this.$props.translation[new_text_serched]) {
           return this.$props.translation[new_text_serched][this.$props.language];
        }
      }
      return text;
    },
    format_date: function (date) {
      var date_str = this.safe_date(date)+" UTC";
      date = new Date(date_str);
      var formated =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        date.getHours() +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2);
      return formated;
    },
    safe_date(date){
      var parsed_array_1 = date.split(" ");
      var parsed_array_2 = parsed_array_1[0].split("-");
      var date_str = parsed_array_2[1]+"/"+parsed_array_2[2]+"/"+parsed_array_2[0]+" "+parsed_array_1[1];
      return date_str;
    },
    odd_header(header,name,handicap,team,home,away,category) {
      var str = "";
      if (header) {
        if (category == "correct_score" || category == "half_time_correct_score" || 
        category=="1st_10_minute_correct_score" || category=="1st_period_correct_score") {
          str += "";
        }
        else if(category=="first_set_total_breaks_of_serve" || 
        category=="result_total_goals" || 
        category=="match_result_and_total_games" ||
        category=="5_innings" ||
        category=="1st_quarter_result_and_total" ||
        category=="1st_game_winner_and_total_double" ||
        category=="1st_half_result_and_total" ||
        category=="result_and_both_teams_to_score_'x'_points" ||
        category=="result_and_total" ||
        category=="1st_inning_hits"){
          str+="";
        }
        else if((category=="game_lines" || category=="1st_half"|| category=="1st_quarter" ||
        category=="1st_half_lines_2_way" || category=="1st_quarter_lines_2_way") && name =="Total"){
          if(handicap.split(" ")[0]=="O" || handicap.split(" ")[0]=="U"){
            str+="";
          }
        }
        else {
          if (header == "1") {
            str += home;
          } else if (header == "2") {
            str += away;
          } else {
            str += this.translate(header);
          }
        }
      }
      if (name) {
        if (category == "correct_score" || category == "half_time_correct_score" || 
        category=="1st_10_minute_correct_score" || category=="1st_period_correct_score") {
          var name_formated = name.split("-");
          if (header == "2") {
            str += " " + name_formated[1] + "-" + name_formated[0];
          } else {
            str += " " + name;
          }
        }
        else if(category=="first_set_total_breaks_of_serve" || 
                category=="result_total_goals" || 
                category=="match_result_and_total_games" ||
                category=="1st_quarter_result_and_total" ||
                category=="1st_game_winner_and_total_double" ||
                category=="1st_half_result_and_total" ||
                category=="result_and_both_teams_to_score_'x'_points" ||
                category=="result_and_total"){
          var player2= name=="1"?home:(name=="2"?away:this.translate(name));
          str+=player2+" / "+this.translate(header);
        }
        else if(category=="5_innings"){
          if(name!="Total"){
            str+=name.slice(0, -3);
            str+= " "+team;
          }
          else{
            str+=header=="1"?home:away;
          }
        }
        else if(category == "to_score_in_half" || category=="1st_half_team_to_score_x_points"){
          if (team == "1") {
            str +=" "+this.translate(name)+" "+ home;
          } else{
            str += " "+this.translate(name)+" "+away;
          }
        }
        else if(category=="1st_inning_hits"){
          str += this.translate(header);
          str+=" "+name;
        }
        else if(category=="winning_margins" || category=="winning_margin" || category=="1st_10_minute_alternative_total_3_way" ){
          str+=" "+name;
        }
        else {
          if (name == "1") {
            str += str!=""?" "+home:home;
          } else if (name == "2") {
            str += str!=""?" "+away:away;
          } else {
            if(this.translate(name) == name){
              var name_splited = name.split(" ");
              if (name_splited.length > 1) {
                for (let i = 0; i < name_splited.length; i++) {
                  str +=" "+ this.translate(name_splited[i]) + " ";
                }
                str.slice(0, -1);
              } else {
                str += " " + name;
              }
            }
            else{
              str+=" "+this.translate(name);
            }
          }
        }
      }
      if(handicap){
        var hnd_splited = handicap.split(" ");
        if (hnd_splited.length > 1) {
          for (let i = 0; i < hnd_splited.length; i++) {
            str +=" "+ this.translate(hnd_splited[i]) + " ";
          }
          str.slice(0, -1);
        } else {
          str += " " + this.translate(handicap);
        }
      }
      return str;
    },
    odd_selected: function (
      event_id,
      logro,
      categoria,
      home,
      away,
      event_date,
      market
    ) {
      this.$root.$emit('bet_loading');
      if (this.selected_odds[logro.id]) {
        this.$store.dispatch('deleteBet', logro.id);
        return;
      }
      const currentTime = Date.now();
      const eTime = Date.parse(this.format_date(event_date))/1000;
      let timeZoneOffset = new Date().getTimezoneOffset() / 60;
      let timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
      var odd_data = {
          "provider":"bestapi",
          "game_id": event_id, //id del evento ok -
          "event_id": logro.id,//id de la apuesta ok -
          "price": logro.odds[logro.odds.length-4]=="."?logro.odds.slice(0, -1):logro.odds, //cuota ok -
          "equipoa": home, //ok -
          "equipob": away, //ok -
          "referencia":categoria, //referencia de la apuesta -
          "market": market, //nombre del mercado -
          "seleccion": this.odd_header(logro.header, logro.name, "handicap" in logro?logro.handicap:"","team" in logro?logro.team:"", home, away, categoria), //logro ok
          "seleccion_ref":logro, // ok -
          "start_ts": eTime, // fecha encuentro ok -
          "islive": false, //ok -
          "alias":this.liga.deporte.name_en, //ok -
          "timestamp": currentTime, // fecha apuesta ok -
          "blocked": false, //ok -
          "extra_data":{
            "apuesta": this.translate(categoria).toUpperCase(), //tipo apuesta ok -
            "pais": this.liga.pais.name_es, //ok -
            "liga": this.liga.liga.name,//ok -
            "deporte": this.liga.deporte.name_es,//ok -
            "format": "decimal",
            "current_state": "notstarted", //ok -
            "lang": this.$props.language == "ES" ? "SPA" : "ENG" ,//ok -
            "timezone": timezoneFormated,//ok
            "geo":{},
            "text_info":"",
            "detallesItem":""
          }
      };
      this.$store.dispatch('setBets', odd_data);
    },
    active: function (id) {
      var league_id = id.split("-")[3];
      let items = document.getElementsByClassName("mk-" + league_id);
      [...items].forEach(item=>{
          item.classList.remove('nav_active');
          item.classList.remove('active');
      });
      document.getElementById(id).classList.add('nav_active');
      document.getElementById(id).classList.add('active');
    },
  },
  created() {
    this.sizeControl();
  }, 
  mounted(){
        window.addEventListener('resize', ()=>{this.sizeControl();});
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.long_text {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.nav_active{
      color:#ffd029;
      border-bottom: solid 3px #ffd029;
  }
.hover {
    background-color: #ffd029;
    color:black;
}
</style>
