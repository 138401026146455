import { render, staticRenderFns } from "./prematch_encuentro.vue?vue&type=template&id=45048a0f&scoped=true&"
import script from "./prematch_encuentro.vue?vue&type=script&lang=js&"
export * from "./prematch_encuentro.vue?vue&type=script&lang=js&"
import style0 from "./prematch_encuentro.vue?vue&type=style&index=0&id=45048a0f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45048a0f",
  null
  
)

export default component.exports