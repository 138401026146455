<template>
    <div>
      <div class="modal fade" id="changePassword" tabindex="-1" aria-labelledby="loginLabel" aria-hidden="true" data-bs-backdrop="false" style="background-color:rgba(0, 0, 0, 0.5);">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content" :style="skin.my_account.main_background">
                  <div class="modal-header p-0" style="border-bottom: none;">
                      <h5 class="modal-title" id="exampleModalLabel"></h5>
                      <button @click="rest_msg" id="closeLogin" :style="skin.my_account.close_btn" style="background:transparent;" type="button" class="btn-close m-1" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                  </div> 
                  <div class="modal-body p-4" style="height:30%">
                      <div class="text-center">
                          <img v-if="skin.header_desktop.logo != ''" :src="skin.header_desktop.logo" :width="skin.header_desktop.login_logo_width" alt=""><br><br>
                          <!-- <span>{{translate("PASWWORD")}}</span><br> -->
                          <span>{{translate("change_password")}}</span><br>
                          <form @submit.prevent="login_pass()">
                              <div class="mb-3">
                                  <!-- <label for="newPassword " id="user-1">{{translate("new_password")}} </label> -->
                                  <input v-model="newPassword " id="newPassword" type="password" class="form-control" :placeholder="translate('enter_password')" aria-label="newPassword" aria-describedby="user-1" required>
                              </div>
                              <div v-if="!resetPassword" :class="skin.reset_password?'mb-0':'mb-3'">
                                  <label for="confirmPassword" id="user-1">{{translate("verify_password")}} </label>
                                  <input v-model="confirmPassword" id="confirmPassword" type="password" class="form-control" :placeholder="translate('enter_password')" aria-label="confirmPassword" aria-describedby="password-1" required>
                              </div>
                              <div v-if="skin.reset_password" class="text-end mb-3">
                                  <span @click="reset_password()" style="text-decoration: underline;cursor:pointer;">{{ translate("forgot_password") }}</span>
                              </div>
                              <button id="chanpass_submit_btn" type="submit" :style="skin.qbet.booking_btn" class="p-2 text-center mb-2" style="width:100%;color:white" :disabled="login_inProcess">{{translate("change_password")}}</button>
                          </form>
                          <div v-if="status.message!=''" :class="'alert alert-'+status.type" role="alert">{{ status.message }}</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
      name:"change_password",
      props:['skin','translation','language','user'],
      data(){
          return {
              newPassword :"",
              confirmPassword:"",
              resetPassword:false,
              status:{
                  message:"",
                  type:""
              },
              login_inProcess:false
          }
      },
      computed:{
      },
      methods:{
          async login_pass(){
            // console.log(this.$props.user.domain)

            // return console.log(this.$props.user.user_data.data)
                // Realizar la verificación de las contraseñas nuevas aquí
            if (this.newPassword === this.confirmPassword) {
                // Las contraseñas coinciden, realizar el cambio de contraseña
                const changePasswordData = {
                    id: this.$props.user.user_data.data.user_id??this.$props.user.user_data.data.id,
                    userc: this.$props.user.user_data.data.username,
                    pass: this.newPassword
                };
                // console.log(changePasswordDatka, `${this.$props.user.domain}/apiv1/supervisor/comandpass/any?access_token=${this.$props.user.token}`,this.$props.user)
                try {
                    // Realizar la solicitud POST para cambiar la contraseña
                    const response = await axios.post(
                    `${this.$props.user.domain}/apiv1/supervisor/comandpass/any?access_token=${this.$props.user.token}`,
                    changePasswordData
                    );

                    if (response.data.ok) {
                    this.status.message = "Contraseña cambiada con éxito";
                    // console.log(this.status.message)
                    this.status.type = "success";
                    // Restablecer los campos de contraseña
                    this.newPassword = "";
                    this.confirmPassword = "";
                    } else {
                    this.status.message = response.data.message;
                    this.status.type = "danger";
                    }
                } catch (error) {
                    this.status.message = "Error al cambiar la contraseña.";
                    this.status.type = "danger";
                }
            } else {
                // Las contraseñas no coinciden
                // console.log(this.newPassword, this.confirmPassword)
                this.status.message = "Las contraseñas no coinciden. Por favor, corrígelas.";
                this.status.type = "danger";
            }

            //   this.login_inProcess = true;
            //   if(this.resetPassword){
            //       if(this.username!=""){
            //           let reset = await axios.post(this.$store.getters["getDomain"]+"/swarm/remote/forgot_password",{
            //               "email":this.username
            //           })
            //           if(reset.data.success){
            //               this.status.message=reset.data.message;
            //               this.status.type="success";
            //           }
            //           else{
            //               this.status.message=reset.data.message;
            //               this.status.type="danger";
            //           }
            //           this.resetPassword = false;
            //       }
            //       else{
            //           this.status.message=this.translate("input_email");
            //           this.status.type="danger"; 
            //       }
            //       this.login_inProcess = false;
            //   }
            //   else{
            //       let data = await this.$store.dispatch('login',{username:this.username,password:this.password});
            //       if(data=="success"){
            //           document.getElementById('closeLogin').click();
            //           this.$root.$emit('forceRender');
            //           this.login_inProcess = false;
            //       }
            //       else{
            //           this.status.message=data;
            //           this.status.type="danger";
            //           this.login_inProcess = false;
            //       }
            //   }
              
          },
          reset_password(){
              this.resetPassword = true;
              this.login_pass();
          },
          rest_msg(){
            this.status.message=''
          },
          translate(text) {
              if(text){
                  if (this.$props.translation[text]) {
                      return this.$props.translation[text][this.$props.language];
                  }
                  else{
                      var new_text_serched = text.replace(/ /g, "_");
                      if (this.$props.translation[new_text_serched]) {
                          return this.$props.translation[new_text_serched][this.$props.language];
                      }
                  }
                  return text;
              }
          },
      },
      onMounted:{
        
      },
  }
  </script>
  
  <style>
  
  </style>