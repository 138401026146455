<template>
  <div class="m-1">
        <div class="mb-1">
            <navigation-bar
                v-bind:skin="skin"
                v-bind:translation="translation"
                v-bind:language="language"
                v-bind:navigation="navigation"
            ></navigation-bar>
        </div>
        <div v-if="!data_loaded" style="color:white">
          <div class="row">
            <div class="col col-5">

            </div>
            <div class="col col-7">
              <div class="spinner-border text-warning mx-auto" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div  
            v-for="(encuentro) in encuentros"
            v-bind:key="encuentro.id"
        >
            <div :style="skin.fondo3">
                <div class="text-center">
                    <span class="rounded-circle me-1" :class="'phoca-flag ' + liga.pais_code" style="max-width:20px;height:20px"></span>
                    <span :class="skin.text1" style="font-size:medium">{{ liga.liga.name }}, {{ liga.pais['name_'+language] }}</span>
                </div>
                <div class="text-center">
                    <span :class="skin.text1" style="font-size:small">{{ format_date(encuentro.time) }} </span>
                </div>
                <div class="d-flex p-2">
                    <div class="d-flex" style="width:50%">
                      <shirt v-bind:color="'white'"/>
                      <span class="mx-1">{{ encuentro.players[0].name }}</span>
                    </div>
                    <div style="width:50%">
                      <span class="float-end"><shirt2 v-bind:color="'black'"/></span>
                      <span class="float-end me-1">{{ encuentro.players[1].name }}</span>
                    </div>
                </div>
            </div>
            <div class="accordion row m-0" :id="'match-'+encuentro.id">
                <div class="accordion-item border border-secondary p-1" :style="skin.fondo2">
                  <AllOdds
                    :encuentro="encuentro"
                    :language="language"
                    :Skin="skin"
                    :liga="liga"
                    :translation="translation"
                    :collapsed="false"
                >
                </AllOdds>
                </div>
            </div>
      </div>
  </div>
</template>
<script>
import navigationBar from './navigation/navigation_bar.vue';
import AllOdds from '../main_lobby/main_events_prematch/all_odds.vue';
import shirt from './shirt.vue';
import shirt2 from './shirt2.vue';
export default {
  name: "encuentro",
  components: {
    AllOdds,
    navigationBar,
    shirt,
    shirt2,
  },
  data() {
    return {
      meta_data: [],
      data_loaded: false,
      search_target:this.$props.search,
      winner_title:{
        3:"to_win_the_match",
        9:"to_win_fight",
        14:"to_win_match",
        15:"match_winner",
      },
      visible:true,
    };
  },
  props: ["liga", "skin", "time", "language", "translation","search","sport"],
  computed: {
    encuentros: function () {
      if (!this.data_loaded) {
        return null;
      }
      return this.meta_data.length>0?this.meta_data[0].events:[];
    },
    selected_odds(){
        let odds = {};
        let data = this.$store.getters['getBets'];
        Object.keys(data).forEach(bet => {
          if(data[bet]){
            odds[bet] = true;
          }
        });
        return odds;
      },
      navigation(){
            let links = [];
            links.push({
                name:this.$props.liga.deporte['name_'+this.$props.language].toUpperCase(),
                link:""
            });
            links.push({
                name:"COMPETICIONES",
                link:""
            });
            links.push({
                name:this.$props.liga.pais['name_'+this.$props.language].toUpperCase(),
                link:""
            });
            links.push({
                name:this.$props.liga.liga.name.toUpperCase(),
                link:""
            });
            return links;
        }
  },
  methods: {
    format_date: function (date) {
      var date_str = this.safe_date(date)+" UTC";
      date = new Date(date_str);
      var formated =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        date.getHours() +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2);
      return formated;
    },
    safe_date(date){
      var parsed_array_1 = date.split(" ");
      var parsed_array_2 = parsed_array_1[0].split("-");
      var date_str = parsed_array_2[1]+"/"+parsed_array_2[2]+"/"+parsed_array_2[0]+" "+parsed_array_1[1];
      return date_str;
    },
    translate(text) {
      if (this.$props.translation[text]) {
        return this.$props.translation[text][this.$props.language];
      }
      return text;
    },
    odd_header(header, name, handicap,  home, away, category) {
      var str = "";
      if (header) {
        if (category == "correct_score") {
          str += "";
        }
        else if(category=="match_result_and_total_games"){
          str+="";
        } 
        else {
          if (header == "1") {
            str += home;
          } else if (header == "2") {
            str += away;
          } else {
            str += this.translate(header) + " "+ handicap;
          }
        }
      }
      if (name) {
        if (category == "correct_score") {
          var name_formated = name.split("-");
          if (header == "2") {
            str += " " + name_formated[1] + "-" + name_formated[0];
          } else {
            str += " " + name;
          }
        } 
        else if(category=="match_result_and_total_games"){
          var player= name=="1"?home:away;
          str+=player+" / "+this.translate(header)+" "+handicap;
        } 
        else {
          if (name == "1") {
            str += home;
          } else if (name == "2") {
            str += away;
          } else {
            var name_splited = name.split(" ");
            if (name_splited.length > 1) {
              for (let i = 0; i < name_splited.length; i++) {
                str +=" "+ this.translate(name_splited[i]) + " ";
              }
              str.slice(0, -1);
              str+=" "+handicap;
            } else {
              str += " " + this.translate(name) +" "+handicap;
            }
          }
        }
      }
      return str;
    },
    odd_selected: function (
      event_id,
      logro,
      categoria,
      home,
      away,
      event_date,
      market
    ) {
      this.$root.$emit('bet_loading');
      if (this.selected_odds[logro.id]) {
        this.$store.dispatch('deleteBet', logro.id);
        return;
      }
      const currentTime = Date.now();
      const eTime = Date.parse(this.format_date(event_date))/1000;
      let timeZoneOffset = new Date().getTimezoneOffset() / 60;
      let timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
      var odd_data = {
          "provider":"bestapi",
          "game_id": event_id, //id del evento ok -
          "event_id": logro.id,//id de la apuesta ok -
          "price": logro.odds[logro.odds.length-4]=="."?logro.odds.slice(0, -1):logro.odds, //cuota ok -
          "equipoa": home, //ok -
          "equipob": away, //ok -
          "referencia":categoria, //referencia de la apuesta -
          "market": market, //nombre del mercado -
          "seleccion": this.odd_header(logro.header, logro.name, "handicap" in logro?logro.handicap:"","team" in logro?logro.team:"", home, away, categoria), //logro ok
          "seleccion_ref":logro, // ok -
          "start_ts": eTime, // fecha encuentro ok -
          "islive": false, //ok -
          "alias":this.liga.deporte.name_en, //ok -
          "timestamp": currentTime, // fecha apuesta ok -
          "blocked": false, //ok -
          "extra_data":{
            "apuesta": this.translate(categoria).toUpperCase(), //tipo apuesta ok -
            "pais": this.liga.pais.name_es, //ok -
            "liga": this.liga.liga.name,//ok -
            "deporte": this.liga.deporte.name_es,//ok -
            "format": "decimal",
            "current_state": "notstarted", //ok -
            "lang": this.$props.language == "ES" ? "SPA" : "ENG" ,//ok -
            "timezone": timezoneFormated,//ok
            "geo":{},
            "text_info":"",
            "detallesItem":""
          }
      };
      this.$store.dispatch('setBets', odd_data);
    },
    async getData() {
      try {
        this.data_loaded = false;
        var url = "";
        let currentdate = new Date();
        let date_to ='';
        let to_UTC = ''; 
        currentdate.setDate(currentdate.getDate() + 3);
        date_to = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                + ("0" + currentdate.getDate()).slice(-2) + "/"
                + currentdate.getFullYear() + " 23:59:00";
        to_UTC = new Date(date_to).toISOString();
        if(this.search_target != "NA"){
          if("encuentro" in this.$props.search){
            url = process.env.VUE_APP_SERVER_URL+"get_searched_event_details/league_id/" +
              this.$props.liga.liga.id +"/time/" + this.time.split(".")[0]+"."+to_UTC.replace('T',' ').split('.')[0]+"/event_id/"+this.$props.search.encuentro;
          }
          else{
            
            url = process.env.VUE_APP_SERVER_URL+"get_upcoming_events_details/league_id/" +
              this.$props.liga.liga.id + "/time/" + this.time.split(".")[0]+"."+to_UTC.replace('T',' ').split('.')[0];
          }
        }
        else{
          url = process.env.VUE_APP_SERVER_URL+"get_upcoming_events_details/league_id/" +
              this.$props.liga.liga.id + "/time/" + this.time;
        }
        const response = await this.$http.get(
          url
        );
        this.meta_data = response.data;
        this.data_loaded = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    
  },
  mounted() {
    this.getData();
    this.$root.$on('hide_show_ligas', (id) => {
        if(this.$props.liga.liga.id == id){
          this.visible = !this.visible;
        }
    });
  }, 
  watch:{
    time:function(){
      this.search_target = "NA";
      this.getData();
    },
    sport:function(){
      this.search_target = "NA";
      this.getData();
    }
  }
};
</script>
