<template>
  <div>
    <div class="px-0">
      <div class="table-responsive">
        <div>
          <div class="px-1">
            <div class="accordion" :id="'matchPrematch-'+game.id"> 
              <div class="accordion-item border border-secondary p-0" :style="Skin.prematch_preview.fondo">
                <div class="accordion-header px-1 pe-1" :id="'heading-'+game.id" >
                  <div v-if="screen_width>Skin.break_point" class="d-flex" :style="Skin.prematch_preview.fondo">
                    <div  class="col col-lg-4 col-sm-5 p-1" style="line-height:15px;width:33%">
                      <div>
                          <span :style="Skin.prematch_preview.text_date">{{ format_date(game.start_ts) }}</span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag" style="max-width:15px;height:15px"></span>
                          <span :style="Skin.prematch_preview.text_liga">{{ game.liga }} </span>
                          <span :style="Skin.prematch_preview.text_country">- {{ game.country }} </span><br>
                          <span :style="Skin.prematch_preview.text_team">{{ game.team1_name}} </span><br>
                          <span :style="Skin.prematch_preview.text_team">{{ game.team2_name}} </span>
                        </div>
                    </div>
                    <div class="col col-lg-4 col-sm-6 p-0" style="width:33%">
                      <table style="width:95%" class="mx-auto my-2">
                        <tr>
                          <Odd
                            v-if="Handicap.Home"
                            v-bind:Skin="Skin"
                            v-bind:logro="Handicap.Home"
                            v-bind:active_state="selected_odds[Handicap.Home.id]"
                            v-bind:teaserVal="teaser_type"
                            @click.native="
                              odd_selected(
                                game.id,
                                Handicap.Home,
                                'Puntos Handicap',
                                'MatchHandicap',
                                Handicap.Home.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'Teaser',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                          <Odd
                            v-if="Handicap.Away"
                            v-bind:Skin="Skin"
                            v-bind:logro="Handicap.Away"
                            v-bind:active_state="selected_odds[Handicap.Away.id]"
                            v-bind:teaserVal="teaser_type"
                            @click.native="
                              odd_selected(
                                game.id,
                                Handicap.Away,
                                'Puntos Handicap',
                                'MatchHandicap',
                                Handicap.Away.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'Teaser',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                        </tr>
                      </table>
                    </div>
                    <div class="col col-3 p-0 d-none d-lg-block" style="width:33%">
                      <table style="width:95%" class="mx-auto my-2">
                        <tr>
                          <th class="text-center p-0 text-dark" style="font-size:small">
                          </th>
                          <Odd
                            v-if="OverUnder.Over"
                            v-bind:key="OverUnder.Over.id+'content'"
                            v-bind:Skin="Skin"
                            v-bind:logro="OverUnder.Over"
                            v-bind:active_state="selected_odds[OverUnder.Over.id]"
                            v-bind:teaserVal="teaser_type"
                            @click.native="
                              odd_selected(
                                game.id,
                                OverUnder.Over,
                                'total de goles',
                                'OverUnder',
                                OverUnder.Over.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'Teaser',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                          <Odd
                            v-if="OverUnder.Under"
                            v-bind:key="OverUnder.Under.id+'content'"
                            v-bind:Skin="Skin"
                            v-bind:logro="OverUnder.Under"
                            v-bind:active_state="selected_odds[OverUnder.Under.id]"
                            v-bind:teaserVal="teaser_type"
                            @click.native="
                              odd_selected(
                                game.id,
                                OverUnder.Under,
                                'total de goles',
                                'OverUnder',
                                OverUnder.Under.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'Teaser',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div v-else>
                      <div style="display:flex;width:100%;position:relative;" class="p-1">
                          <span class="me-1" :style="Skin.prematch_preview.text_date">{{ format_date(game.start_ts) }}</span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag" style="max-width:20px;height:20px"></span>
                          <span class="long_text2" :style="Skin.prematch_preview.text_liga">{{ game.liga }} - {{ game.country }}</span>
                      </div>
                      <div class="px-1">
                            <span :style="Skin.prematch_preview.text_team" style="font-size:14px" class="long_text1">{{ game.team1_name }}</span> -VS- 
                            <span :style="Skin.prematch_preview.text_team" style="font-size:14px" class="long_text1">{{ game.team2_name }}</span>
                      </div>
                      <div class="d-flex">
                        <div class="" style="width:50%">
                            <table style="width:95%" class="mx-auto my-2">
                                <tr>
                                    <td colspan="2" class="text-center">{{translate("handicap_points")}}</td>
                                </tr>
                                <tr>
                                <Odd
                                    v-if="Handicap.Home"
                                    v-bind:Skin="Skin"
                                    v-bind:logro="Handicap.Home"
                                    v-bind:active_state="selected_odds[Handicap.Home.id]"
                                    v-bind:teaserVal="teaser_type"
                                    @click.native="
                                    odd_selected(
                                        game.id,
                                        Handicap.Home,
                                        'Puntos Handicap',
                                        'MatchHandicap',
                                        Handicap.Home.market_id,
                                        game.team1_name,
                                        game.team2_name,
                                        game.start_ts,
                                        'Teaser',
                                    )
                                    "
                                >
                                </Odd>
                                <th v-else class="py-2">
                                    <div
                                        class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                                    >
                                        <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                                    </div>
                                </th>
                                <Odd
                                    v-if="Handicap.Away"
                                    v-bind:Skin="Skin"
                                    v-bind:logro="Handicap.Away"
                                    v-bind:active_state="selected_odds[Handicap.Away.id]"
                                    v-bind:teaserVal="teaser_type"
                                    @click.native="
                                    odd_selected(
                                        game.id,
                                        Handicap.Away,
                                        'Puntos Handicap',
                                        'MatchHandicap',
                                        Handicap.Away.market_id,
                                        game.team1_name,
                                        game.team2_name,
                                        game.start_ts,
                                        'Teaser',
                                    )
                                    "
                                >
                                </Odd>
                                <th v-else class="py-2">
                                    <div
                                        class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                                    >
                                        <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                                    </div>
                                </th>
                                </tr>
                            </table>
                        </div>
                        <div class="" style="width:50%">
                            <table style="width:95%" class="mx-auto my-2">
                                <tr>
                                    <td colspan="2" class="text-center">Total de puntos</td>
                                </tr>
                                <tr>
                                <Odd
                                    v-if="OverUnder.Over"
                                    v-bind:key="OverUnder.Over.id+'content'"
                                    v-bind:Skin="Skin"
                                    v-bind:logro="OverUnder.Over"
                                    v-bind:active_state="selected_odds[OverUnder.Over.id]"
                                    v-bind:teaserVal="teaser_type"
                                    @click.native="
                                    odd_selected(
                                        game.id,
                                        OverUnder.Over,
                                        'total de goles',
                                        'OverUnder',
                                        OverUnder.Over.market_id,
                                        game.team1_name,
                                        game.team2_name,
                                        game.start_ts,
                                        'Teaser',
                                    )
                                    "
                                >
                                </Odd>
                                <th v-else class="py-2">
                                    <div
                                        class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                                    >
                                        <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                                    </div>
                                </th>
                                <Odd
                                    v-if="OverUnder.Under"
                                    v-bind:key="OverUnder.Under.id+'content'"
                                    v-bind:Skin="Skin"
                                    v-bind:logro="OverUnder.Under"
                                    v-bind:active_state="selected_odds[OverUnder.Under.id]"
                                    v-bind:teaserVal="teaser_type"
                                    @click.native="
                                    odd_selected(
                                        game.id,
                                        OverUnder.Under,
                                        'total de goles',
                                        'OverUnder',
                                        OverUnder.Under.market_id,
                                        game.team1_name,
                                        game.team2_name,
                                        game.start_ts,
                                        'Teaser',
                                    )
                                    "
                                >
                                </Odd>
                                <th v-else class="py-2">
                                    <div
                                        class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                                    >
                                        <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                                    </div>
                                </th>
                                </tr>
                            </table>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Odd from './teaser_odd.vue';
import AllOdds from './all_odds.vue';
import Futbol from '../sport_tables/futbol.vue';
import Cricket from '../sport_tables/cricket.vue';
import Rugby from '../sport_tables/rugby.vue';
import Tenis from '../sport_tables/tenis.vue';
import Balonmano from '../sport_tables/balonmano.vue';
const flags = require('../../../../assets/countries.json');
export default {
  name: "teaser_encuentro",
  components: {
    Odd,
    Futbol,
    Cricket,
    AllOdds,
    Rugby,
    Tenis,
    Balonmano
  },
  data() {
    return {
      meta_data: [],
      data_loaded: false,
      search_target:this.$props.search,
      winner_title:{
        3:"to_win_the_match",
        9:"to_win_fight",
        14:"to_win_match",
        15:"match_winner",
      },
      screen_width:0,
    };
  },
  props: ["game", "Skin", "language", "translation","sport","teaserVal"],
  computed: {
    selected_odds(){
        let odds = {};
        let data = this.$store.getters['getBets'];
        Object.keys(data).forEach(bet => {
          if(data[bet]){
            odds[bet] = true;
          }
        });
        return odds;
      },
    country_flag() {
          let flag = []
          flags.countries.forEach(country => {
              if(country.name_es == this.$props.game.country){
              flag.push(country.code.toLowerCase());
              }
          });
          return flag;
      },
    Handicap(){
        let data = this.$props.game.market;
        let cuotas = {};
        let teaser_handicap = [];
        Object.keys(data).forEach(type=>{
          if(data[type] && !isNaN(type)){
            if("type" in data[type]){
                if(data[type].type == "MatchHandicap" && data[type].event){
                    data[type].market_id = type;
                    teaser_handicap.push(data[type]);
                }   
            }
          }
        });
        if(teaser_handicap.length>0){
            let handicap_min = teaser_handicap.reduce((prev, current) => (
                Math.abs(prev.event[Object.keys(prev.event)[0]].price - prev.event[Object.keys(prev.event)[1]].price) < Math.abs(current.event[Object.keys(current.event)[0]].price - current.event[Object.keys(current.event)[1]].price)) ? prev : current
            )
            if(handicap_min){
                Object.keys(handicap_min.event).forEach(odd=>{
                    handicap_min.event[odd].market_id = handicap_min.market_id;
                    cuotas[handicap_min.event[odd].type] = handicap_min.event[odd];
                });
            }
        }
        return cuotas;
    },
    P1P2(){
        let data = this.$props.game.market;
        let cuotas = {};
        let factorAjuste = this.$store.getters['getConfig'].SkinConfig.main.deltalogro ? this.$store.getters['getConfig'].SkinConfig.main.deltalogro : 1;
        Object.keys(data).forEach(type=>{
          if(data[type] && !isNaN(type)){
          if("type" in data[type]){
                    if(data[type].type == "P1P2" && data[type].event){
                        Object.keys(data[type].event).forEach(odd=>{
                            if( data[type].event[odd].id && data[type].event[odd].type && data[type].event[odd].price){
                              data[type].event[odd].market_id = type;
                              data[type].event[odd].price *= factorAjuste;
                              cuotas[data[type].event[odd].type] = data[type].event[odd]
                            }
                        });
                    }   
                }
          }
        })
        return cuotas;
    },
    OverUnder(){
        let data = this.$props.game.market;
        let cuotas = {};
        let teaser_total = [];
        Object.keys(data).forEach(type=>{
          if(data[type] && !isNaN(type)){
            if("type" in data[type]){
                if(data[type].type == "MatchTotal" && data[type].event){
                    data[type].market_id = type;
                    teaser_total.push(data[type]);
                }   
            }
          }
        });
        if(teaser_total.length>0){
            let total_min = teaser_total.reduce((prev, current) => (
                Math.abs(prev.event[Object.keys(prev.event)[0]].price - prev.event[Object.keys(prev.event)[1]].price) < Math.abs(current.event[Object.keys(current.event)[0]].price - current.event[Object.keys(current.event)[1]].price)) ? prev : current
            )
            if(total_min){
                Object.keys(total_min.event).forEach(odd=>{
                    total_min.event[odd].market_id = total_min.market_id;
                    cuotas[total_min.event[odd].type] = total_min.event[odd];
                });
            }
        }
        return cuotas;
    },
    market(){
      let data = this.$store.getters['prematch_2/getSuscribeEventsMarket'];
      if(data[this.$props.game.id] != null){
        let game = data[this.$props.game.id].data.data.sport[this.$props.sport].region[this.$props.game.country_id].competition[this.$props.game.liga_id].game[this.$props.game.id];
        return game;
      }
      return {};
    },
    hoverCss(){
      return {
        '--fondoH':this.$props.Skin.prematch_all_odds.hover_fondo,
        '--textH':this.$props.Skin.prematch_all_odds.hover_text,
      }
    },
    rate_type(){
      return this.$store.getters['getRateType'];
    },
    teaser_type(){
        let value = this.$props.teaserVal;
        if(this.$props.sport==3){
          if(value==6){
            value = 4
          }
          else if(value==6.5){
            value = 4.5
          }
          else if(value==7){
            value = 5
          }
        }
        if(this.$props.sport==6){
          if(value==4){
            value = 6
          }
          else if(value==4.5){
            value = 6.5
          }
          else if(value==5){
            value = 7
          }
        }
        return value;
    }
  },
  methods: {
    suscribePremathEvent(id){
      let suscribed = this.$store.getters['prematch_2/getSuscribeEventsMarket'][id] ? true : false;
      if(!suscribed){
        this.$store.dispatch('prematch_2/suscribe_event',id);
      }
      else{
        this.$store.dispatch('prematch_2/unsuscribe_event',id);
      }
    },
    format_date: function (ts) {
      let date = new Date(ts * 1000);
      var formated =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        date.getHours() +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2);
      return formated;
    },
    safe_date(date){
      var parsed_array_1 = date.split(" ");
      var parsed_array_2 = parsed_array_1[0].split("-");
      var date_str = parsed_array_2[1]+"/"+parsed_array_2[2]+"/"+parsed_array_2[0]+" "+parsed_array_1[1];
      return date_str;
    },
    translate(text) {
      if (this.$props.translation[text]) {
        return this.$props.translation[text][this.$props.language];
      }
      return text;
    },
    odd_header(header){
      if(header){
        if(header.includes("W1")){
          header = header.replace("W1",this.$props.game.team1_name);
        }
        if(header.includes("Equipo 1")){
          header = header.replace("Equipo 1",this.$props.game.team1_name);
        }
        if(header.includes("W2")){
          header = header.replace("W2",this.$props.game.team2_name);
        }
        if(header.includes("Equipo 2")){
          header = header.replace("Equipo 2",this.$props.game.team2_name);
        }
        if(header.includes(" X")){
          header = header.replace("X"," Empate");
        }
        return header;
      }
      return "error";
    },
    validate_odd(odd){
      if(odd){
        if(odd.price){
          return odd.price.toFixed(2);
        }
        else{
          return null;
        }
      }
    },
    odd_selected: function (
      event_id,
      logro,
      categoria,
      market_type,
      market_id,
      home,
      away,
      event_date,
      market
    ) {
      this.$root.$emit('bet_loading');
      if (this.selected_odds[logro.id]) {
        this.$store.dispatch('live/unsuscribe_odd',logro.id);
        return;
      }
      const UTC = new Date().toISOString();
      const currentTime = Math.round(new Date(UTC).getTime() / 1000);
      let timeZoneOffset = new Date().getTimezoneOffset() / 60;
      let timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
      var odd_data = {
          "provider":"mode" in this.$props.game ? "client" : "betconstruct",
          "mode":"mode" in this.$props.game ? "external" : "socket",
          "game_id": event_id, //id del evento ok
          "event_id": logro.id,//id de la apuesta ok
          "price": logro.price.toFixed(2), //cuota ok
          "equipoa": home, //ok
          "equipob": away, //ok
          "market_id":market_id,// ID del marcado
          "referencia":market_type, //referencia de la apuesta
          "seleccion": this.odd_header(logro.name)+(logro.base ? ' ('+(logro.base)+')' : ""), //logro ok
          "seleccion_ref":logro,
          "start_ts": event_date, // fecha encuentro ok
          "islive": false, //ok
          "alias":this.$props.game.sport_alias, //ok
          "timestamp": currentTime, // fecha apuesta ok
          "blocked": Boolean(this.$props.game.is_blocked), //ok
          "blocked_reason":"",
          "deltaCombinada":1,
          "teaser":{isTeaser:market=='Teaser'?true:false,value:this.$props.teaserVal,base:market=='Teaser'?logro.base:null},
          "extra_data":{
            "apuesta": this.translate(categoria).toUpperCase(), //tipo apuesta ok
            "market": market, //nombre del mercado
            "pais": this.$props.game.country, //ok
            "liga": this.$props.game.liga,//ok
            "deporte": this.$props.game.sport_alias,//ok
            "format": this.rate_type == 'americano' ? 'american' : 'decimal',
            "current_state": this.$props.game.info.current_game_state, //ok
            "lang": this.$props.Skin.languages[this.$props.language].code,//ok
            "timezone": timezoneFormated,//ok
            "geo":{},
            "score":{
                "team1":null,
                "team2":null,
            },
            "text_info":"",
            "detallesItem":""
          }
      };
      if(market=='Teaser'){this.$store.dispatch("setBetType","teaser");}
      this.$store.dispatch('live/suscribe_odd', odd_data);
    },
    game_state(state,sport){
      switch (sport) {
          case 1: //Futbol
              if(state == "set1"){
                  return "1a Parte"
              }
              else if(state == "set2"){
                  return "2a Parte"
              }
              else if(state == "set3"){
                  return "Tiempo Extra 1a Parte"
              }
              else if(state == "set4"){
                  return "Tiempo Extra 2a Parte"
              }
              else if(state == "Half Time"){
                  return "Medio Tiempo"
              }
              else if(state == "notstarted"){
                  return "Por comenzar"
              }
              break;
          case 3: //Baloncesto
              if(state == "set1"){
                  return "1er Cuarto"
              }
              else if(state == "set2"){
                  return "2do Cuarto"
              }
              else if(state == "set3"){
                  return "3er Cuarto"
              }
              else if(state == "set4"){
                  return "4to Cuarto"
              }
              else if(state == "timeout"){
                  return "Timeout"
              }
              else if(state == "notstarted"){
                  return "Por comenzar"
              }
              break;
          default:
          break;
      }
    },
    select_event(){
      this.$store.dispatch('setLobbyView','prematch2_evento');
      this.$root.$emit('show_prematch2_event',this.$props.game);
      this.$store.dispatch('prematch_2/unsuscribe_all_events');
      if(!("mode" in this.$props.game)){
        this.$store.dispatch('prematch_2/suscribe_event', this.$props.game.id);
      }
    },
    sizeControl(){
        this.screen_width=window.innerWidth;
    },
    hover1(id){
        document.getElementById(id).classList.add('hover');
    },
    hover2(id){
        document.getElementById(id).classList.remove('hover');
    },
    rate_conversion(rate){
      if(this.rate_type == 'americano'){
        let value = parseFloat(rate);
        if(value >= 2){
            let result = (value-1)*100;
            return '+'+(Math.round(result));
        }
        else{
            let result = (-100)/(value-1);
            return Math.round(result);
        }
      }
      return rate.toFixed(2)
    },
  },  
  mounted() {
    window.addEventListener('resize', ()=>{this.sizeControl();});
  },
  created(){
     this.sizeControl();
  }
};
</script>
<style scoped>
  .hover {
    background-color: var(--fondoH) !important;
    color:var(--textH);
  }
  .long_text2 {
    display: -webkit-box;
    max-width: 190px;
    height: 16px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
