<template>
  <div>
    <div class="p-2" :style="skin.my_account.menu_item_border">
      <h5>{{translate("pay_withdrawal")}}</h5>
    </div>
    <div v-if="response.result=='Ok'" class="alert alert-success fade show" role="alert">
      {{ response.msg }}
    </div>
    <div v-if="response.result=='error'" class="alert alert-danger fade show" role="alert">
      {{ response.msg }}
    </div>
    <div class="row p-2">
        <form @submit.prevent="confirmWithdraw()" class="col-12 col-lg-6">
          <div class="py-2">
            <label for="code">{{translate("withdrawal_code")}}</label>
            <input v-model="code" type="text" class="form-control" :placeholder="translate('enter_withdrawal_code')" id="code" required>
          </div>
          <div class="py-2">
            <label for="notes">{{translate("notes")}}</label>
            <input v-model="notes" type="text" class="form-control" id="notes" :placeholder="translate('annotations')">
          </div>
          <div class="py-4">
            <button :style="skin.qbet.booking_btn" class="p-2 text-center mb-2" style="width:100%;color:white" :disabled="payment_inProcess">{{translate("confirm")}}</button>
          </div>
        </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name:"withdraw",
    props:["skin","language","translation"],
    data(){
      return{
        payment_inProcess:false,
        code:"",
        notes:"",
        response:{result:"",msg:""}
      }
    },
    methods:{
      async confirmWithdraw(){
        this.payment_inProcess = true
        let data = {
          "command":"pagar",
          "params":{
            "codigo":this.code,
            "notas":this.notes
            }
          }

        const response = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data);
        if(response.data.result == 'Ok'){
          this.response.result="Ok";
          this.response.msg=response.data.msg;
          this.code="";
          this.notes="";
        }
        else if(response.data.result == 'error'){
          this.response.result="error";
          this.response.msg=response.data.msg;
        }
        this.payment_inProcess = false;
      },
      translate(text) {
        if(text){
          if (this.$props.translation[text]) {
            return this.$props.translation[text][this.$props.language];
          }
          else{
            var new_text_serched = text.replace(/ /g, "_");
            if (this.$props.translation[new_text_serched]) {
              return this.$props.translation[new_text_serched][this.$props.language];
            }
          }
          return text;
        }
      },
    },
    computed:{
      user(){
        return this.$store.getters['getUserData'];
      },
    }
}
</script>

<style>

</style>