<template>
  <div :style="skin.carrousel_event.plantilla" class="m-1 p-1">
    <div style="display:flex;width:100%" class="p-1">
        <div style="width:10%">
            <div class="icon_futbol" style="width:20px;height:20px" v-if="game.sport==1"></div>
            <div class="icon_hockey" style="width:20px;height:20px" v-if="game.sport==2"></div>
            <div class="icon_basket" style="width:20px;height:20px" v-if="game.sport==3"></div>
            <div class="icon_tenis" style="width:20px;height:20px" v-if="game.sport==4"></div>
            <div class="icon_beisball" style="width:20px;height:20px" v-if="game.sport==11"></div>
            <div class="icon_futsal" style="width:20px;height:20px" v-if="game.sport==26"></div>
            <div class="icon_tenis_mesa" style="width:20px;height:20px" v-if="game.sport==41"></div>
            <div class="icon_eGames" style="width:20px;height:20px" v-if="game.sport==73"></div>
            <div class="icon_eGames" style="width:20px;height:20px" v-if="game.sport==75"></div>
            <div class="icon_eGames" style="width:20px;height:20px" v-if="game.sport==76"></div>
            <div class="icon_eGames" style="width:20px;height:20px" v-if="game.sport==77"></div>
            <div class="icon_eGames" style="width:20px;height:20px" v-if="game.sport==71"></div>
        </div>
        <div style="width:20%"><span class="long_text" :style="skin.carrousel_event.current_state_text">{{ game_state(game.info.current_game_state,1) }} {{ game.info.current_game_time }}</span></div>
        <div style="width:50%"><span class="long_text1" :style="skin.carrousel_event.liga_text"><span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag" style="max-width:15px;height:15px"></span>{{ game.liga }} |</span></div>
        <div style="width:20%"><span class="long_text1" :style="skin.carrousel_event.country_text">{{ game.country }}</span></div>
    </div>
    <router-link
        :to="this.$root.user_token == '' ? { name: 'LiveMainEcuabets',query: { token: this.$root.user_token+this.$root.site_id}} : { name: 'Live_ecuabets' ,  query: { token: this.$root.user_token+this.$root.site_id} }"
    >
        <div @click="select_event" class="p-1">
            <div style="display:flex;width:100%">
                <div style="width:70%">
                    <img v-if="game.team2_name" :src="'https://statistics.betconstruct.com/images/e/m/0/'+game.team1_id+'.png??v=0.77896426980335'" onerror="this.src='https://resources.jbets.online/logos/empty_escudo.png'" alt="" width="18">&nbsp;
                    <span :style="skin.carrousel_event.team_text" style="font-size:14px" >{{ game.team1_name }}</span></div>
                <div style="width:10%"><span :style="skin.carrousel_event.score_text" style="font-size:14px">{{ game.info.score1 }}</span></div>
                <div style="width:20%"><span style="float:right;" class="badge bg-danger me-2">{{translate("live")}}</span></div>
            </div>
            <div style="display:flex;width:100%">
                <div style="width:70%">
                    <img v-if="game.team2_name" :src="'https://statistics.betconstruct.com/images/e/m/0/'+game.team2_id+'.png??v=0.77896426980335'" onerror="this.src='https://resources.jbets.online/logos/empty_escudo.png'" alt="" width="18">&nbsp;
                    <span :style="skin.carrousel_event.team_text" style="font-size:14px" >{{ game.team2_name }}</span></div>
                <div style="width:10%"><span :style="skin.carrousel_event.score_text" style="font-size:14px">{{ game.info.score2 }}</span></div>
                <div style="width:20%"></div>
            </div> 
        </div>
    </router-link>
    <div class="p-1">
        <div class="p-0" style="display:flex;width:100%" :style="hoverCss">
            <div 
                v-if="P1XP2.P1 ? (P1XP2.P1.price ? true : false) : false"
                :id="P1XP2.P1.id" 
                :class="selected_odds[P1XP2.P1.id] ? 'hover' : ''"
                @mouseover="selected_odds[P1XP2.P1.id] ? '' : hover1(P1XP2.P1.id)"
                @mouseleave="selected_odds[P1XP2.P1.id] ? '' : hover2(P1XP2.P1.id)" 
                class="p-2 m-1" style="width:33%;cursor:pointer" :style="skin.carrousel_event.odd"
                @click="
                    rate_conversion(P1XP2.P1.price,P1XP2.P1.id) == 1.00 ? '' :
                    odd_selected(
                    game.id,
                    P1XP2.P1,
                    'resultado del partido',
                    'P1XP2',
                    P1XP2.P1.market_id,
                    game.team1_name,
                    game.team2_name,
                    game.start_ts,
                    'P1XP2',
                    )
                "
             >
                <span style="font-size:14px">{{ P1XP2.P1.type }}</span>
                <span v-if="rate_conversion(P1XP2.P1.price,P1XP2.P1.id) == 1.00" style="font-size:14px;float:right"><i  class="fas fa-lock"></i></span>
                <span v-else style="font-size:14px;float:right">{{ rate_conversion(P1XP2.P1.price,P1XP2.P1.id) }}</span>
            </div>
            <div v-else
                class="p-2 m-1 text-center" style="width:33%" :style="skin.carrousel_event.odd"
            >
                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
            </div>
            <div 
            v-if="P1XP2.X ? (P1XP2.X.price ? true : false) : false"
            :id="P1XP2.X.id" 
            :class="selected_odds[P1XP2.X.id] ? 'hover' : ''"
            @mouseover="selected_odds[P1XP2.X.id] ? '' : hover1(P1XP2.X.id)"
            @mouseleave="selected_odds[P1XP2.X.id] ? '' : hover2(P1XP2.X.id)" 
            class="p-2 m-1" style="width:33%;cursor:pointer" :style="skin.carrousel_event.odd"
             @click="
                rate_conversion(P1XP2.X.price,P1XP2.X.id) == 1.00 ? '' :
                odd_selected(
                game.id,
                P1XP2.X,
                'resultado del partido',
                'P1XP2',
                P1XP2.X.market_id,
                game.team1_name,
                game.team2_name,
                game.start_ts,
                'P1XP2',
                )
            "
             >
                <span style="font-size:14px">{{ P1XP2.X.type }}</span>
                <span v-if="rate_conversion(P1XP2.X.price,P1XP2.X.id) == 1.00" style="font-size:14px;float:right"><i  class="fas fa-lock"></i></span>
                <span v-else style="font-size:14px;float:right">{{ rate_conversion(P1XP2.X.price,P1XP2.X.id) }}</span>
            </div>
            <div v-else
                class="p-2 m-1 text-center" style="width:33%" :style="skin.carrousel_event.odd"
            >
                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
            </div>
            <div
            v-if="P1XP2.P2 ? (P1XP2.P2.price ? true : false) : false" 
            :id="P1XP2.P2.id" 
            :class="selected_odds[P1XP2.P2.id] ? 'hover' : ''"
            @mouseover="selected_odds[P1XP2.P2.id] ? '' : hover1(P1XP2.P2.id)"
            @mouseleave="selected_odds[P1XP2.P2.id] ? '' : hover2(P1XP2.P2.id)" 
            class="p-2 m-1" style="width:33%;cursor:pointer" :style="skin.carrousel_event.odd"
             @click="
                rate_conversion(P1XP2.P2.price,P1XP2.P2.id) == 1.00 ? '' :
                odd_selected(
                game.id,
                P1XP2.P2,
                'resultado del partido',
                'P1XP2',
                P1XP2.P2.market_id,
                game.team1_name,
                game.team2_name,
                game.start_ts,
                'P1XP2',
                )
            "
             >
                <span style="font-size:14px">{{ P1XP2.P2.type }}</span>
                <span v-if="rate_conversion(P1XP2.P2.price,P1XP2.P2.id) == 1.00" style="font-size:14px;float:right"><i  class="fas fa-lock"></i></span>
                <span v-else style="font-size:14px;float:right">{{ rate_conversion(P1XP2.P2.price,P1XP2.P2.id) }}</span>
            </div>
            <div v-else
                class="p-2 m-1 text-center" style="width:33%" :style="skin.carrousel_event.odd"
            >
                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
const flags = require('../../../../assets/countries.json');
export default {
     props:['skin','translation','language','game'],
     data(){
         return{
            hover:false,
         }
     },
     methods:{
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
        odd_header(header){
            if(header){
                if(header.includes("W1")){
                header = header.replace("W1",this.$props.game.team1_name);
                }
                if(header.includes("Equipo 1")){
                header = header.replace("Equipo 1",this.$props.game.team1_name);
                }
                if(header.includes("W2")){
                header = header.replace("W2",this.$props.game.team2_name);
                }
                if(header.includes("Equipo 2")){
                header = header.replace("Equipo 2",this.$props.game.team2_name);
                }
                if(header.includes(" X")){
                header = header.replace("X"," Empate");
                }
                return header;
            }
            return "error";
        },
        game_state(state,sport){
            switch (sport) {
                case 1: //Futbol
                    if(state == "set1"){
                        return "1a Parte"
                    }
                    else if(state == "set2"){
                        return "2a Parte"
                    }
                    else if(state == "set3"){
                        return "Tiempo Extra 1a Parte"
                    }
                    else if(state == "set4"){
                        return "Tiempo Extra 2a Parte"
                    }
                    else if(state == "Half Time"){
                        return "Medio Tiempo"
                    }
                    else if(state == "notstarted"){
                        return "Por comenzar"
                    }
                    break;
                case 3: //Baloncesto
                    if(state == "set1"){
                        return "1er Cuarto"
                    }
                    else if(state == "set2"){
                        return "2do Cuarto"
                    }
                    else if(state == "set3"){
                        return "3er Cuarto"
                    }
                    else if(state == "set4"){
                        return "4to Cuarto"
                    }
                    else if(state == "timeout"){
                        return "Timeout"
                    }
                    else if(state == "notstarted"){
                        return "Por comenzar"
                    }
                    break;
                default:
                break;
            }
        },
        hover1(id){
            document.getElementById(id).classList.add('hover');
        },
        hover2(id){
            document.getElementById(id).classList.remove('hover');
        },
        odd_selected: function (
        event_id,
        logro,
        categoria,
        market_type,
        market_id,
        home,
        away,
        event_date,
        market
        ) {
        this.$root.$emit('bet_loading');
        if (this.selected_odds[logro.id]) {
            this.$store.dispatch('live/unsuscribe_odd',logro.id);
            return;
        }
        const UTC = new Date().toISOString();
        const currentTime = Math.round(new Date(UTC).getTime() / 1000);
        let timeZoneOffset = new Date().getTimezoneOffset() / 60;
        let timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
        var odd_data = {
            "provider":"betconstruct",
            "mode":"socket",
            "game_id": event_id, //id del evento ok
            "event_id": logro.id,//id de la apuesta ok
            "price": (logro.price*this.factorAjuste<1?1.00:logro.price*this.factorAjuste).toFixed(2), //cuota ok
            "equipoa": home, //ok
            "equipob": away, //ok
            "market_id":market_id,// ID del marcado
            "referencia":market_type, //referencia de la apuesta
            "seleccion": this.odd_header(logro.name)+(logro.base ? ' ('+(logro.base)+')' : ""), //logro ok
            "seleccion_ref":logro,
            "start_ts": event_date, // fecha encuentro ok
            "islive": true, //ok
            "alias":this.$props.game.sport_alias, //ok
            "timestamp": currentTime, // fecha apuesta ok
            "blocked": Boolean(this.$props.game.is_blocked), //ok
            "blocked_reason":"",
            "deltaCombinada":1,
            "extra_data":{
                "apuesta": this.translate(categoria).toUpperCase(), //tipo apuesta ok
                "market": market, //nombre del mercado
                "pais": this.$props.game.country, //ok
                "liga": this.$props.game.liga,//ok
                "deporte": this.$props.game.sport_alias,//ok
                "format": this.rate_type == 'americano' ? 'american' : 'decimal',
                "current_state": this.$props.game.info.current_game_state, //ok
                "lang": this.$props.skin.languages[this.$props.language].code,//ok
                "timezone": timezoneFormated,//ok
                "geo":{},
                "score":{
                    "team1":this.$props.game.info.score1??null,
                    "team2":this.$props.game.info.score2??null,
                },
                "text_info":"",
                "detallesItem":""
            }
        };
        this.$store.dispatch('live/suscribe_odd', odd_data);
        },
        select_event(){
            if(this.$store.getters['live/getTargetEventId'] !=  this.$props.game.id){
                if(Object.keys(this.$store.getters['live/getLiveEvent']).length > 0){
                    this.$store.dispatch('live/unsuscribe_event');
                }
                this.$store.dispatch('live/suscribe_event', this.$props.game.id);
            }
        },
        rate_conversion(rate,id){
            let value = rate*this.factorAjuste<1?1.00:rate*this.factorAjuste;
            let jbets_modified_odd = this.jbets_modified_odds.filter(odd_modified=>odd_modified.event_id == id);
            if(jbets_modified_odd.length==1){
                value = jbets_modified_odd[0].blocked?1.00:value*jbets_modified_odd[0].factor<1?1.00:value*jbets_modified_odd[0].factor;
            }
            if(this.rate_type == 'americano'){
                value = parseFloat(value);
                if(value >= 2){
                    let result = (value-1)*100;
                    return '+'+(Math.round(result));
                }
                else{
                    let result = (-100)/(value-1);
                    return Math.round(result);
                }
            }
            return value.toFixed(2)
        }
     },
     computed:{
        selected_odds(){
            let odds = {};
            let data = this.$store.getters['getBets'];
            Object.keys(data).forEach(bet => {
            if(data[bet]){
                odds[bet] = true;
            }
            });
            return odds;
        },
        country_flag() {
            let flag = []
            flags.countries.forEach(country => {
                if(country.name_es == this.$props.game.country){
                flag.push(country.code.toLowerCase());
                }
            });
            return flag;
        },
        P1XP2(){
            let data = this.$props.game.market;
            let cuotas = {};
            Object.keys(data).forEach(type=>{
                if(data[type]){
                    if("type" in data[type]){
                        if(data[type].type == "P1XP2"){
                            Object.keys(data[type].event).forEach(odd=>{
                                if( data[type].event[odd].id && data[type].event[odd].type && data[type].event[odd].price){
                                    data[type].event[odd].market_id = type;
                                    cuotas[data[type].event[odd].type] = data[type].event[odd]
                                }
                            });
                        }   
                    }
                }
            })
            return cuotas;
        },
        hoverCss(){
            return {
            '--fondoH':this.$props.skin.prematch_all_odds.hover_fondo,
            '--textH':this.$props.skin.prematch_all_odds.hover_text,
            }
        },
        rate_type(){
            return this.$store.getters['getRateType'];
        },
        factorAjuste(){
            return this.$store.getters['getConfig'].SkinConfig.main.deltalogro ? this.$store.getters['getConfig'].SkinConfig.main.deltalogro : 1;
        },
        jbets_modified_odds(){
            return this.$store.getters['getJbetsModifieOdds'];
        }
     }
}
</script>

<style scoped>
  .long_text1 {
      display: -webkit-box;
      max-width: 190px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
  .hover {
    background-color: var(--fondoH);
    color:var(--textH) !important;
}
</style>