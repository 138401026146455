<template>
  <div>
    <div class="p-2" :style="skin.my_account.menu_item_border">
      <h5>{{translate("Movements")}}</h5>
    </div>
    <div class="row p-2">
      <div class="p-1 col-12 col-sm col-lg-4">
        <select v-model="movementType" name="movementType" id="movementType" class="form-control p-1">
          <option value="2">{{translate("bet_paid")}}</option>
          <option value="0">{{translate("bet_made")}}</option>
          <option value="3">{{translate("withdrawal_requested")}}</option>
          <option value="4">{{translate("paid_withdrawal")}}</option>
          <option value="5">{{translate("recharge_done")}}</option>
          <option value="6">{{translate("recharge_requested")}}</option>
          <option value="1">{{translate("casino_movement")}}</option>
          <option value="" selected>{{translate("all")}}</option>
        </select>
      </div>
      <div class="p-1 col-12 col-sm col-lg-4">
        <date-picker v-model="date_range" type="date" range placeholder="" style="max-width:100%"></date-picker>
      </div>
      <div class="p-1 col-12 col-sm col-lg-4 text-center">
        <button @click="loadMovements()" :style="skin.qbet.booking_btn" class="p-1 text-center mb-2" style="width:50%;color:white" :disabled="loading_movements">{{translate("show")}}</button>
      </div>
    </div>
    <div v-if="loading_movements" style="width:100%" class="text-center p-2" :style="skin.qbet.fondo3">
      <div class="spinner-border" :style="skin.loader" role="status">
        <span class="visually-hidden">{{translate("loading")}}</span>
      </div>
    </div>
    <div v-if="movements.length>0" class="responsive">
      <table class="table" :style="skin.my_account.close_btn">
        <thead>
          <tr :style="skin.my_account.main_menu_background">
            <th scope="col">{{translate("date_id")}}</th>
            <th scope="col">{{translate("movement_type")}}</th>
            <th scope="col">{{translate("amount")}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="movement in movements" :key="movement.id" :style="skin.my_account.header">
            <td>
              <span>{{ format_date(movement.date_time) }}</span>&nbsp;&nbsp;
              <span>ID: {{ movement.bet_id }}</span>
            </td>
            <td>{{ movement.operation_name }}</td>
            <td>{{ movement.amount.toLocaleString('de-DE', {maximumFractionDigits:2}) }}&nbsp;{{ user.user_data.data.currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es/es';
export default {
    name:"movements",
    props:['skin','translation','language'],
    components:{DatePicker},
    data(){
      return {
        date_range:[],
        loading_movements:false,
        movements:[],
        movementType:""
      }
    },
    methods:{
      async loadMovements(){
        this.loading_movements = true
        let dateFrom =this.date_range[0];
        let dateTo = this.date_range[1];
        let timeZoneOffset = new Date().getTimezoneOffset() / 60;
        let timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
        let where = {
          "time_shift":timezoneFormated,
          "from_date":Math.floor(dateFrom.getTime()/1000),
          "to_date":Math.floor(dateTo.getTime()/1000),
        }
        if(this.movementType!=""){
          where["type"] = this.movementType
        }
        let data = {
          "command":"balance_history",
          "params":{
            "where":where
          }
        }
        const response = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data);
        if(response.data.result == 'Ok'){
          this.movements = response.data.history
        }
        this.loading_movements = false;
      },
      format_date: function (ts) {
        let date = new Date(ts * 1000);
        var formated =
          date.getFullYear() +
          "-" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date.getDate()).slice(-2) +
          " " +
          date.getHours() +
          ":" +
          ("0" + date.getMinutes()).slice(-2) +
          ":" +
          ("0" + date.getSeconds()).slice(-2);
        return formated;
      },
      translate(text) {
        if(text){
          if (this.$props.translation[text]) {
            return this.$props.translation[text][this.$props.language];
          }
          else{
            var new_text_serched = text.replace(/ /g, "_");
            if (this.$props.translation[new_text_serched]) {
              return this.$props.translation[new_text_serched][this.$props.language];
            }
          }
          return text;
        }
      },
    },
    computed:{
      user(){
        return this.$store.getters['getUserData'];
      },
    },
    mounted(){
        let dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate()-1);
        let dateTo = new Date();
        dateTo.setDate(dateTo.getDate());
        this.date_range=[dateFrom,dateTo];
    },
}
</script>

<style>

</style>