<template>
  <div class="m-1">
        <div>
            <navigation-bar
                v-bind:skin="skin"
                v-bind:translation="translation"
                v-bind:language="language"
                v-bind:navigation="navigation"
            ></navigation-bar>
        </div>
        <div class="my-1">
            <div :style="skin.fondo3" class="m-1 p-2">
                <span :style="skin.text3">CUOTAS DE PARTIDO</span>
            </div>
            <encuentro v-for="(liga,index) in ligas_array"
                v-bind:liga="liga"
                v-bind:Skin="skin"
                v-bind:key="index"
                v-bind:sport="liga.deporte.id"
                v-bind:time="time"
                v-bind:language="language"
                v-bind:translation="translation"
                v-bind:search="'NA'"
            >
                <div @click="hide_show_liga(liga.liga.id,index)" style="cursor:pointer;" slot="header" :style="skin.fondo3" class="m-1 p-1">
                    <span class="rounded-circle me-1" :class="'phoca-flag ' + liga.pais_code" style="max-width:20px;height:20px"></span>
                    <span :style="skin.text1" class="mx-1">{{ liga.liga.name }}</span>, 
                    <span :style="skin.text1" class="mx-1">{{ liga.pais['name_'+language] }}</span>
                    <span :style="skin.text1" class="mx-1 float-end"><i ref="arrow" class="fas fa-angle-up"></i></span>
                </div>
                <div slot="table" v-if="liga.deporte.id==1">
                    <div class="px-3">
                        <Futbol
                            :Skin="skin"
                            :translation="translation"
                            :language="language"
                        ></Futbol>
                    </div>
                </div>
                <div slot="table" v-if="liga.deporte.id==3 || liga.deporte.id==9 || liga.deporte.id==14 || liga.deporte.id==15">
                    <div class="px-3">
                        <Cricket
                            :Skin="skin"
                            :translation="translation"
                            :language="language"
                        ></Cricket>
                    </div>
                </div>
                <div slot="table" v-if="liga.deporte.id==8">
                    <div class="px-3">
                        <Rugby
                            :Skin="skin"
                            :translation="translation"
                            :language="language"
                        ></Rugby>
                    </div>
                </div>
                <div slot="table" v-if="liga.deporte.id==12 || liga.deporte.id==16
                || liga.deporte.id==17 || liga.deporte.id==18
                || liga.deporte.id==91">
                    <div class="px-3">
                        <Cricket
                            :Skin="skin"
                            :translation="translation"
                            :language="language"
                        ></Cricket>
                    </div>
                </div>
                <div slot="table" v-if="liga.deporte.id==13">
                    <div class="px-3">
                        <Tenis
                            :Skin="skin"
                            :translation="translation"
                            :language="language"
                        ></Tenis>
                    </div>
                </div>
                <div slot="table" v-if="liga.deporte.id==78 || liga.deporte.id==110 || liga.deporte.id==83">
                    <div class="px-3">
                        <Balonmano
                            :Skin="skin"
                            :translation="translation"
                            :language="language"
                        ></Balonmano>
                    </div>
                </div>
                <div slot="table" v-if="liga.deporte.id==92 || liga.deporte.id==94 || liga.deporte.id==151">
                    <div class="px-3">
                        <Cricket
                            :Skin="skin"
                            :translation="translation"
                            :language="language"
                        ></Cricket>
                    </div>
                </div>
            </encuentro>
        </div>
  </div>
</template>

<script>
import navigationBar from './navigation/navigation_bar.vue';
import encuentro from '../main_lobby/main_events_prematch/Encuentro.vue';
import Futbol from '../main_lobby/sport_tables/futbol.vue';
import Cricket from '../main_lobby/sport_tables/cricket.vue';
import Rugby from '../main_lobby/sport_tables/rugby.vue';
import Tenis from '../main_lobby/sport_tables/tenis.vue';
import Balonmano from '../main_lobby/sport_tables/balonmano.vue';
export default {
    name:"ligas_prematch",
    props:["skin","translation","language","time"],
    components:{
        navigationBar,
        encuentro,
        Futbol,
        Cricket,
        Rugby,
        Tenis,
        Balonmano
    }, 
    data(){
        return{
        }
    },
    computed:{
        ligas_array(){
            let data = this.$store.getters['prematch/getLigasArray2'];
            if(data.length < 1){
                this.$store.dispatch('setLobbyView','main');
            }
            return data;
        },
        navigation(){
            let links = [];
            let sports = {};
            let countries = {};
            let leagues = {};
            let data = this.$store.getters['prematch/getLigasArray2'];
            data.forEach(liga => {
                sports[liga.deporte.id] = liga.deporte['name_'+this.$props.language].toUpperCase();
                countries[liga.pais_code] = liga.pais['name_'+this.$props.language].toUpperCase();
                leagues[liga.liga.id] = liga.liga.name.toUpperCase();
            });
            if(Object.keys(leagues).length == 1 && Object.keys(sports).length == 1 && Object.keys(countries).length == 1){
                links.push({
                    name:sports[Object.keys(sports)[0]],
                    link:""
                });
                links.push({
                    name:"COMPETICIONES",
                    link:""
                });
                links.push({
                    name:countries[Object.keys(countries)[0]],
                    link:""
                });
                links.push({
                    name:leagues[Object.keys(leagues)[0]],
                    link:""
                });
            }
            else if(Object.keys(sports).length > 1){
                links.push({
                    name:"COMPETICIONES",
                    link:""
                });
            }
            else if(Object.keys(countries).length > 1 && Object.keys(countries).length < 4 && Object.keys(sports).length == 1){
                links.push({
                    name:sports[Object.keys(sports)[0]],
                    link:""
                });
                links.push({
                    name:"COMPETICIONES",
                    link:""
                });
                let countries_str = []
                Object.keys(countries).forEach(country => {
                    countries_str.push(countries[country]);
                });
                links.push({
                    name:countries_str.join(" - "),
                    link:""
                });
            }
            else if(Object.keys(countries).length == 1 && Object.keys(sports).length == 1 && Object.keys(leagues).length > 1){
                links.push({
                    name:sports[Object.keys(sports)[0]],
                    link:""
                });
                links.push({
                    name:"COMPETICIONES",
                    link:""
                });
                links.push({
                    name:countries[Object.keys(countries)[0]],
                    link:""
                });
            }
            else{
                links.push({
                    name:"COMPETICIONES",
                    link:""
                });
            }
            return links;
        }
    },
    methods:{
        hide_show_liga(id,index){
            this.$root.$emit('hide_show_ligas',id);
            let arrow = this.$refs.arrow[index];
            if(arrow.classList.contains('fa-angle-up')){
                arrow.classList.remove('fa-angle-up');
                arrow.classList.add('fa-angle-down');
            }
            else{
                arrow.classList.remove('fa-angle-down');
                arrow.classList.add('fa-angle-up');
            }
        }
    },
    created(){
        
    }
}
</script>

<style>

</style>