var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"px-0"},[_c('div',{staticClass:"table-responsive"},[_c('div',[_c('div',{staticClass:"px-1"},[_c('div',{staticClass:"accordion",attrs:{"id":'matchPrematch-'+_vm.game.id}},[_c('div',{staticClass:"accordion-item border border-secondary p-0",style:(_vm.Skin.prematch_preview.fondo)},[_c('div',{staticClass:"accordion-header px-1 pe-1",attrs:{"id":'heading-'+_vm.game.id}},[(_vm.screen_width>_vm.Skin.break_point)?_c('div',{staticClass:"d-flex",style:(_vm.Skin.prematch_preview.fondo)},[_c('div',{staticClass:"col col-lg-4 col-sm-5 p-1",staticStyle:{"line-height":"15px","width":"33%"}},[_c('div',[_c('span',{style:(_vm.Skin.prematch_preview.text_date)},[_vm._v(_vm._s(_vm.format_date(_vm.game.start_ts)))]),_c('br'),_c('span',{staticClass:"rounded-circle me-1",class:'phoca-flag ' + _vm.country_flag,staticStyle:{"max-width":"15px","height":"15px"}}),_c('span',{style:(_vm.Skin.prematch_preview.text_liga)},[_vm._v(_vm._s(_vm.game.liga)+" ")]),_c('span',{style:(_vm.Skin.prematch_preview.text_country)},[_vm._v("- "+_vm._s(_vm.game.country)+" ")]),_c('br'),_c('span',{style:(_vm.Skin.prematch_preview.text_team)},[_vm._v(_vm._s(_vm.game.team1_name)+" ")]),_c('br'),_c('span',{style:(_vm.Skin.prematch_preview.text_team)},[_vm._v(_vm._s(_vm.game.team2_name)+" ")])])]),_c('div',{staticClass:"col col-lg-4 col-sm-6 p-0",staticStyle:{"width":"33%"}},[_c('table',{staticClass:"mx-auto my-2",staticStyle:{"width":"95%"}},[_c('tr',[(_vm.Handicap.Home)?_c('Odd',{attrs:{"Skin":_vm.Skin,"logro":_vm.Handicap.Home,"active_state":_vm.selected_odds[_vm.Handicap.Home.id],"teaserVal":_vm.teaser_type},nativeOn:{"click":function($event){return _vm.odd_selected(
                              _vm.game.id,
                              _vm.Handicap.Home,
                              'Puntos Handicap',
                              'MatchHandicap',
                              _vm.Handicap.Home.market_id,
                              _vm.game.team1_name,
                              _vm.game.team2_name,
                              _vm.game.start_ts,
                              'Teaser',
                            )}}}):_c('th',{staticClass:"py-2"},[_c('div',{staticClass:"py-2 pb-2 px-0 pe-0 text-center",style:(_vm.Skin.prematch_preview.odd+'min-width:30px;')},[_c('span',{staticStyle:{"font-size":"xx-small"}},[_c('i',{staticClass:"fas fa-lock"})])])]),(_vm.Handicap.Away)?_c('Odd',{attrs:{"Skin":_vm.Skin,"logro":_vm.Handicap.Away,"active_state":_vm.selected_odds[_vm.Handicap.Away.id],"teaserVal":_vm.teaser_type},nativeOn:{"click":function($event){return _vm.odd_selected(
                              _vm.game.id,
                              _vm.Handicap.Away,
                              'Puntos Handicap',
                              'MatchHandicap',
                              _vm.Handicap.Away.market_id,
                              _vm.game.team1_name,
                              _vm.game.team2_name,
                              _vm.game.start_ts,
                              'Teaser',
                            )}}}):_c('th',{staticClass:"py-2"},[_c('div',{staticClass:"py-2 pb-2 px-0 pe-0 text-center",style:(_vm.Skin.prematch_preview.odd+'min-width:30px;')},[_c('span',{staticStyle:{"font-size":"xx-small"}},[_c('i',{staticClass:"fas fa-lock"})])])])],1)])]),_c('div',{staticClass:"col col-3 p-0 d-none d-lg-block",staticStyle:{"width":"33%"}},[_c('table',{staticClass:"mx-auto my-2",staticStyle:{"width":"95%"}},[_c('tr',[_c('th',{staticClass:"text-center p-0 text-dark",staticStyle:{"font-size":"small"}}),(_vm.OverUnder.Over)?_c('Odd',{key:_vm.OverUnder.Over.id+'content',attrs:{"Skin":_vm.Skin,"logro":_vm.OverUnder.Over,"active_state":_vm.selected_odds[_vm.OverUnder.Over.id],"teaserVal":_vm.teaser_type},nativeOn:{"click":function($event){return _vm.odd_selected(
                              _vm.game.id,
                              _vm.OverUnder.Over,
                              'total de goles',
                              'OverUnder',
                              _vm.OverUnder.Over.market_id,
                              _vm.game.team1_name,
                              _vm.game.team2_name,
                              _vm.game.start_ts,
                              'Teaser',
                            )}}}):_c('th',{staticClass:"py-2"},[_c('div',{staticClass:"py-2 pb-2 px-0 pe-0 text-center",style:(_vm.Skin.prematch_preview.odd+'min-width:30px;')},[_c('span',{staticStyle:{"font-size":"xx-small"}},[_c('i',{staticClass:"fas fa-lock"})])])]),(_vm.OverUnder.Under)?_c('Odd',{key:_vm.OverUnder.Under.id+'content',attrs:{"Skin":_vm.Skin,"logro":_vm.OverUnder.Under,"active_state":_vm.selected_odds[_vm.OverUnder.Under.id],"teaserVal":_vm.teaser_type},nativeOn:{"click":function($event){return _vm.odd_selected(
                              _vm.game.id,
                              _vm.OverUnder.Under,
                              'total de goles',
                              'OverUnder',
                              _vm.OverUnder.Under.market_id,
                              _vm.game.team1_name,
                              _vm.game.team2_name,
                              _vm.game.start_ts,
                              'Teaser',
                            )}}}):_c('th',{staticClass:"py-2"},[_c('div',{staticClass:"py-2 pb-2 px-0 pe-0 text-center",style:(_vm.Skin.prematch_preview.odd+'min-width:30px;')},[_c('span',{staticStyle:{"font-size":"xx-small"}},[_c('i',{staticClass:"fas fa-lock"})])])])],1)])])]):_c('div',[_c('div',{staticClass:"p-1",staticStyle:{"display":"flex","width":"100%","position":"relative"}},[_c('span',{staticClass:"me-1",style:(_vm.Skin.prematch_preview.text_date)},[_vm._v(_vm._s(_vm.format_date(_vm.game.start_ts)))]),_c('br'),_c('span',{staticClass:"rounded-circle me-1",class:'phoca-flag ' + _vm.country_flag,staticStyle:{"max-width":"20px","height":"20px"}}),_c('span',{staticClass:"long_text2",style:(_vm.Skin.prematch_preview.text_liga)},[_vm._v(_vm._s(_vm.game.liga)+" - "+_vm._s(_vm.game.country))])]),_c('div',{staticClass:"px-1"},[_c('span',{staticClass:"long_text1",staticStyle:{"font-size":"14px"},style:(_vm.Skin.prematch_preview.text_team)},[_vm._v(_vm._s(_vm.game.team1_name))]),_vm._v(" -VS- "),_c('span',{staticClass:"long_text1",staticStyle:{"font-size":"14px"},style:(_vm.Skin.prematch_preview.text_team)},[_vm._v(_vm._s(_vm.game.team2_name))])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"width":"50%"}},[_c('table',{staticClass:"mx-auto my-2",staticStyle:{"width":"95%"}},[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.translate("handicap_points")))])]),_c('tr',[(_vm.Handicap.Home)?_c('Odd',{attrs:{"Skin":_vm.Skin,"logro":_vm.Handicap.Home,"active_state":_vm.selected_odds[_vm.Handicap.Home.id],"teaserVal":_vm.teaser_type},nativeOn:{"click":function($event){return _vm.odd_selected(
                                      _vm.game.id,
                                      _vm.Handicap.Home,
                                      'Puntos Handicap',
                                      'MatchHandicap',
                                      _vm.Handicap.Home.market_id,
                                      _vm.game.team1_name,
                                      _vm.game.team2_name,
                                      _vm.game.start_ts,
                                      'Teaser',
                                  )}}}):_c('th',{staticClass:"py-2"},[_c('div',{staticClass:"py-2 pb-2 px-0 pe-0 text-center",style:(_vm.Skin.prematch_preview.odd+'min-width:30px;')},[_c('span',{staticStyle:{"font-size":"xx-small"}},[_c('i',{staticClass:"fas fa-lock"})])])]),(_vm.Handicap.Away)?_c('Odd',{attrs:{"Skin":_vm.Skin,"logro":_vm.Handicap.Away,"active_state":_vm.selected_odds[_vm.Handicap.Away.id],"teaserVal":_vm.teaser_type},nativeOn:{"click":function($event){return _vm.odd_selected(
                                      _vm.game.id,
                                      _vm.Handicap.Away,
                                      'Puntos Handicap',
                                      'MatchHandicap',
                                      _vm.Handicap.Away.market_id,
                                      _vm.game.team1_name,
                                      _vm.game.team2_name,
                                      _vm.game.start_ts,
                                      'Teaser',
                                  )}}}):_c('th',{staticClass:"py-2"},[_c('div',{staticClass:"py-2 pb-2 px-0 pe-0 text-center",style:(_vm.Skin.prematch_preview.odd+'min-width:30px;')},[_c('span',{staticStyle:{"font-size":"xx-small"}},[_c('i',{staticClass:"fas fa-lock"})])])])],1)])]),_c('div',{staticStyle:{"width":"50%"}},[_c('table',{staticClass:"mx-auto my-2",staticStyle:{"width":"95%"}},[_vm._m(0),_c('tr',[(_vm.OverUnder.Over)?_c('Odd',{key:_vm.OverUnder.Over.id+'content',attrs:{"Skin":_vm.Skin,"logro":_vm.OverUnder.Over,"active_state":_vm.selected_odds[_vm.OverUnder.Over.id],"teaserVal":_vm.teaser_type},nativeOn:{"click":function($event){return _vm.odd_selected(
                                      _vm.game.id,
                                      _vm.OverUnder.Over,
                                      'total de goles',
                                      'OverUnder',
                                      _vm.OverUnder.Over.market_id,
                                      _vm.game.team1_name,
                                      _vm.game.team2_name,
                                      _vm.game.start_ts,
                                      'Teaser',
                                  )}}}):_c('th',{staticClass:"py-2"},[_c('div',{staticClass:"py-2 pb-2 px-0 pe-0 text-center",style:(_vm.Skin.prematch_preview.odd+'min-width:30px;')},[_c('span',{staticStyle:{"font-size":"xx-small"}},[_c('i',{staticClass:"fas fa-lock"})])])]),(_vm.OverUnder.Under)?_c('Odd',{key:_vm.OverUnder.Under.id+'content',attrs:{"Skin":_vm.Skin,"logro":_vm.OverUnder.Under,"active_state":_vm.selected_odds[_vm.OverUnder.Under.id],"teaserVal":_vm.teaser_type},nativeOn:{"click":function($event){return _vm.odd_selected(
                                      _vm.game.id,
                                      _vm.OverUnder.Under,
                                      'total de goles',
                                      'OverUnder',
                                      _vm.OverUnder.Under.market_id,
                                      _vm.game.team1_name,
                                      _vm.game.team2_name,
                                      _vm.game.start_ts,
                                      'Teaser',
                                  )}}}):_c('th',{staticClass:"py-2"},[_c('div',{staticClass:"py-2 pb-2 px-0 pe-0 text-center",style:(_vm.Skin.prematch_preview.odd+'min-width:30px;')},[_c('span',{staticStyle:{"font-size":"xx-small"}},[_c('i',{staticClass:"fas fa-lock"})])])])],1)])])])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("Total de puntos")])])
}]

export { render, staticRenderFns }