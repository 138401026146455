<template>
<div>
     <!-- Modal -->
    <div class="modal fade" id="myAccount" tabindex="-1" aria-labelledby="myAccountLabel" aria-hidden="true" data-bs-backdrop="false">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content p-0" :style="skin.my_account.modal_background">
                <div class="modal-header px-3 p-1" :style="skin.my_account.header" style="border-bottom: none;">
                    <span v-if="screen_width<skin.break_point" id="myAccountCollapseBtn" data-bs-toggle="collapse" data-bs-target="#myAccountCollapse" aria-controls="myAccountCollapse" aria-expanded="false" aria-label="Toggle navigation" style="font-size:x-large;" class="p-1"><i class="fas fa-bars"></i></span>
                    <h5>{{ user.user_data.data ? user.user_data.data.name : '' }}</h5>
                    <button @click="change_view('')" id="myAccountCloseBtn" :style="skin.my_account.close_btn" style="background:transparent;font-size:x-large;" type="button" class="btn-close m-1" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                </div>
                <div v-if="user.user_data.data" class="modal-body p-2" :style="skin.my_account.content_background">
                    <div v-if="screen_width<skin.break_point" class="collapse" id="myAccountCollapse">
                        <div class="p-2" :style="skin.my_account.main_menu_background"> 
                            <!-- Saldo Pricipal -->
                            <div class="card text-white mb-3 shadow shadow-sm" style="background: #36a03d;">
                                <div class="card-header">{{translate("main_balance")}}</div>
                                <div class="card-body">
                                    <h5 class="card-title mx-1"><strong>{{ bonus?user.user_data.data.available_balance.toFixed(skin.qBet_config.decimalsQty):user.user_data.data.bonus_balance.toFixed(skin.qBet_config.decimalsQty) }} {{ user.user_data.data.currency }}</strong></h5>
                                    <div v-if="user.user_data.data.rol!='RESELLER'">
                                        <div v-if="user.user_data.data.parent!='none'" class="row p-0">
                                            <div v-if="skin.my_account.user_recharge.enabled" class="col-6 text-center p-1 pb-0" >
                                                <button class="btn btn-success" @click="change_view('user_recharge')" style="width: 100%;background: #6eb971;"><i class="fas fa-wallet me-2"></i>{{translate("recharge")}}</button>
                                            </div>
                                            <div class="text-center p-1 pb-0" :class="skin.my_account.user_recharge.enabled?'col-6':'col-12'">
                                                <button @click="change_view('request_payment')" class="btn btn-success" style="width: 100%;background: #6eb971;"><i class="fas fa-hand-holding-usd me-2"></i>{{translate("request_withdrawal")}}</button>
                                            </div>
                                        </div>
                                        <div v-else-if="user.user_data.data.parent=='none'" class="row p-0">
                                            <div v-if="skin.my_account.user_recharge.enabled" class="col-6 text-center p-1 pb-0" >
                                                <button class="btn btn-success" @click="change_view('user_recharge')" style="width: 100%;background: #6eb971;"><i class="fas fa-wallet me-2"></i>{{translate("recharge")}}</button>
                                            </div>
                                            <div v-if="skin.my_account.request_payment_target.enabled" class="text-center p-1 pb-0" :class="skin.my_account.user_recharge.enabled?'col-6':'col-12'">
                                                <button @click="change_view('request_payment')" class="btn btn-success" style="width: 100%;background: #6eb971;"><i class="fas fa-hand-holding-usd me-2"></i>{{translate("request_withdrawal")}}</button>
                                            </div>
                                        </div>
                                        <div v-else-if="skin.my_account.user_recharge.enabled">
                                            <div class="text-center p-1 pb-0">
                                                <button @click="change_view('user_recharge')" class="btn btn-success" style="width: 100%;background: #6eb971;"><i class="fas fa-wallet me-2"></i>{{translate("recharge")}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Saldo Bono -->
                            <div v-if="user.user_data.data.rol!='RESELLER' && bonus" class="card text-white mb-3 shadow shadow-sm" style="background: #b78f33;">
                                <div class="card-header">{{translate("bonus_balance")}}</div>
                                <div class="card-body">
                                    <h5 class="card-title mx-1"><strong>{{ user.user_data.data.bonus.toFixed(skin.qBet_config.decimalsQty) }} {{ user.user_data.data.currency }}</strong></h5>
                                    <div v-if="user.user_data.data.frozen > 0 && user.user_data.data.rol!='RESELLER'">
                                        <hr class="m-0">
                                        {{translate("frozen_balance")}}: {{ user.user_data.data.frozen.toFixed(skin.qBet_config.decimalsQty) }} {{ user.user_data.data.currency }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="skin.my_account.my_records" @click="change_view('my_records')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("my_bets")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol=='RESELLER' && skin.my_account.register_client" @click="change_view('register_client')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("enroll_customer")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol=='RESELLER' && skin.my_account.my_clients" @click="change_view('my_clients')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("my_clients")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol=='RESELLER' && skin.my_account.recharge" @click="change_view('recharge')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("recharge")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol!='RESELLER' && bonus" @click="change_view('bonus')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("bonus")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol!='RESELLER' && skin.my_account.user_recharge.enabled" @click="change_view('user_recharge')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("recharge")}}</span>
                            </div>
                            <div v-if="user.user_data.data.parent!='none' && skin.my_account.request_payment" @click="change_view('request_payment')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("request_withdrawal")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol=='RESELLER' && skin.my_account.withdraw" @click="change_view('withdraw')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("pay_withdrawal")}}</span>
                            </div>
                            <div v-if="skin.my_account.movements" @click="change_view('movements')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("movements")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol=='RESELLER' && skin.my_account.reports" @click="change_view('reports')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("reports")}}</span>
                            </div>
                        </div>
                    </div>
                    <div :style="skin.my_account.main_background" class="p-0" :class="screen_width>skin.break_point?'d-flex':''" style="height:100%;overflow:hidden;">
                        <div v-if="screen_width>skin.break_point" class="p-2" style="width: 20%;height:100%;overflow-y:auto;overflow-x:hidden;" :style="skin.my_account.main_menu_background">
                            <!-- Saldo Pricipal -->
                            <div class="card text-white mb-3 shadow shadow-sm" style="background: #36a03d;">
                                <div class="card-header">{{translate("main_balance")}}</div>
                                <div class="card-body">
                                    <h5 class="card-title mx-1"><strong>{{ bonus?user.user_data.data.available_balance.toFixed(skin.qBet_config.decimalsQty):user.user_data.data.bonus_balance.toFixed(skin.qBet_config.decimalsQty) }} {{ user.user_data.data.currency }}</strong></h5>
                                    <div v-if="user.user_data.data.rol!='RESELLER'">
                                        <div v-if="user.user_data.data.parent!='none'" class="row p-0">
                                            <div v-if="skin.my_account.user_recharge.enabled" class="col-6 text-center p-1 pb-0" >
                                                <button class="btn btn-success" @click="change_view('user_recharge')" style="width: 100%;background: #6eb971;"><i class="fas fa-wallet me-2"></i>{{translate("recharge")}}</button>
                                            </div>
                                            <div class="text-center p-1 pb-0" :class="skin.my_account.user_recharge.enabled?'col-6':'col-12'">
                                                <button @click="change_view('request_payment')" class="btn btn-success" style="width: 100%;background: #6eb971;"><i class="fas fa-hand-holding-usd me-2"></i>{{translate("request_withdrawal")}}</button>
                                            </div>
                                        </div>
                                        <div v-else-if="user.user_data.data.parent=='none'" class="row p-0">
                                            <div v-if="skin.my_account.user_recharge.enabled" class="col-6 text-center p-1 pb-0" >
                                                <button class="btn btn-success" @click="change_view('user_recharge')" style="width: 100%;background: #6eb971;"><i class="fas fa-wallet me-2"></i>{{translate("recharge")}}</button>
                                            </div>
                                            <div v-if="skin.my_account.request_payment_target.enabled" class="text-center p-1 pb-0" :class="skin.my_account.user_recharge.enabled?'col-6':'col-12'">
                                                <button @click="change_view('request_payment')" class="btn btn-success" style="width: 100%;background: #6eb971;"><i class="fas fa-hand-holding-usd me-2"></i>{{translate("request_withdrawal")}}</button>
                                            </div>
                                        </div>
                                        <div v-else-if="skin.my_account.user_recharge.enabled">
                                            <div class="text-center p-1 pb-0">
                                                <button @click="change_view('user_recharge')" class="btn btn-success" style="width: 100%;background: #6eb971;"><i class="fas fa-wallet me-2"></i>{{translate("recharge")}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Saldo Bono -->
                            <div v-if="user.user_data.data.rol!='RESELLER' && bonus" class="card text-white mb-3 shadow shadow-sm" style="background: #b78f33;">
                                <div class="card-header">{{translate("bonus_balance")}}</div>
                                <div class="card-body">
                                    <h5 class="card-title mx-1"><strong>{{ user.user_data.data.bonus }} {{ user.user_data.data.currency }}</strong></h5>
                                    <div v-if="user.user_data.data.frozen > 0 && user.user_data.data.rol!='RESELLER'">
                                        <hr class="m-0">
                                        {{translate("frozen_balance")}}: {{ user.user_data.data.frozen }} {{ user.user_data.data.currency }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="skin.my_account.my_records" @click="change_view('my_records')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("my_bets")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol=='RESELLER' && skin.my_account.register_client" @click="change_view('register_client')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("enroll_customer")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol=='RESELLER' && skin.my_account.my_clients" @click="change_view('my_clients')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("my_clients")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol=='RESELLER'&&skin.my_account.recharge" @click="change_view('recharge')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("recharge")}}</span>
                            </div>
                            <!--PENDIENTE AGREGAR BONUS A SKIN-->
                            <div v-if="user.user_data.data.rol!='RESELLER' && bonus" @click="change_view('bonus')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("bonus")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol!='RESELLER' && skin.my_account.user_recharge.enabled" @click="change_view('user_recharge')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("recharge")}}</span>
                            </div>
                            <div v-if="user.user_data.data.parent!='none' && skin.my_account.request_payment" @click="change_view('request_payment')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("request_withdrawal")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol=='RESELLER' && skin.my_account.withdraw" @click="change_view('withdraw')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("pay_withdrawal")}}</span>
                            </div>
                            <div v-if="skin.my_account.movements" @click="change_view('movements')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("movements")}}</span>
                            </div>
                            <div v-if="user.user_data.data.rol=='RESELLER' && skin.my_account.reports" @click="change_view('reports')" class="p-2" :style="skin.my_account.menu_item_border" style="cursor:pointer;">
                                <span>{{translate("reports")}}</span>
                            </div>
                        </div>
                        <div class="p-2 position-relative" style="height:100%;overflow-y:auto;overflow-x:hidden;" :style="screen_width>skin.break_point?'width:80%':''">
                            <register-client
                                v-if="actual_view=='register_client'"
                                :skin="skin"
                                :language="language"
                                :translation="translation"
                            />
                            <my-clients
                                v-if="actual_view=='my_clients'"
                                :skin="skin"
                                :language="language"
                                :translation="translation"
                            />
                            <recharge
                                v-if="actual_view=='recharge'"
                                :skin="skin"
                                :language="language"
                                :translation="translation"
                            />
                            <user-recharge
                                v-if="actual_view=='user_recharge'"
                                :skin="skin"
                                :language="language"
                                :translation="translation"
                            />
                            <request-payment
                                v-if="actual_view=='request_payment'"
                                :skin="skin"
                                :language="language"
                                :translation="translation"
                            />
                            <withdraw
                                v-if="actual_view=='withdraw'"
                                :skin="skin"
                                :language="language"
                                :translation="translation"
                            />
                            <movements
                                v-if="actual_view=='movements'"
                                :skin="skin"
                                :language="language"
                                :translation="translation"
                            />
                            <reports
                                v-if="actual_view=='reports'"
                                :skin="skin"
                                :language="language"
                                :translation="translation"
                            />
                            <bonus
                                v-if="actual_view=='bonus'"
                                :skin="skin"
                                :language="language"
                                :translation="translation"
                            />
                            <user-records
                                v-if="actual_view=='user_records'"
                                :skin="skin"
                                :language="language"
                                :translation="translation"
                                :user_data="user_data_records"
                            />
                            <div v-if="actual_view=='my_records'">
                                <div class="p-2" :style="skin.my_account.menu_item_border">
                                    <h5>{{translate("my_bets")}}</h5>
                                </div>
                                <records
                                    :skin="skin"
                                    :language="language"
                                    :translation="translation"
                                    :user_id="{id:null,nav_bar:false}"
                                    :source="'myAccount'"
                                />
                            </div>
                            <div v-if="actual_view==''" class="position-absolute top-50 start-50 translate-middle">
                                <div class="text-center">
                                    <span>Selecciona una opción del menú</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import registerClient from './my_account_components/register_client.vue';
import myClients from './my_account_components/my_clients.vue';
import recharge from './my_account_components/recharge.vue';
import userRecharge from './my_account_components/user_recharge.vue';
import withdraw from './my_account_components/withdraw.vue';
import movements from './my_account_components/movements.vue';
import reports from './my_account_components/reports.vue';
import userRecords from './my_account_components/user_records.vue';
import requestPayment from './my_account_components/request_payment.vue';
import records from '../main_lobby/main_records/main_records.vue';
import bonus from './my_account_components/bonus.vue';
export default {
    name:"myAccount",
    props:['skin','language','translation'],
    components: { DatePicker,registerClient,myClients,recharge,withdraw,movements,reports,userRecords,requestPayment,records,userRecharge,bonus },
    data(){
        return{
            date_range:[],
            actual_view:'',
            user_data_records:{},
            screen_width:0,
        }
    },
    computed:{
        nav_active(){
            return {
                '--nav-active-color':this.$props.skin.my_account.nav_active_color,
                '--nav-active-bar':this.$props.skin.my_account.nav_active_bar
            };
        },
        user(){
            return this.$store.getters['getUserData'];
        },
        bonus(){
            return this.$store.getters['getConfig'].SkinConfig.main.newbonus ? this.$store.getters['getConfig'].SkinConfig.main.newbonus : false;
        }
    },
    methods:{
        change_view(view){
            this.actual_view = view;
            if(document.getElementById('myAccountCollapseBtn') && view!=''){
                document.getElementById('myAccountCollapseBtn').click();
            }
        },
        sizeControl(){
            this.screen_width=window.innerWidth;
        },
        translate(text){
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
    },
    mounted(){
        window.addEventListener('resize', ()=>{this.sizeControl();});
        this.$root.$on('show_user_record', (data) => {
            this.user_data_records = data;
            this.actual_view = 'user_records';
        });
        this.$root.$on('show_user_recharge', () => {
            this.actual_view = 'user_recharge';
        });
    },
    created(){
        this.sizeControl();
    }
}
</script>

<style scoped>
    .nav_active{
        color:var(--nav-active-color);
        border-bottom: var(--nav-active-bar);
    }
</style>