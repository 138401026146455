<template>
  <div class="m-1">
      <div>
            <carousel v-if="events && events.length"  
            :paginationEnabled="false"
            :autoplay="false"
            :autoplayTimeout="3000"
            :autoplayHoverPause="true"
            :perPageCustom="[[300, 1.1],[451, 2.1],[1024, 2.1]]">
                <slide v-for="(event) in events" v-bind:key="event.id + 'main'">
                    <main-event
                        v-bind:game="event"
                        v-bind:skin="skin"
                        v-bind:translation="translation"
                        v-bind:language="language"
                        style="min-width:250px"
                    >
                    </main-event>
                </slide>
            </carousel>
      </div>
  </div>
</template>

<script>
import mainEvent from './main_event.vue'
import { Carousel, Slide } from 'vue-carousel';

export default {
    name:"main_events",
    props:['skin','translation','language'],
    components:{
        mainEvent,
        Carousel,
        Slide
    },
    data(){
        return{
        }
    },
    computed:{
        events(){
            let events = [];
            let ligas = [];
            let count = 0;
            let data = this.$store.getters['live/getSports']; 
            let regions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideRegions;
            let words_banned = this.$store.getters['getConfig'].SkinConfig.main.hideWords;
            let ligas_banned = this.$store.getters['getConfig'].SkinConfig.main.hideCompetitions;
            Object.keys(data).forEach(sport=>{
                if(data[sport].region && data[sport].id == 1){
                    Object.keys(data[sport].region).forEach(pais=>{
                        if(data[sport].region[pais]){
                            if(!regions_banned.includes(data[sport].region[pais].alias)){
                                if(data[sport].region[pais].competition){
                                    Object.keys(data[sport].region[pais].competition).forEach(liga=>{
                                        if(data[sport].region[pais].competition[liga]){
                                            data[sport].region[pais].competition[liga].sport = data[sport].id;
                                            data[sport].region[pais].competition[liga].country = data[sport].region[pais].name;
                                            ligas.push(data[sport].region[pais].competition[liga]);
                                        }
                                    });
                                }
                            }
                        }
                    });
                }
            });
            ligas.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            ligas.forEach(liga=>{
                if((liga ? (liga.game ? true : false) : false)){
                    Object.keys(liga.game).forEach(element=>{
                        if(liga.game[element] && liga.game[element].info && !liga.game[element].favorite){
                            let banned = false;
                            words_banned.forEach(word => {
                                if(liga.game[element].team1_name){
                                    if(liga.game[element].team1_name.includes(word)){
                                        banned = true;
                                    }
                                }
                                if(liga.game[element].team2_name){
                                    if(liga.game[element].team2_name.includes(word)){
                                        banned = true;
                                    }
                                }            
                            });
                            ligas_banned.forEach(word => {
                                    if(liga.name){
                                        if(liga.name.includes(word)){
                                            banned = true;
                                        }
                                    }           
                                });
                            if(!banned){
                                liga.game[element]['sport'] = this.$props.deporte;
                                if(count<11){
                                    if(liga.game[element].market){
                                        Object.keys(liga.game[element].market).forEach(mercado=>{
                                            if(liga.game[element].market[mercado]){
                                                if(liga.game[element].market[mercado].type == "P1XP2" && 
                                                liga.game[element].market[mercado].event && liga.game[element].info.current_game_state != "notstarted"){
                                                    liga.game[element].liga = liga.name;
                                                    liga.game[element].sport = liga.sport;
                                                    liga.game[element].country = liga.country;
                                                    events.push(liga.game[element]);
                                                    count++;
                                                }
                                            }
                                        })
                                    }
                                }
                            }
                        }
                    })
                }
            });
            return events; 
        }
    },
}
</script>

<style>

</style>