<template>
    <div class="text-center p-3">
        <span :style="skin.menu_prematch.calendar_text">{{translate("schedule")}}</span>
        <hr class="my-1 border border-1 border-white"/>
        <div :style="skin.menu_prematch.calendar+ 'font-size:small'" class="row">
            <div @click="select_time('1hh')" id="1hh" class="border border-1 border-white col col-2 p-0 m-0 time" :style="time2 == '1hh' ? skin.menu_prematch.calendar_selected : ''" style="cursor:pointer">1HH</div>
            <div @click="select_time('3hh')" id="3hh" class="border border-1 border-white col col-2 p-0 m-0 time" :style="time2 == '3hh' ? skin.menu_prematch.calendar_selected : ''" style="cursor:pointer">3HH</div>
            <div @click="select_time('hoy')" id="hoy" class="border border-1 border-white col col-2 p-0 m-0 time" :style="time2 == 'hoy' ? skin.menu_prematch.calendar_selected : ''" style="cursor:pointer">{{translate("today")}}</div>
            <div @click="select_time('hoy1')" id="hoy1" class="border border-1 border-white col col-3 p-0 m-0 time" :style="time2 == 'hoy1' ? skin.menu_prematch.calendar_selected : ''" style="cursor:pointer">{{translate("tomorrow_sportium")}}</div>
            <div @click="select_time('todos')" id="todos" class="border border-1 border-white col col-3 p-0 m-0 time" :style="time2 == 'todos' ? skin.menu_prematch.calendar_selected : ''" style="cursor:pointer">{{translate("all")}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name:"schedule",
    props:['skin','translation','language'],
    methods:{
        select_time(time){
            let timer = {
                "1hh":{
                    "@now": {
                        "@gte": 0,
                        "@lt": 3600
                    }
                },
                "3hh":{
                    "@now": {
                        "@gte": 0,
                        "@lt": 10800
                    }
                },
                "hoy":{
                    "@now": {
                        "@gte": 0,
                        "@lt": 57600
                    }
                },
                "hoy1":{
                    "@now": {
                        "@gte": 57600,
                        "@lt": 144000
                    }
                },
                "todos":{},
            }  
            this.$store.dispatch('prematch_2/select_time',{name:time,timer:timer[time]});
        },
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        }, 
    },
    computed:{
        time2(){
            return this.$store.getters['prematch_2/getTime'];
        },
    }
}
</script>

<style>

</style>