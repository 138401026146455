<template>
  <div>
    <div :style="Skin.sport_table.fondo" class="row text-center">
      <div class="col col-lg-8 col-sm-6 py-2">
        <span :style="Skin.sport_table.text">{{ translate("event") }}</span>
      </div>
      <div class="col col-lg-3 col-sm-5 py-2" style="min-width: 10.58%">
        <span :style="Skin.sport_table.text">1X2</span>
      </div>
      <div class="col col-1" style="max-width: 8%"></div>
    </div>
    <div class="row text-center" :style="Skin.sport_table.fondo">
      <div class="col col-lg-8 col-sm-6">
      </div>
      <div class="col col-lg-3 col-sm-5" style="min-width: 10.58%">
        <table style="width: 100%">
          <tr>
            <th>
              <span :style="Skin.sport_table.text">1</span>
            </th>
            <th>
              <span :style="Skin.sport_table.text">X</span>
            </th>
            <th>
              <span :style="Skin.sport_table.text">2</span>
            </th>
          </tr>
        </table>
      </div>
      <div class="col col-1 p-0" style="max-width: 8%">
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "balonmano",
  props: ["Skin", "translation", "language"],
  data() {
    return {};
  },
  methods: {
    translate(text) {
      if (this.$props.translation[text]) {
        return this.$props.translation[text][this.$props.language];
      }
      return text;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
