<template>
  <div>
    <div class="px-0">
      <div v-if="!data_loaded" style="color:white">
        <div class="row">
          <div class="col col-5">

          </div>
          <div class="col col-7">
            <div class="spinner-border text-warning mx-auto" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <slot name="header"></slot>
      <div v-if="visible">
        <slot name="table"></slot>
        <div v-if="liga.deporte['id']==1" class="table-responsive">
          <div class="px-3">
            <div
              v-for="(encuentro) in encuentros"
              v-bind:key="encuentro.id"
              >
              <div class="accordion row" :id="'match-'+encuentro.id">
                <div class="accordion-item border border-secondary p-0" :style="Skin.fondo2">
                  <div class="accordion-header px-3 pe-3" :id="'heading-'+encuentro.id" >
                    <div class="row" :style="Skin.fondo2">
                      <div @click="set_main_event_prematch(encuentro.id)" class="col col-lg-4 col-sm-5 p-1" style="line-height:15px;width:34%;cursor:pointer;">
                        <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ format_date(encuentro.time) }} </span><br>
                        <span class="rounded-circle me-1" :class="'phoca-flag ' + liga.pais_code" style="max-width:15px;height:15px"></span>
                        <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.liga.name }} </span> - 
                        <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.pais['name_'+language] }} </span><br>
                        <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[0].name}} </span><br>
                        <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[1].name }} </span>
                      </div>
                      <div class="col col-lg-4 col-sm-6 p-0" style="width:30%">
                        <table v-if="encuentro.markets.odds.results[0].main" style="width:95%" class="mx-auto my-2">
                          <tr v-if="'full_time_result' in encuentro.markets.odds.results[0].main.sp">
                            <Odd
                              v-for="(logro) in encuentro.markets.odds.results[0].main.sp.full_time_result.odds"
                              v-bind:key="logro.id+'content'"
                              v-bind:rate="logro.odds"
                              v-bind:Skin="Skin"
                              v-bind:logro="logro"
                              v-bind:active_state="selected_odds[logro.id]"
                              @click.native="odd_selected(encuentro.id,logro,'full_time_result',encuentro.players[0].name,encuentro.players[1].name,encuentro.time,'main')"
                            >
                            </Odd>
                          </tr>
                        </table>
                      </div>
                      <div class="col col-3 p-0 d-none d-lg-block" style="width:30%">
                        <table v-if="encuentro.markets.odds.results[0].main" style="width:95%" class="mx-auto my-2">
                          <tr v-if="'goals_over_under' in encuentro.markets.odds.results[0].main.sp">
                            <th class="text-center p-0 text-dark" style="font-size:small">
                              <div
                              class="text-center px-1 pe-1 text-white"
                              >
                                {{ Object.keys(encuentro.markets.odds.results[0].main.sp.goals_over_under.odds).length>0 ? 
                                  encuentro.markets.odds.results[0].main.sp.goals_over_under.odds[0].name : '' }}
                              </div>
                            </th>
                            <Odd
                              v-for="(logro) in encuentro.markets.odds.results[0].main.sp.goals_over_under.odds"
                              v-bind:key="logro.id+'content'"
                              v-bind:rate="logro.odds"
                              v-bind:Skin="Skin"
                              v-bind:logro="logro"
                              v-bind:active_state="selected_odds[logro.id]"
                              @click.native="odd_selected(encuentro.id,logro,'goals_over_under',encuentro.players[0].name,encuentro.players[1].name,encuentro.time,'main')"
                            >
                            </Odd>
                          </tr>
                        </table>
                      </div>
                      <div class="col col-1 px-0 py-2 text-center" style="max-width:6%">
                        <div class="text1 collapsed py-3 m-0" style="cursor:pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+encuentro.id" aria-expanded="false" :aria-controls="'collapse-'+encuentro.id">
                          <span :class="Skin.text1" :style="Skin.color1" style="font-size:large;color:#ffd029;"><i class="fas fa-plus-square"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <AllOdds
                  :encuentro="encuentro"
                  :language="language"
                  :Skin="Skin"
                  :liga="liga"
                  :translation="translation"
                  :collapsed="true"
                  >
                  </AllOdds>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="liga.deporte['id']==3 || liga.deporte['id']==9 || liga.deporte['id']==14 || liga.deporte['id']==15" class="table-responsive">
          <div class="px-3 pe-3">
            <div
                v-for="(encuentro) in encuentros"
                v-bind:key="encuentro.id"
                >
                <div class="accordion row" :id="'match-'+encuentro.id">
                  <div class="accordion-item border border-secondary" :style="Skin.fondo2">
                    <div class="accordion-header" :id="'heading-'+encuentro.id" >
                      <div class="row" :style="Skin.fondo2">
                        <div @click="set_main_event_prematch(encuentro.id)" class="col col-lg-8 col-sm-6 p-1" style="line-height:15px;cursor:pointer;">
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ format_date(encuentro.time) }} </span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + liga.pais_code" style="max-width:15px;height:15px"></span>
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.liga.name }} </span> - 
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.pais['name_'+language] }} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[0].name}} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[1].name }} </span>
                        </div>
                        <div class="col col-lg-3 col-sm-5 p-0" style="min-width: 10.58%">
                          <table v-if="encuentro.markets.odds.results[0].main" style="width:95%" class="mx-auto my-2">
                            <tr v-if="winner_title[liga.deporte['id']] in encuentro.markets.odds.results[0].main.sp">
                              <Odd
                                v-for="(logro) in encuentro.markets.odds.results[0].main.sp[winner_title[liga.deporte['id']]].odds"
                                v-bind:key="logro.id+'content'"
                                v-bind:rate="logro.odds"
                                v-bind:Skin="Skin"
                                v-bind:logro="logro"
                                v-bind:active_state="selected_odds[logro.id]"
                                @click.native="odd_selected(encuentro.id,logro,winner_title[liga.deporte['id']],encuentro.players[0].name,encuentro.players[1].name,encuentro.time,'main')"
                              >
                              </Odd>
                            </tr>
                          </table>
                        </div>
                        <div class="col col-1 px-0 py-2 text-center" style="max-width:8%">
                          <div class="text1 collapsed py-1 m-0" style="cursor:pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+encuentro.id" aria-expanded="false" :aria-controls="'collapse-'+encuentro.id">
                            <span :class="Skin.text1" :style="Skin.color1" style="font-size:large;color:#ffd029;"><i class="fas fa-plus-square"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <AllOdds
                    :encuentro="encuentro"
                    :language="language"
                    :Skin="Skin"
                    :liga="liga"
                    :translation="translation"
                    :collapsed="true"
                    >
                    </AllOdds>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div v-if="liga.deporte['id']==8" class="table-responsive">
          <div class="px-3 pe-3">
            <div
                v-for="(encuentro) in encuentros"
                v-bind:key="encuentro.id"
                >
                <div class="accordion row" :id="'match-'+encuentro.id">
                  <div class="accordion-item border border-secondary" :style="Skin.fondo2">
                    <div class="accordion-header" :id="'heading-'+encuentro.id" >
                      <div class="row" :style="Skin.fondo2">
                        <div @click="set_main_event_prematch(encuentro.id)" class="col col-lg-8 col-sm-6 p-1" style="line-height:15px;cursor:pointer;">
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ format_date(encuentro.time) }} </span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + liga.pais_code" style="max-width:15px;height:15px"></span>
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.liga.name }} </span> - 
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.pais['name_'+language] }} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[0].name}} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[1].name }} </span>
                        </div>
                        <div class="col col-lg-3 col-sm-5 p-0" style="min-width: 10.58%">
                          <table v-if="encuentro.markets.odds.results[0].main" style="width:95%" class="mx-auto my-2">
                            <tr v-if="'game_betting_3_way' in encuentro.markets.odds.results[0].main.sp">
                              <Odd
                                v-for="(logro) in rugby_main(encuentro.markets.odds.results[0].main.sp.game_betting_3_way.odds)"
                                v-bind:key="logro.id+'content'"
                                v-bind:rate="logro.odds"
                                v-bind:Skin="Skin"
                                v-bind:logro="logro"
                                v-bind:active_state="selected_odds[logro.id]"
                                @click.native="odd_selected(encuentro.id,logro,'game_betting_3_way',encuentro.players[0].name,encuentro.players[1].name,encuentro.time,'main')"
                              >
                              </Odd>
                            </tr>
                          </table>
                        </div>
                        <div class="col col-1 px-0 py-2 text-center" style="max-width:8%">
                          <div class="text1 collapsed py-1 m-0" style="cursor:pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+encuentro.id" aria-expanded="false" :aria-controls="'collapse-'+encuentro.id">
                            <span :class="Skin.text1" :style="Skin.color1" style="font-size:large;color:#ffd029;"><i class="fas fa-plus-square"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <AllOdds
                    :encuentro="encuentro"
                    :language="language"
                    :Skin="Skin"
                    :liga="liga"
                    :translation="translation"
                    :collapsed="true"
                    >
                    </AllOdds>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div v-if="liga.deporte['id']==12 || liga.deporte['id']==16
          || liga.deporte['id']==17 || liga.deporte['id']==18
          || liga.deporte['id']==91" class="table-responsive">
          <div class="px-3 pe-3" >
            <div
                v-for="(encuentro) in encuentros"
                v-bind:key="encuentro.id"
                >
                <div class="accordion row" :id="'match-'+encuentro.id">
                  <div class="accordion-item border border-secondary" :style="Skin.fondo2">
                    <div class="accordion-header" :id="'heading-'+encuentro.id" >
                      <div class="row" :style="Skin.fondo2">
                        <div @click="set_main_event_prematch(encuentro.id)" class="col col-lg-8 col-sm-6 p-1" style="line-height:15px;cursor:pointer">
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ format_date(encuentro.time) }} </span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + liga.pais_code" style="max-width:15px;height:15px"></span>
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.liga.name }} </span> - 
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.pais['name_'+language] }} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[0].name}} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[1].name }} </span>
                        </div>
                        <div class="col col-lg-3 col-sm-5 p-0" style="min-width: 10.58%">
                          <table v-if="encuentro.markets.odds.results[0].main" style="width:95%" class="mx-auto my-2">
                            <tr v-if="'game_lines' in encuentro.markets.odds.results[0].main.sp">
                              <Odd
                                v-for="(logro) in futbol_americano_main(encuentro.markets.odds.results[0].main.sp.game_lines.odds)"
                                v-bind:key="logro.id+'content'"
                                v-bind:rate="logro.odds"
                                v-bind:Skin="Skin"
                                v-bind:logro="logro"
                                v-bind:active_state="selected_odds[logro.id]"
                                @click.native="odd_selected(encuentro.id,logro,'game_lines',encuentro.players[0].name,encuentro.players[1].name,encuentro.time,'main')"
                              >
                              </Odd>
                            </tr>
                          </table>
                        </div>
                        <div class="col col-1 px-0 py-2 text-center" style="max-width:8%">
                          <div class="text1 collapsed py-1 m-0" style="cursor:pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+encuentro.id" aria-expanded="false" :aria-controls="'collapse-'+encuentro.id">
                            <span :class="Skin.text1" :style="Skin.color1" style="font-size:large;color:#ffd029;"><i class="fas fa-plus-square"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <AllOdds
                    :encuentro="encuentro"
                    :language="language"
                    :Skin="Skin"
                    :liga="liga"
                    :translation="translation"
                    :collapsed="true"
                    >
                    </AllOdds>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div v-if="liga.deporte['id']==13" class="table-responsive">
          <div class="px-3 pe-3">
            <div
                v-for="(encuentro) in encuentros"
                v-bind:key="encuentro.id"
                >
                <div class="accordion row" :id="'match-'+encuentro.id">
                  <div class="accordion-item border border-secondary" :style="Skin.fondo2">
                    <div class="accordion-header" :id="'heading-'+encuentro.id" >
                      <div class="row" :style="Skin.fondo2">
                        <div @click="set_main_event_prematch(encuentro.id)" class="col col-lg-5 col-sm-6 p-1" style="line-height:15px;cursor:pointer;">
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ format_date(encuentro.time) }} </span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + liga.pais_code" style="max-width:15px;height:15px"></span>
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.liga.name }} </span> - 
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.pais['name_'+language] }} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[0].name}} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[1].name }} </span>
                        </div>
                        <div class="col col-lg-3 col-sm-5 p-0" style="min-width: 10.58%">
                          <table v-if="encuentro.markets.odds.results[0].main" style="width:95%" class="mx-auto my-2">
                            <tr v-if="'to_win_match' in encuentro.markets.odds.results[0].main.sp">
                              <Odd
                                v-for="(logro) in encuentro.markets.odds.results[0].main.sp.to_win_match.odds"
                                v-bind:key="logro.id+'content'"
                                v-bind:rate="logro.odds"
                                v-bind:Skin="Skin"
                                v-bind:logro="logro"
                                v-bind:active_state="selected_odds[logro.id]"
                                @click.native="odd_selected(encuentro.id,logro,'to_win_match',encuentro.players[0].name,encuentro.players[1].name,encuentro.time,'main')"
                              >
                              </Odd>
                            </tr>
                          </table>
                        </div>
                        <div class="col col-3 p-0 d-none d-lg-block" style="min-width: 10.58%">
                          <table v-if="encuentro.markets.odds.results[0].main" style="width:95%" class="mx-auto my-2">
                            <tr v-if="'first_set_winner' in encuentro.markets.odds.results[0].main.sp">
                              <Odd
                                v-for="(logro) in encuentro.markets.odds.results[0].main.sp.first_set_winner.odds"
                                v-bind:key="logro.id+'content'"
                                v-bind:rate="logro.odds"
                                v-bind:Skin="Skin"
                                v-bind:logro="logro"
                                v-bind:active_state="selected_odds[logro.id]"
                                @click.native="odd_selected(encuentro.id,logro,'first_set_winner',encuentro.markets.odds.results[0].main.sp.first_set_winner,encuentro.players[0].name,encuentro.players[1].name,encuentro.time,'main')"
                              >
                              </Odd>
                            </tr>
                          </table>
                        </div>
                        <div class="col col-1 px-0 py-2 text-center" style="max-width:8%">
                          <div class="text1 collapsed py-1 m-0" style="cursor:pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+encuentro.id" aria-expanded="false" :aria-controls="'collapse-'+encuentro.id">
                            <span :class="Skin.text1" :style="Skin.color1" style="font-size:large;color:#ffd029;"><i class="fas fa-plus-square"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <AllOdds
                    :encuentro="encuentro"
                    :language="language"
                    :Skin="Skin"
                    :liga="liga"
                    :translation="translation"
                    :collapsed="true"
                    >
                    </AllOdds>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div v-if="liga.deporte['id']==78 || liga.deporte['id']==110" class="table-responsive">
          <div class="px-3 pe-3">
            <div
              v-for="(encuentro) in encuentros"
              v-bind:key="encuentro.id"
              >
              <div class="accordion row" :id="'match-'+encuentro.id">
                <div class="accordion-item border border-secondary" :style="Skin.fondo2">
                  <div class="accordion-header" :id="'heading-'+encuentro.id" >
                    <div class="row" :style="Skin.fondo2">
                      <div @click="set_main_event_prematch(encuentro.id)" class="col col-lg-8 col-sm-6 p-1" style="line-height:15px;cursor:pointer;">
                        <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ format_date(encuentro.time) }} </span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + liga.pais_code" style="max-width:15px;height:15px"></span>
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.liga.name }} </span> - 
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.pais['name_'+language] }} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[0].name}} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[1].name }} </span>
                      </div>
                      <div class="col col-lg-3 col-sm-5 p-0" style="min-width: 10.58%">
                        <table v-if="encuentro.markets.odds.results[0].main" style="width:95%" class="mx-auto my-2">
                          <tr v-if="'game_lines_3_way' in encuentro.markets.odds.results[0].main.sp">
                            <Odd
                              v-for="(logro) in balonmano(encuentro.markets.odds.results[0].main.sp.game_lines_3_way.odds)"
                              v-bind:key="logro.id+'content'"
                              v-bind:rate="logro.odds"
                              v-bind:Skin="Skin"
                              v-bind:logro="logro"
                              v-bind:active_state="selected_odds[logro.id]"
                              @click.native="odd_selected(encuentro.id,logro,'game_lines_3_way',encuentro.players[0].name,encuentro.players[1].name,encuentro.time,'main')"
                            >
                            </Odd>
                          </tr>
                        </table>
                      </div>
                      <div class="col col-1 px-0 py-2 text-center" style="max-width:6%">
                        <div class="text1 collapsed py-1 m-0" style="cursor:pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+encuentro.id" aria-expanded="false" :aria-controls="'collapse-'+encuentro.id">
                          <span :class="Skin.text1" :style="Skin.color1" style="font-size:large;color:#ffd029;"><i class="fas fa-plus-square"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <AllOdds
                  :encuentro="encuentro"
                  :language="language"
                  :Skin="Skin"
                  :liga="liga"
                  :translation="translation"
                  :collapsed="true"
                  >
                  </AllOdds>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="liga.deporte['id']==83" class="table-responsive">
          <div class="px-3 pe-3">
            <div
              v-for="(encuentro) in encuentros"
              v-bind:key="encuentro.id"
              >
              <div class="accordion row" :id="'match-'+encuentro.id">
                <div class="accordion-item border border-secondary" :style="Skin.fondo2">
                  <div class="accordion-header" :id="'heading-'+encuentro.id" >
                    <div class="row" :style="Skin.fondo2">
                      <div @click="set_main_event_prematch(encuentro.id)" class="col col-lg-8 col-sm-6 p-1" style="line-height:15px;cursor:pointer;">
                        <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ format_date(encuentro.time) }} </span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + liga.pais_code" style="max-width:15px;height:15px"></span>
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.liga.name }} </span> - 
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.pais['name_'+language] }} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[0].name}} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[1].name }} </span>
                      </div>
                      <div class="col col-lg-3 col-sm-5 p-0" style="min-width: 10.58%">
                        <table v-if="encuentro.markets.odds.results[0].main" style="width:95%" class="mx-auto my-2">
                          <tr v-if="'3_way' in encuentro.markets.odds.results[0].main.sp">
                            <Odd
                              v-for="(logro) in balonmano(encuentro.markets.odds.results[0].main.sp['3_way'].odds)"
                              v-bind:key="logro.id+'content'"
                              v-bind:rate="logro.odds"
                              v-bind:Skin="Skin"
                              v-bind:logro="logro"
                              v-bind:active_state="selected_odds[logro.id]"
                              @click.native="odd_selected(encuentro.id,logro,'3_way',encuentro.players[0].name,encuentro.players[1].name,encuentro.time,'main')"
                            >
                            </Odd>
                          </tr>
                        </table>
                      </div>
                      <div class="col col-1 px-0 py-2 text-center" style="max-width:8%">
                        <div class="text1 collapsed py-1 m-0" style="cursor:pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+encuentro.id" aria-expanded="false" :aria-controls="'collapse-'+encuentro.id">
                          <span :class="Skin.text1" :style="Skin.color1" style="font-size:large;color:#ffd029;"><i class="fas fa-plus-square"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <AllOdds
                  :encuentro="encuentro"
                  :language="language"
                  :Skin="Skin"
                  :liga="liga"
                  :translation="translation"
                  :collapsed="true"
                  >
                  </AllOdds>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="liga.deporte['id']==92 || liga.deporte['id']==94 || liga.deporte['id']==151" class="table-responsive">
          <div class="px-3 pe-3">
            <div
                v-for="(encuentro) in encuentros"
                v-bind:key="encuentro.id"
                >
                <div class="accordion row" :id="'match-'+encuentro.id">
                  <div class="accordion-item border border-secondary" :style="Skin.fondo2">
                    <div class="accordion-header" :id="'heading-'+encuentro.id" >
                      <div class="row" :style="Skin.fondo2">
                        <div @click="set_main_event_prematch(encuentro.id)" class="col col-lg-8 col-sm-6 p-1" style="line-height:15px;cursor:pointer;">
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ format_date(encuentro.time) }} </span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + liga.pais_code" style="max-width:15px;height:15px"></span>
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.liga.name }} </span> - 
                          <span :class="Skin.text1" style="font-size:small;color:#BDB6B7">{{ liga.pais['name_'+language] }} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[0].name}} </span><br>
                          <span :class="Skin.text1" style="font-size:small;font-weight:bolder;">{{ encuentro.players[1].name }} </span>
                        </div>
                        <div class="col col-lg-3 col-sm-5 p-0" style="min-width: 10.58%">
                          <table v-if="encuentro.markets.odds.results[0].main" style="width:95%" class="mx-auto my-2">
                            <tr v-if="'match_lines' in encuentro.markets.odds.results[0].main.sp">
                              <Odd
                                v-for="(logro) in futbol_americano_main(encuentro.markets.odds.results[0].main.sp.match_lines.odds)"
                                v-bind:key="logro.id+'content'"
                                v-bind:rate="logro.odds"
                                v-bind:Skin="Skin"
                                v-bind:logro="logro"
                                v-bind:active_state="selected_odds[logro.id]"
                                @click.native="odd_selected(encuentro.id,logro,'match_lines',encuentro.players[0].name,encuentro.players[1].name,encuentro.time,'main')"
                              >
                              </Odd>
                            </tr>
                          </table>
                        </div>
                        <div class="col col-1 px-0 py-2 text-center" style="max-width:8%">
                          <div class="text1 collapsed py-1 m-0" style="cursor:pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+encuentro.id" aria-expanded="false" :aria-controls="'collapse-'+encuentro.id">
                            <span :class="Skin.text1" :style="Skin.color1" style="font-size:large;color:#ffd029;"><i class="fas fa-plus-square"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <AllOdds
                    :encuentro="encuentro"
                    :language="language"
                    :Skin="Skin"
                    :liga="liga"
                    :translation="translation"
                    :collapsed="true"
                    >
                    </AllOdds>
                  </div>
                </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</template>
<script>
import Odd from './odd.vue';
import AllOdds from './all_odds.vue';
import Futbol from '../sport_tables/futbol.vue';
import Cricket from '../sport_tables/cricket.vue';
import Rugby from '../sport_tables/rugby.vue';
import Tenis from '../sport_tables/tenis.vue';
import Balonmano from '../sport_tables/balonmano.vue';
export default {
  name: "encuentro",
  components: {
    Odd,
    Futbol,
    Cricket,
    AllOdds,
    Rugby,
    Tenis,
    Balonmano
  },
  data() {
    return {
      meta_data: [],
      data_loaded: false,
      search_target:this.$props.search,
      winner_title:{
        3:"to_win_the_match",
        9:"to_win_fight",
        14:"to_win_match",
        15:"match_winner",
      },
      visible:true,
    };
  },
  props: ["liga", "Skin", "time", "language", "translation","search","sport"],
  computed: {
    encuentros: function () {
      if (!this.data_loaded) {
        return null;
      }
      return this.meta_data.length>0?this.meta_data[0].events:[];
    },
    selected_odds(){
        let odds = {};
        let data = this.$store.getters['getBets'];
        Object.keys(data).forEach(bet => {
          if(data[bet]){
            odds[bet] = true;
          }
        });
        return odds;
      }
  },
  methods: {
    delete_liga: function (liga) {
      let payload = {"type":1,"datos":{ liga: liga }}
      this.$store.dispatch('prematch/delete_liga', payload);
      this.$root.$emit("uncheck_liga", liga.id);
    },
    format_date: function (date) {
      var date_str = this.safe_date(date)+" UTC";
      date = new Date(date_str);
      var formated =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        date.getHours() +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2);
      return formated;
    },
    safe_date(date){ 
      var parsed_array_1 = date.split(" ");
      var parsed_array_2 = parsed_array_1[0].split("-");
      var date_str = parsed_array_2[1]+"/"+parsed_array_2[2]+"/"+parsed_array_2[0]+" "+parsed_array_1[1];
      return date_str;
    },
    translate(text) {
      if (this.$props.translation[text]) {
        return this.$props.translation[text][this.$props.language];
      }
      return text;
    },
    odd_header(header, name, handicap,  home, away, category) {
      var str = "";
      if (header) {
        if (category == "correct_score") {
          str += "";
        }
        else if(category=="match_result_and_total_games"){
          str+="";
        } 
        else {
          if (header == "1") {
            str += home;
          } else if (header == "2") {
            str += away;
          } else {
            str += this.translate(header) + " "+ handicap;
          }
        }
      }
      if (name) {
        if (category == "correct_score") {
          var name_formated = name.split("-");
          if (header == "2") {
            str += " " + name_formated[1] + "-" + name_formated[0];
          } else {
            str += " " + name;
          }
        } 
        else if(category=="match_result_and_total_games"){
          var player= name=="1"?home:away;
          str+=player+" / "+this.translate(header)+" "+handicap;
        } 
        else {
          if (name == "1") {
            str += home;
          } else if (name == "2") {
            str += away;
          } else {
            var name_splited = name.split(" ");
            if (name_splited.length > 1) {
              for (let i = 0; i < name_splited.length; i++) {
                str +=" "+ this.translate(name_splited[i]) + " ";
              }
              str.slice(0, -1);
              str+=" "+handicap;
            } else {
              str += " " + this.translate(name) +" "+handicap;
            }
          }
        }
      }
      return str;
    },
    odd_selected: function (
      event_id,
      logro,
      categoria,
      home,
      away,
      event_date,
      market
    ) {
      this.$root.$emit('bet_loading');
      if (this.selected_odds[logro.id]) {
        this.$store.dispatch('deleteBet', logro.id);
        return;
      }
      const currentTime = Date.now();
      const eTime = Date.parse(this.format_date(event_date))/1000;
      let timeZoneOffset = new Date().getTimezoneOffset() / 60;
      let timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
      var odd_data = {
          "provider":"bestapi",
          "game_id": event_id, //id del evento ok -
          "event_id": logro.id,//id de la apuesta ok -
          "price": logro.odds[logro.odds.length-4]=="."?logro.odds.slice(0, -1):logro.odds, //cuota ok -
          "equipoa": home, //ok -
          "equipob": away, //ok -
          "referencia":categoria, //referencia de la apuesta -
          "market": market, //nombre del mercado -
          "seleccion": this.odd_header(logro.header, logro.name, "handicap" in logro?logro.handicap:"","team" in logro?logro.team:"", home, away, categoria), //logro ok
          "seleccion_ref":logro, // ok -
          "start_ts": eTime, // fecha encuentro ok -
          "islive": false, //ok -
          "alias":this.liga.deporte.name_en, //ok -
          "timestamp": currentTime, // fecha apuesta ok -
          "blocked": false, //ok -
          "extra_data":{
            "apuesta": this.translate(categoria).toUpperCase(), //tipo apuesta ok -
            "pais": this.liga.pais.name_es, //ok -
            "liga": this.liga.liga.name,//ok -
            "deporte": this.liga.deporte.name_es,//ok -
            "format": "decimal",
            "current_state": "notstarted", //ok -
            "lang": this.$props.language == "ES" ? "SPA" : "ENG" ,//ok -
            "timezone": timezoneFormated,//ok
            "geo":{},
            "text_info":"",
            "detallesItem":""
          }
      };
      this.$store.dispatch('setBets', odd_data);
    },
    async getData() {
      try {
        this.data_loaded = false;
        var url = "";
        let currentdate = new Date();
        let date_to ='';
        let to_UTC = ''; 
        currentdate.setDate(currentdate.getDate() + 3);
        date_to = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                + ("0" + currentdate.getDate()).slice(-2) + "/"
                + currentdate.getFullYear() + " 23:59:00";
        to_UTC = new Date(date_to).toISOString();
        if(this.search_target != "NA"){
          if("encuentro" in this.$props.search){
            url = process.env.VUE_APP_SERVER_URL+"get_searched_event_details/league_id/" +
              this.$props.liga.liga.id +"/time/" + this.time.split(".")[0]+"."+to_UTC.replace('T',' ').split('.')[0]+"/event_id/"+this.$props.search.encuentro;
          }
          else{
            
            url = process.env.VUE_APP_SERVER_URL+"get_upcoming_events_details/league_id/" +
              this.$props.liga.liga.id + "/time/" + this.time.split(".")[0]+"."+to_UTC.replace('T',' ').split('.')[0];
          }
        }
        else{
          url = process.env.VUE_APP_SERVER_URL+"get_upcoming_events_details/league_id/" +
              this.$props.liga.liga.id + "/time/" + this.time;
        }
        const response = await this.$http.get(
          url
        );
        this.meta_data = response.data;
        this.data_loaded = true;
      } catch (error) {
        console.log(error);
      }
    },
    alernative_goals: function(encuentro){
      var goals = [];
      encuentro.forEach(element => {
        if(element.name == 1.5){
          goals.push(element);
        }
      });
      return goals;
    },
    rugby_main: function(encuentro){
      var main = [];
      encuentro.forEach(element => {
        if(element.name == "Result"){
          main.push(element);
        }
      });
      return main;
    },
    futbol_americano_main: function(encuentro){
      var main = [];
      encuentro.forEach(element => {
        if(element.name == "Money Line" || element.name == "To Win"){
          main.push(element);
        }
      });
      return main;
    },
    balonmano: function(encuentro){
      var main = [];
      encuentro.forEach(element => {
        if(element.name == "Result"){
          main.push(element);
        }
      });
      return main;
    },
    set_main_event_prematch(id){
        this.search_active = false;
        let liga = this.$props.liga
        var datos = {
            pais:{name_es:liga.pais.name_es,name_en:liga.pais.name_en},
            liga:{cc:liga.pais_code,id:liga.liga.id,name:liga.liga.name,sport_id:liga.deporte.id,events:[
            ]},
            deporte:{name_es:liga.deporte.name_es,name_en:liga.deporte.name_en,id:liga.deporte.id},
            pais_code:liga.pais_code
        };
        this.$store.dispatch('prematch/delete_all_ligas');
        this.$store.dispatch('setLobbyView','prematch_event');
        this.$root.$emit('set_prematch_event',({data:datos,id:id})); 
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on('hide_show_ligas', (id) => {
        if(this.$props.liga.liga.id == id){
          this.visible = !this.visible;
        }
    });
  }, 
  watch:{
    time:function(){
      this.getData();
    },
    sport:function(){
      this.getData(); 
    },
    liga:function(){
      this.getData(); 
    }
  }
};
</script>
