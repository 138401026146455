<template>
  <div :id="'acordionP'+ region.id + deporte.id" class="accordion py-1 mb-1" :style="skin.menu_live.country_fondo">
    <div class="accordion-item" :style="skin.menu_live.country_fondo +'border:none'">
      <div class="accordion-header">
        <div style="display:flex;width:100%" :style="skin.menu_live.country_fondo">
          <div style="width:10%" class="px-1 mx-1"><input @click="check_all()" class="form-check-input check_country_live" type="checkbox"/></div>
          <div style="width:90%">
            <button :style="skin.menu_live.country_fondo+skin.menu_live.country_text" class="accordion-button p-0 text1 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#p'+ region.id + deporte.id" :aria-expanded="(deporte.id=='1'?'true':'false')" :aria-controls="'p'+ region.id + deporte.id">
              <div style="display:flex;width:100%">
                <div style="width:10%" class="mx-1"><span class="rounded-circle" :class="'phoca-flag ' + country_flag" style="max-width:20px;height:20px"></span></div>
                <div style="width:90%"><span class="long_text">{{ region.name }}</span></div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div :id="'p'+ region.id + deporte.id" class="accordion-collapse collapse"  aria-labelledby="headingOne" :data-bs-parent="'#acordionP'+ region.id + deporte.id">
      <div class="accordion-body p-0" style="">
        <eventoLigaLive
              v-for="liga in ligas"
              v-bind:liga="liga"
              v-bind:pais="liga.country"
              v-bind:Skin="skin"
              v-bind:deporte="deporte"
              v-bind:key="liga.id"
              ref="liga"
              >
        </eventoLigaLive>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import eventoLigaLive from './Evento_liga_live.vue';
const flags = require('../../../../../assets/countries.json');
export default{
  name:"eventoPais",
  components:{eventoLigaLive},
  data(){
    return {
    }
  },
  props:['region','deporte','skin'],
  methods:{
    check_all(){
      this.$refs.liga.forEach(element => {
        element.check_liga();
      });
    }
  },
  computed:{
    country_flag() {
      let flag = []
      flags.countries.forEach(country => {
        if(country.name_es == this.$props.region.alias || country.name_en == this.$props.region.alias){
          flag.push(country.code.toLowerCase());
        }
      });
      return flag;
    },
    ligas(){
      let ligas = [];
      let competitions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideCompetitions; 
      let regions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideRegions;
      let region = this.$props.region;
      if(region.competition){
        Object.keys(region.competition).forEach(key2 => {
          if(region.competition[key2] && region.competition[key2].game){
            let check = false;
            Object.keys(region.competition[key2].game).forEach(key3 => {
              if(region.competition[key2].game[key3] && region.competition[key2].game[key3].info){
                check = true;
              }
            })
            if(check){
              region.competition[key2].country = region.name;
              region.competition[key2].country_id = region.id;
              let banned1_check = false;
              let banned2_check = false;
              competitions_banned.forEach(word => {
                if(region.competition[key2].name.includes(word)){
                  banned1_check = true;
                }
              });
              if(regions_banned.includes(region.alias)){
                  banned2_check = true; 
              }
              if(!banned1_check && !banned2_check){ligas.push(region.competition[key2]);}
            }
          }
        })
      }
      ligas.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)); 
      return ligas;
    }
  }
}
</script>
<style scoped>
  .long_text {
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
</style>
