<template>
  <div class="d-flex p-0" :style="skin.navigation_bar.frame">
        <div @click="start()" style="cursor:pointer;" :style="skin.navigation_bar.fondo" class="py-2 pb-2 px-3 pe-3">
            <span :style="skin.navigation_bar.arrow"><i class="fas fa-arrow-left"></i></span>
        </div>
        <div class="py-2 pb-2 px-3 pe-0">
            <span @click="start()" :style="skin.navigation_bar.text" style="cursor:pointer;">{{translate("home")}}</span>&nbsp;&nbsp; 
        </div>
        <div 
            v-for="(link,index) in navigation"
            v-bind:key="index"
            class="py-2 pb-2 px-0 pe-1"
        >
            <span :style="skin.navigation_bar.text"><i class="fas fa-caret-right"></i></span>&nbsp;&nbsp; 
            <span :style="skin.navigation_bar.text">{{ link.name }}</span>
        </div>
  </div>
</template>

<script>
export default {
    name: "navigation_bar",
    props:["skin","translation","language","navigation"],
    data(){
        return {

        }
    },
    methods:{
        start(){
            this.$store.dispatch('setLobbyView','main');
        },
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
    }
}
</script>

<style>

</style>