<template>
  <div>
    <div class="p-2" :style="skin.my_account.menu_item_border">
      <h5>{{translate("register_customer")}}</h5>
    </div>
    <div v-if="register.result=='Ok'" class="alert alert-success fade show" role="alert">
      {{ register.msg }}
    </div>
    <div v-if="register.result=='error'" class="alert alert-danger fade show" role="alert">
      {{ register.msg }}
    </div>
    <form @submit.prevent="registerClient()" class="row p-2">
        <div class="col-12 col-lg-6">
          <div class="py-2">
            <label for="documentNumber">{{translate("Identification_number")}}</label>
            <input v-model="documentNumber" type="text" class="form-control" id="documentNumber" required>
          </div>
          <div class="py-2">
            <label for="firstName">{{translate("first_name")}}</label>
            <input v-model="firstName" type="text" class="form-control" id="firstName" required>
          </div>
          <div class="py-2">
            <label for="lastName">{{translate("surname")}}</label>
            <input v-model="lastName" type="text" class="form-control" id="lastName" required>
          </div>
          <div class="py-2" v-if="skin.register_fields.address">
            <label for="address">{{translate("address")}}</label>
            <input v-model="address" type="text" class="form-control" id="address" required>
          </div>
          <div class="py-2">
            <label for="birthDay">{{translate("birthday")}}</label>
            <input v-model="birthDay" type="text" class="form-control" id="birthDay" placeholder="YYYY-MM-DD" required>
          </div>
          <div class="py-2">
            <label for="email">{{translate("email")}}</label>
            <input v-model="email" type="text" class="form-control" id="email" required>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="py-2">
            <label for="phoneNumber">{{translate("telephone")}}</label>
            <input v-model="phoneNumber" type="text" class="form-control" id="phoneNumber" required>
          </div>
          <div class="py-2">
            <label for="userPassword">{{translate("password")}}</label>
            <input v-model="userPassword" type="password" class="form-control" id="userPassword" required>
          </div>
          <div class="py-2">
            <label for="passwordConfirmed">{{translate("repeat_password")}}</label>
            <input v-model="passwordConfirmed" type="password" class="form-control" id="passwordConfirmed" required>
          </div>
          <div class="py-2">
            <label for="firstCharge">{{translate("initial_recharge")}}</label>
            <input v-model="firstCharge" type="number" step="any" class="form-control" id="firstCharge" placeholder="0" required>
          </div>
          <div class="py-4">
            <button type="submit" :style="skin.qbet.booking_btn" class="p-2 text-center mb-2" style="width:100%;color:white" :disabled="register_inProcess">{{translate("register")}}</button>
          </div>
        </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name:"register_client",
    props:["skin","language","translation"],
    data(){
      return{
        register_inProcess:false,
        documentNumber:"",
        firstName:"",
        address:"",
        lastName:"",
        birthDay:"",
        email:"",
        phoneNumber:"",
        userPassword:"",
        passwordConfirmed:"",
        firstCharge:0,
        register:{result:"",msg:""}
      }
    },
    methods:{
      async registerClient(){
        this.register_inProcess = true
        let data = {
          "command":"registrar",
          "params":{
            "nombres":this.firstName,
            "apellidos":this.lastName,
            "password":this.userPassword,
            "direccion":this.address,
            "passwordagain":this.passwordConfirmed,
            "email":this.email,
            "recarga":this.firstCharge,
            "cedula":this.documentNumber,
            "telefono":this.phoneNumber,
            "nacimiento":this.birthDay
          }
        }
        const response = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data);
        if(response.data.result == 'Ok'){
          this.register.result="Ok";
          this.register.msg=response.data.msg;
          this.firstName="";
          this.lastName="";
          this.userPassword="";
          this.passwordConfirmed="";
          this.email="";
          this.firstCharge="";
          this.documentNumber="";
          this.phoneNumber="";
          this.birthDay="";
        }
        else if(response.data.result == 'error'){
          this.register.result="error";
          this.register.msg=response.data.msg;
        }
        this.register_inProcess = false;
      },
      translate(text) {
        if(text){
          if (this.$props.translation[text]) {
            return this.$props.translation[text][this.$props.language];
          }
          else{
            var new_text_serched = text.replace(/ /g, "_");
            if (this.$props.translation[new_text_serched]) {
              return this.$props.translation[new_text_serched][this.$props.language];
            }
          }
          return text;
        }
      },
    },
    computed:{
      user(){
        return this.$store.getters['getUserData'];
      },
    }
}
</script>

<style>

</style>