<template>
  <div :id="'acordionP'+ pais.id + deporte.name_es.replace(/\s/g, '')" class="accordion p-1 mb-1" :style="skin.fondo2">
    <div class="accordion-item" :style="skin.fondo3 +'border:none'">
      <div class="accordion-header">
        <div style="display:flex;width:100%" :style="skin.fondo2">
          <div style="width:10%" class="px-1 mx-1"><input @click="check_all()" class="form-check-input check_country_prematch" type="checkbox"/></div>
          <div style="width:90%">
            <button :style="skin.fondo2+skin.text1" class="accordion-button p-0 text1 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#p'+ pais.id + deporte.name_es.replace(/\s/g, '')" aria-expanded="false" :aria-controls="'p'+ pais.id + deporte.name_es.replace(/\s/g, '')">
              <div style="display:flex;width:100%">
                <div style="width:10%" class="mx-1"><span class="rounded-circle" :class="'phoca-flag ' + pais.code" style="height:20px;width:20px"></span></div>
                <div style="width:80%"><span class="long_text">{{ pais.name_es }}</span></div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div :id="'p'+ pais.id + deporte.name_es.replace(/\s/g, '')" class="accordion-collapse collapse" aria-labelledby="headingOne" :data-bs-parent="'#acordionP'+ pais.id + deporte.name_es.replace(/\s/g, '')">
      <div class="accordion-body p-0" style="">
        <eventoLiga
              v-for="liga in pais.leagues"
              v-bind:liga="liga"
              v-bind:pais="pais"
              v-bind:Skin="skin"
              v-bind:deporte="deporte"
              v-bind:key="liga.id"
              ref="liga"
              >
        </eventoLiga>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import eventoLiga from './Evento_liga_prematch.vue'
export default{
  name:"eventoPais",
  components:{eventoLiga},
  data(){
    return {
    }
  },
  props:['pais','deporte','skin'],
  methods:{
    check_all(){
      this.$refs.liga.forEach(element => {
        element.check_liga();
      });
    }
  }
}
</script>
<style scoped>
  .long_text {
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
</style>
