<template>
<div class="m-1 mb-2" :style="skin.search.body" style="overflow:hidden">
    <div class="p-2" :style="skin.search.header">{{translate("search_by")}}</div>
    <div class="p-2">
        <input v-model="text" type="text" class="form-control" :placeholder="translate('team_competition')" aria-label="Buscar">
    </div>
    <div v-if="search_results_betconstruct != 'empty'">
        <div v-for="(sport,key) in search_results_betconstruct" :key="key" class="px-1">
            <div v-for="event in sport" :key="event.id" class="px-2" style="cursor:pointer" :style="skin.search.text">
                <div @click="select_event(event);changeToLive()" v-if="event.type=='live' && (skin.live!=null?skin.live:true)">
                    <span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag(event.country)" style="max-width:20px;height:20px"></span>
                    <span style="font-size:small" :style="skin.search.text">{{ key }} - {{ event.competition }} </span>
                    <span class="badge bg-danger me-2">{{translate("live")}}</span>
                    <br>
                    <span style="font-size:small" :class="skin.search.text">{{ event.team1_name+' -VS- '+ event.team2_name }} </span>
                    <hr class="my-1 border border-1 border-secondary"/>
                </div>
                <div @click="select_event(event)" v-if="event.type!='live'&&(skin.prematch!=null?skin.prematch:true)">
                    <span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag(event.country)" style="max-width:20px;height:20px"></span>
                    <span style="font-size:small" :style="skin.search.text">{{ key }} - {{ event.competition }}</span><br>
                    <span style="font-size:small">{{ event.date }}</span>
                    <br>
                    <span style="font-size:small" :class="skin.search.text">{{ event.team1_name+' -VS- '+ event.team2_name }} </span>
                    <hr class="my-1 border border-1 border-secondary"/>
                </div>
            </div>   
        </div>
    </div>
    <div v-else-if="text.length>2" class="text-center py-5">
        <div v-if="loading" class="spinner-border" :style="skin.loader" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else class="text-center"><span :style="skin.search.text">{{translate("no_search_results")}}</span></div>
    </div>
    <div v-if="prematchTarget==1">
        <div v-for="(result,key) in search_results_prematch"
        v-bind:key="key"
        class="p-2"
        >
            <div v-if="result.type=='event'">
                <div v-for="(event,index) in result.data"
                v-bind:key="index"
                @click="add_evento(event)"
                style="cursor:pointer"
                >
                    <span class="rounded-circle me-1" :class="'phoca-flag ' + event.code" style="width:20px;height:20px"></span>
                    <span style="font-size:small" :style="skin.search.text">{{ event['name_'+language] }} - {{ event.name }}</span><br>
                    <span style="font-size:small" :style="skin.search.text">{{ format_date(event.time) }} </span><br>
                    <span style="font-size:small" :style="skin.search.text">{{ event.players[0].name+' -VS- '+ event.players[1].name }} </span>
                    <hr class="my-1 border border-1 border-white"/>
                </div>
            </div>
            <div v-if="result.type=='league'">
                <div v-for="(league,index) in result.data"
                v-bind:key="index"
                @click="add_liga(league)"
                style="cursor:pointer"
                >
                    <span class="rounded-circle me-1" :class="'phoca-flag ' + league.code" style="width:20px;height:20px"></span>
                    <span style="font-size:small" :style="skin.search.text">{{ league['name_'+language] }} - {{ league.name }}</span><br>
                    <span style="font-size:small" :style="skin.search.text">{{ league.country_en }}</span>
                    <hr class="my-1 border border-1 border-white"/>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
const flags = require('../../../../assets/countries.json');
import router from '../../../../router/index';
export default {
    name: "main_search",
    components: {
        
    },
    props: ["skin", "language", "translation"],
    data() {
        return {
            text:"",
            screen_width:0,
            loading: false,
        }
    },
    methods:{
        changeToLive(){
            if(document.getElementById('closeSideBar')){
                document.getElementById('closeSideBar').click();
            }
            if(this.user.token==''){
                router.push({ name: 'LiveMainEcuabets', query: { token: this.user.token+this.user.site_id} })
            }
            else{
                router.push({ name: 'Live_ecuabets', query: { token: this.user.token+this.user.site_id} })
            }
        },
        translate(text){
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
        format_date: function (date) {
            var date_str = this.safe_date(date)+" UTC";
            date = new Date(date_str);
            var formated =
                date.getFullYear() +
                "-" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + date.getDate()).slice(-2) +
                " " +
                date.getHours() +
                ":" +
                ("0" + date.getMinutes()).slice(-2) +
                ":" +
                ("0" + date.getSeconds()).slice(-2);
            return formated;
        },
        safe_date(date){
            var parsed_array_1 = date.split(" ");
            var parsed_array_2 = parsed_array_1[0].split("-");
            var date_str = parsed_array_2[1]+"/"+parsed_array_2[2]+"/"+parsed_array_2[0]+" "+parsed_array_1[1];
            return date_str;
        },
        select_event(event){
            if(event.type=='live'){
                if(this.$store.getters['live/getTargetEventId'] !=  event.id){
                    if(Object.keys(this.$store.getters['live/getLiveEvent']).length > 0){
                        this.$store.dispatch('live/unsuscribe_event');
                    }
                    this.$store.dispatch('live/suscribe_event', event.id);
                }
            }
            else{
                if(router.currentRoute.name!="MainEcuabets"&&router.currentRoute.name!="Main_ecuabets"){
                    if(document.getElementById('closeSideBar')){
                        document.getElementById('closeSideBar').click();
                    }
                    if(this.user.token==''){
                        router.push({ name: 'MainEcuabets', query: { token: this.user.token+this.user.site_id} })
                    }
                    else{
                        router.push({ name: 'Main_ecuabets', query: { token: this.user.token+this.user.site_id} })
                    }
                }
                setTimeout(() => {
                    this.$store.dispatch('prematch_2/unsuscribe_all_events');
                    this.$store.dispatch('prematch_2/suscribe_event', event.id);
                    this.$store.dispatch('setLobbyView','prematch2_evento');
                    this.$root.$emit('show_prematch2_event',event);
                }, 100);
            }
        },
        add_evento(data){
            this.search_active = false;
            var datos = {
                pais:{name_es:data.country_es,name_en:data.country_en},
                liga:{cc:data.cc,id:data.league_id,name:data.name,sport_id:data.sport_id,events:[
                    {
                        id:data.id,league_id:data.league_id,time:data.time
                    }
                ]},
                deporte:{name_es:data.name_es,name_en:data.name_en,id:data.sport_id},
                pais_code:data.code
            };
            this.$store.dispatch('prematch/delete_all_ligas');
            this.$store.dispatch('setLobbyView','prematch_event');
            this.$root.$emit('set_prematch_event',({data:datos,id:data.id}));
        },
        add_liga(data){
            this.$root.$emit('show_prematch_ligas');
            this.search_active = false;
            var datos = {
                pais:{name_es:data.country_es,name_en:data.country_en},
                liga:{cc:data.cc,id:data.id,name:data.name,sport_id:data.sport_id,events:[
                    {
                        id:data.id
                    }
                ]},
                deporte:{name_es:data.name_es,name_en:data.name_en,id:data.sport_id},
                pais_code:data.code
            };
            let payload = {"type":1,"datos":datos}
            this.$store.dispatch('prematch/add_liga', payload);
        },
        country_flag(pais) {
            let flag = []
            flags.countries.forEach(country => {
                if(country.name_es == pais){
                flag.push(country.code.toLowerCase());
                }
            });
            return flag;
        },
        sizeControl(){
            this.screen_width=window.innerWidth;
        },
    },
    computed:{
        user(){
            return {
                token:this.$root.user_token,
                site_id:this.$root.site_id,
            }
        },
        prematchTarget(){
            return this.$store.getters['getPrematchTarget'];
        },
        search_results_betconstruct(){
            let results = {};
            let prematch = this.$store.getters['getPrematchTarget'] == 0 ? true : false;
            let SportsBanned = this.$store.getters['getConfig'].SkinConfig.main.hideSports;
            let competitions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideCompetitions; 
            let regions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideRegions;
            let SportsBannedPrematch = this.$store.getters['getConfig'].SkinConfig.main.hideSportsPrematch;
            let competitions_bannedPrematch = this.$store.getters['getConfig'].SkinConfig.main.hideCompetitionsPrematch; 
            let regions_bannedPrematch = this.$store.getters['getConfig'].SkinConfig.main.hideRegionsPrematch;
            let data = [this.$store.getters['live/getSearchTeam'],this.$store.getters['live/getSearchCompetition']];
            data.forEach(search_info => {
                if(Object.keys(search_info).length > 0){
                let target = search_info.data.data.sport;
                Object.keys(target).forEach(sport => {
                    results[target[sport].name] = [];
                    Object.keys(target[sport].region).forEach(key => {
                        if(target[sport].region[key] && target[sport].region[key].competition){
                        Object.keys(target[sport].region[key].competition).forEach(key2 => {
                            if(target[sport].region[key].competition[key2] && target[sport].region[key].competition[key2].game){
                                Object.keys(target[sport].region[key].competition[key2].game).forEach(key3 => {
                                    if(target[sport].region[key].competition[key2].game[key3]){
                                        target[sport].region[key].competition[key2].game[key3]['competition'] = target[sport].region[key].competition[key2].name;
                                        let date = new Date(parseInt(target[sport].region[key].competition[key2].game[key3].start_ts)*1000);
                                        let date_str = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())+
                                            "/"+((date.getMonth()+1 < 10 ? "0" + (date.getMonth()+1) : date.getMonth()+1))+
                                            "/"+date.getFullYear()+
                                            " "+(date.getHours() < 10 ? "0" + date.getHours() : date.getHours())+
                                            ":"+(date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())+
                                            ":"+(date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());
                                        target[sport].region[key].competition[key2].game[key3]['date'] = date_str;
                                        if(prematch){
                                            if(date < Date.now() + (30 * 60000)){
                                                target[sport].region[key].competition[key2].game[key3].country = target[sport].region[key].name;
                                                target[sport].region[key].competition[key2].game[key3].sport = target[sport].id;
                                                target[sport].region[key].competition[key2].game[key3].sport_alias = target[sport].alias;
                                                target[sport].region[key].competition[key2].game[key3].country_id = target[sport].region[key].id;
                                                target[sport].region[key].competition[key2].game[key3].liga_id = target[sport].region[key].competition[key2].id;
                                                target[sport].region[key].competition[key2].game[key3].liga = target[sport].region[key].competition[key2].name;
                                                target[sport].region[key].competition[key2].game[key3].type = "live";
                                                //bloqueos
                                                let banned = false;
                                                if(SportsBanned.includes(target[sport].alias) || regions_banned.includes(target[sport].region[key].alias)){
                                                    banned = true;
                                                }
                                                competitions_banned.forEach(word => {
                                                    if(target[sport].region[key].competition[key2].name.includes(word)){
                                                    banned = true;
                                                    }
                                                });
                                                if(!banned){
                                                    results[target[sport].name].push(target[sport].region[key].competition[key2].game[key3]);
                                                } 
                                            }
                                            else{
                                                target[sport].region[key].competition[key2].game[key3].country = target[sport].region[key].name;
                                                target[sport].region[key].competition[key2].game[key3].sport = target[sport].id;
                                                target[sport].region[key].competition[key2].game[key3].sport_alias = target[sport].alias;
                                                target[sport].region[key].competition[key2].game[key3].country_id = target[sport].region[key].id;
                                                target[sport].region[key].competition[key2].game[key3].liga_id = target[sport].region[key].competition[key2].id;
                                                target[sport].region[key].competition[key2].game[key3].liga = target[sport].region[key].competition[key2].name;
                                                target[sport].region[key].competition[key2].game[key3].type = "prematch";
                                                //bloqueos
                                                let banned = false;
                                                if(SportsBannedPrematch.includes(target[sport].alias) || regions_bannedPrematch.includes(target[sport].region[key].alias)){
                                                    banned = true;
                                                }
                                                competitions_bannedPrematch.forEach(word => {
                                                    if(target[sport].region[key].competition[key2].name.includes(word)){
                                                    banned = true;
                                                    }
                                                });
                                                if(!banned){
                                                    results[target[sport].name].push(target[sport].region[key].competition[key2].game[key3]);
                                                } 
                                            }
                                            
                                        }
                                        else{
                                            if(date < Date.now() + (30 * 60000)){
                                                target[sport].region[key].competition[key2].game[key3].country = target[sport].region[key].name;
                                                target[sport].region[key].competition[key2].game[key3].type = "live";
                                                results[target[sport].name].push(target[sport].region[key].competition[key2].game[key3]);
                                            }
                                        }
                                        
                                    }
                                })
                            }
                        })
                        }
                    }) 

                });
                }
            });
            return Object.keys(results).length>0 ? results : "empty";
        },
        search_results_prematch(){
            let data = this.$store.getters['prematch/getSearchResult'];
            if(this.text==""){
                return {};
            }
            return data;
        }
    },
    watch:{
        text(newText){
            if(newText.length > 2){
                this.loading = true;
                this.$store.dispatch('live/search_team', newText);
                this.$store.dispatch('live/search_competition', newText);
                let self = this;
                //this.$store.dispatch('prematch/search', {text:newText,time:this.$store.getters['prematch/getTime']});
                setTimeout(function(){self.loading = false;},3000)
            }
            if(newText==""){
                this.loading = false;
                this.$store.dispatch('live/clearSearch');
            }
        }
    },
    created() {
        this.sizeControl();
    },
    mounted(){
        window.addEventListener('resize', ()=>{this.sizeControl();});
    },
}
</script>

<style>

</style>