import Vue from "vue";
import Vuex from "vuex";
import _get from "lodash.get";
import live from "../modules/live_module";
import prematch from "../modules/prematch_module";
import prematch_2 from "../modules/prematch_module_betconstruct";
import axios from "axios";
import router from "../router/index";
const config = require("../assets/conf-example.json");
// const skin_data = require("../../skin.json");
import swal from "sweetalert";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currency:{fiat:'',rate:1},
    language:"",
    currentSkin:0,
    skins:[{}],
    bet_type:"combinada",
    rate_type:"decimal",
    hostName: '',
    skin_charged:false,
    translation: {},
    bets: {},
    jbets_modified_odds: [],
    can_bet: true,
    user_token: "",
    site_id: "",
    site: "",
    domain: "",
    prefix: "api",
    user_data: {},
    view: "main",
    websocket: "",
    jbets_socket: "",
    connectionTimeOut: 250,
    config: config,
    prematch_target: 0,
    lobby_view: "main",
    external_events: {},
    loadedExternalData: false,
    renderSports: false,
    headerHeight: 0,
    odds: [],
    pending_odds: [],
    odds_loaded: false,
    games:[],
    games_loaded: false,
    gameIds : []
  },
  plugins: [
    createPersistedState({
      paths: ["bets", "skins','language"],
    }),
  ],
  mutations: {
    createGameIds(state, gameIds) {
      state.gameIds = gameIds;
    },
    setHeaderHeight(state, payload) {
      state.headerHeight = payload;
    },
    changeCurrencyFiat(state, payload) {
      state.currency.fiat = payload.fiat;
      state.currency.rate = payload.rate;
    },
    setBetType(state, payload) {
      state.bet_type = payload;
    },
    setHostName(state, payload) {
      state.hostName = payload;
    },
    setRateType(state, payload) {
      state.rate_type = payload;
    },
    setCurrentSkin(state, payload) {
      state.currentSkin = payload;
    },
    setLanguage(state, payload) {
      state.language = payload;
      state.renderSports = false;
      let rerender = setTimeout(function () {
        state.renderSports = true;
        clearTimeout(rerender);
      }, 300);
      state.websocket.close();
    },
    setBets(state, payload) {
      Vue.set(state.bets, payload.event_id, payload);
    },
    blockBet(state, payload) {
      state.bets[payload].blocked = true;
    },
    unBlockBet(state, payload) {
      state.bets[payload].blocked = false;
    },
    deleteBet(state, payload) {
      state.bets[payload] = null;
      let check = true;
      Object.keys(state.bets).forEach((bet) => {
        if (state.bets[bet] != null) {
          check = false;
        } else {
          Vue.delete(state.bets, bet);
        }
      });
      if (check) {
        state.bets = {};
      }
    },
    setBetGame(state, payload) {
      if (typeof payload === "object") {
        let game_id = Object.keys(payload.game)[0];
        let market_id = Object.keys(payload.game[game_id].market)[0];
        let event_id = Object.keys(
          payload.game[game_id].market[market_id].event
        )[0];
        try {
          Vue.set(state.bets[event_id], "game", payload);
        } catch (error) {
          state.bets[event_id].game = "Not available"
          console.log("error",game_id,market_id,event_id,payload);
        }
      } else {
        state.bets[payload].game = "Not available";
      }
    },
    deleteBets(state) {
      state.bets = {};
    },
    detach(state, payload) {
      state.skins = payload;
    },
    setCanBet(state) {
      state.can_bet = !state.can_bet;
    },
    setUserData(state, payload) {
      state.user_data = payload;
    },
    setToken(state, payload) {
      state.user_token = payload.token;
      state.site_id = payload.site_id;
      state.site = payload.site;
      state.prefix = payload.prefix;
    },
    setTranslations(state, payload) {
      state.translation = payload;
      state.renderSports = true;
    },
    setDomain(state, payload) {
      state.domain = payload;
    },
    setView(state, payload) {
      state.view = payload;
    },
    setMainView(state, payload) {
      state.skins = payload;
      state.rate_type = router.currentRoute.query.rate_type || payload[0].qBet_config.default_rate_type;
      state.language = router.currentRoute.query.language || payload[0].default_language;
      state.bet_type = payload[0].qBet_config.default_bet_type;
      state.skin_charged = true;
      if (payload[0].front_lobby.enabled) {
        state.view = "lobby";
      } else {
        state.view = "main";
      }
    },
    setExternalEvents(state, payload) {
      state.external_events = payload;
      state.loadedExternalData = true;
    },
    socket_connection(state) {
      let self = this;
      let socketUrl = state.config.swarm.websocket[0].socket.url;
      state.websocket = new WebSocket(socketUrl);
      state.websocket.onerror = function (err) {
        console.error("Socket error: ", err.message, "Closing socket");
        state.websocket.close();
      };
      state.websocket.onopen = function () {
        self.commit("request_session");
        self.commit("live/request_competitions");
        let timer = {
          "1hh": {
            "@now": {
              "@gte": 0,
              "@lt": 3600,
            },
          },
          "3hh": {
            "@now": {
              "@gte": 0,
              "@lt": 10800,
            },
          },
          hoy: {
            "@now": {
              "@gte": 0,
              "@lt": 57600,
            },
          },
          hoy1: {
            "@now": {
              "@gte": 57600,
              "@lt": 144000,
            },
          },
          todos: {},
        };
        self.commit("prematch_2/request_competitions", timer["todos"]);
        if (state.live.target_event_id != "") {
          try {
            self.commit("live/suscribe_event", state.live.target_event_id);
          } catch (error) {
            console.log("Error al reconectar con el evento Objetivo");
          }
        }
        Object.keys(self.getters.getBets).forEach((bet) => {
          if (self.getters.getBets[bet]) {
            if (self.getters.getBets[bet].islive) {
              try {
                let payload = {
                  game_id: self.getters.getBets[bet].game_id,
                  event_id: parseInt(bet),
                };
                self.commit("live/suscribe_odd", payload);
              } catch (error) {
                console.log(error);
              }
            }
          }
        });
        state.connectionTimeOut = 250;
      };
      state.websocket.onclose = function () {
        setTimeout(function () {
          console.log("Reconectando WebSocket");
          self.commit("socket_connection");
        }, Math.min(
          10000,
          (state.connectionTimeOut += state.connectionTimeOut)
        ));
      };
      state.websocket.onmessage = function (event) {
        // console.log(event.data)
        let response = JSON.parse(event.data);
        if (response.rid == state.live.competitions_rid) {
          state.live.competitions_subid = response.data.subid;
          self.commit("live/setLiveData", response);
          self.commit("live/loadedLiveData", true);
        }
        if (response.rid == state.prematch_2.competitions_rid) {
          state.prematch_2.competitions_subid = response.data.subid;
          self.commit("prematch_2/setPrematchData", response);
          self.commit("prematch_2/loadedPrematchData", true);
          state.prematch_2.time_loading = false;
        }
        if (response.rid == state.live.live_event_rid) {
          state.live.live_event_subid = response.data.subid;
          self.commit("live/setLiveEvent", response);
          state.live.loadedEventData = true;
        }
        if (response.rid in state.prematch_2.prematch_event_rids) {
          state.prematch_2.prematch_event_subids[
            state.prematch_2.prematch_event_rids[response.rid]
          ] = response.data.subid;
          self.commit("prematch_2/setPrematchEvent", {
            data: response,
            game_id: state.prematch_2.prematch_event_rids[response.rid],
          });
          state.prematch_2.loadedEventData = true;
        }
        if (response.rid == state.live.search_team_rid) {
          //revisar
          state.live.searchTeamResult = response;
        }
        if (response.rid == state.live.search_competition_rid) {
          //revisar
          state.live.searchCompetitionResult = response;
        }
        state.live.live_bets_rids.forEach((item) => {
          let rid = item.rid;
          if (response.rid == rid && response.data.data.game) {
            let game_id = Object.keys(response.data.data.game)[0];
            if (response.data.data.game[game_id]) {
              let market_id = Object.keys(
                response.data.data.game[game_id].market
              )[0];
              let event_id = Object.keys(
                response.data.data.game[game_id].market[market_id].event
              )[0];
              state.live.live_bets_subids.push({
                subid: response.data.subid,
                game_id: game_id,
                event_id: event_id,
              });
              self.commit("setBetGame", response.data.data);
            } else {
              self.commit("setBetGame", item.event_id);
            }
          }
        });
        if (state.live.loadedLiveData && response.data) {
          if (state.live.competitions_subid in response.data) {
            self.commit("live/updateLiveData", response);
          }
          if (state.prematch_2.competitions_subid in response.data) {
            self.commit("prematch_2/updatePrematchData", response);
          }
          if (state.live.live_event_subid in response.data) {
            let sport_idx = Object.keys(
              response.data[state.live.live_event_subid].sport
            )[0];
            if (
              response.data[state.live.live_event_subid].sport[sport_idx] ==
              null
            ) {
              state.live.liveEvent = {};
            } else {
              self.commit("live/updateEventData", response);
            }
          }
          Object.keys(state.prematch_2.prematch_event_subids).forEach(
            (game_id) => {
              let subid = state.prematch_2.prematch_event_subids[game_id];
              if (subid in response.data) {
                let sport_idx = Object.keys(response.data[subid].sport)[0];
                if (response.data[subid].sport[sport_idx] != null) {
                  let payload = { data: response, game_id: game_id };
                  self.commit("prematch_2/updateEventData", payload);
                }
              }
            }
          );
          state.live.live_bets_subids.forEach((item) => {
            if (item.subid in response.data) {
              self.commit("live/updateBetsData", {
                subid: item.subid,
                data: response.data[item.subid],
              });
            }
          });
        }
      };
    },
    request_session(state) {
      let rid =
        state.config.swarm.websocket[0].socket.rid_prefix +
        Math.floor(100000000000 + Math.random() * 900000000000).toString();
      let site_id = state.config.main.site_id;
      let language = state.skins[0].languages[state.language].code;
      let params = state.config.swarm.websocket[0].socket.params;
      params.site_id = parseInt(site_id);
      params.language = language;
      state.websocket.send(
        JSON.stringify({ command: "request_session", params: params, rid: rid })
      );
    },
    set_config(state, payload) {
      state.config = payload;
    },
    setLobbyView(state, payload) {
      state.lobby_view = payload;
      this.commit("prematch/load_prematch_events", 1);
    },
    setPrematchProvider(state, payload) {
      if (payload != state.prematch_target) {
        state.prematch_target = payload;
      }
    },
    setBetsFromBooking(state, payload) {
      if (payload.odds) {
        state.bets = payload.odds;
        Object.keys(payload.odds).forEach((bet) => {
          if (payload.odds[bet]) {
            this.commit("live/suscribe_odd", payload.odds[bet]);
          }
        });
      } else {
        state.bets = payload;
        Object.keys(payload).forEach((bet) => {
          if (payload[bet]) {
            this.commit("live/suscribe_odd", payload[bet]);
          }
        });
      }
    },
    logOut(state) {
      (state.user_data = {}), (state.user_token = "");
      state.prefix = "api";
    },
    renderSports(state, payload) {
      state.renderSports = payload;
    },
    jbets_socket_connection(state, payload) {
      let self = this;
      let socketUrl = process.env.VUE_APP_SOCKET_URL;
      state.jbets_socket = new WebSocket(socketUrl);
      state.jbets_socket.onerror = function (err) {
        console.error("Jbets Socket error: ", err.message, "Closing socket");
        state.jbets_socket.close();
      };
      state.jbets_socket.onopen = function () {
        state.jbets_socket.send(
          JSON.stringify({
            message_type: "new_session",
            type: "user",
            site_id: payload,
          })
        );
        state.connectionTimeOut = 250;
      };
      state.jbets_socket.onclose = function () {
        setTimeout(function () {
          console.log("Reconectando Jbets WebSocket");
          self.commit("jbets_socket_connection", payload);
        }, Math.min(
          10000,
          (state.connectionTimeOut += state.connectionTimeOut)
        ));
      };
      state.jbets_socket.onmessage = function (event) {
        let data = JSON.parse(event.data);
        if (data.message == "updated_odds") {
          state.jbets_modified_odds = data.data;
        }
        if (data.message == "new_session") {
          state.jbets_modified_odds = data.data.odds;
        }
      };
    },
    close_socket(state) {
      state.websocket.close();
      state.jbets_socket.close();
    },
    setOdds(state, payload) {
      state.odds = payload;
      state.odds_loaded = true;
    },
    clearOdds(state) {
      state.odds_loaded = false;
      state.odds = [];
    },
    setGames(state, payload){
      state.games = payload;
      state.games_loaded = true;
    }
  },
  actions: {
    async setHeaderHeight(state, payload) {
      state.commit("setHeaderHeight", payload);
    },
    async changeCurrencyFiat(state, payload) {
      await state.commit("changeCurrencyFiat", payload);
    },
    async closeSocket(state) {
      await state.commit("close_socket");
    },
    async setBetType(state, payload) {
      await state.commit("setBetType", payload);
    },
    async setRateType(state, payload) {
      await state.commit("setRateType", payload);
    },
    async getRid() {
      let rid =
        "16" +
        Math.floor(100000000000 + Math.random() * 900000000000).toString();
      return rid;
    },
    async setView(state, payload) {
      await state.commit("setView", payload);
    },
    async setMainView(state) {
      await state.commit("setMainView");
    },
    async setCurrentSkin(state, payload) {
      await state.commit("setCurrentSkin", payload);
    },
    async setLanguage(state, payload) {
      await state.commit("setLanguage", payload);
    },
    async setBets(state, payload) {
      await state.commit("setBets", payload);
    },
    async setBetGame(state, payload) {
      await state.commit("setBetGame", payload);
    },
    async detach_data(state, payload) {
      await state.commit("detach", payload);
    },
    async deleteBet(state, payload) {
      await state.commit("deleteBet", payload);
    },
    async deleteBets(state) {
      await state.commit("deleteBets");
    },
    async setCanBet(state) {
      await state.commit("setCanBet");
    },
    async blockBet(state, payload) {
      await state.commit("blockBet", payload);
    },
    async unBlockBet(state, payload) {
      await state.commit("unBlockBet", payload);
    },
    async setToken(state) {
      let site = "";
      let site_id = "";
      let token = "";
      let prefix = "api";
      let formated = router.currentRoute.query.target;
      if (formated.includes("-")) {
        if (formated.split("-")[2] == "integration") {
          site = formated.split("-")[0];
          site_id = formated.split("-")[1];
          prefix = "external";
          if (router.currentRoute.query.token) {
            if (router.currentRoute.query.token != "") {
              token = router.currentRoute.query.token;
            }
          }
        } else if (formated.split("-")[2] == "owned") {
          site_id = formated.split("-")[1];
          site = "jugar";
          if (router.currentRoute.query.token) {
            if (router.currentRoute.query.token != "") {
              token = router.currentRoute.query.token.slice(0, -5);
            }
          }
        }
      } else {
        site = "jugar";
        if (router.currentRoute.query.token) {
          if (router.currentRoute.query.token != "") {
            token = router.currentRoute.query.token.slice(0, -5);
            site_id = router.currentRoute.query.token.substring(
              token.length,
              router.currentRoute.query.token.length
            );
          }
        }
      }
      if (token != "" && token != "123456789") {
        let router_data = {
          token: token,
          site_id: site_id,
          site: site,
          prefix: prefix,
        };
        state.commit("setToken", router_data);
        state.dispatch("getSiteDomain", router_data);
      } else if (token == "123456789") {
        console.log(token);
        let router_data = {
          token: "",
          site_id: site_id,
          site: site,
          prefix: prefix,
        };
        state.commit("setToken", router_data);
        state.dispatch("getSiteDomain", router_data);
      } else {
        let restoredUser = JSON.parse(localStorage.getItem("user_data"));
        if (restoredUser ? (restoredUser.token ? true : false) : false) {
          if (restoredUser.site_id != site_id) {
            localStorage.removeItem("user_data");
            let router_data = {
              token: "",
              site_id: site_id,
              site: site,
              prefix: prefix,
            };
            state.commit("setToken", router_data);
            state.dispatch("getSiteDomain", router_data);
          } else {
            state.commit("setToken", restoredUser);
            state.dispatch("getSiteDomain", restoredUser);
          }
        } else {
          let router_data = {
            token: "",
            site_id: site_id,
            site: site,
            prefix: prefix,
          };
          state.commit("setToken", router_data);
          state.dispatch("getSiteDomain", router_data);
        }
      }
    },
    async getSiteDomain(state, payload) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_BET_SERVER +
            "get_domain?site_id=" +
            payload.site_id
        );
        let domain = response.data.domain;
        payload.domain = domain;
        if (state.state.user_token != "") {
          state.dispatch("getUserData", payload);
        }
        state.commit("setDomain", domain);
        if (!payload.type) {
          state.dispatch("getConfig", domain);
        }
        payload.type = null;
        state.commit("setPrematchProvider", response.data.prematch_provider);
        localStorage.setItem("user_data", JSON.stringify(payload));
      } catch (error) {
        console.log(error);
      }
    },
    async getUserData(state, payload) {
      try {
        if (!payload.source) {
          state.commit("renderSports", false);
        }
        const response2 = await axios.post(
          (state.state.domain || payload.domain) +
            "/" +
            state.state.prefix +
            "/scommand?access_token=" +
            payload.token +
            "&unico=" +
            payload.token +
            "&site=" +
            payload.site,
          { command: "get_balance", params: {} }
        );
        if (response2.data.data.error) {
          state.commit("setToken", {
            token: "",
            site_id: payload.site_id,
            site: payload.site,
            prefix: payload.prefix,
          });
          swal("Error", response2.data.data.message, "error");
          state.commit("renderSports", true);
        } else {
          state.commit("setUserData", response2.data);
          if (!payload.source) {
            state.commit("renderSports", true);
          }
          if (localStorage.getItem("bet_currency")) {
            let newVal = localStorage.getItem("bet_currency");
            state.commit("changeCurrencyFiat", {
              fiat: newVal,
              rate:
                newVal != "BETC"
                  ? newVal == "USDT"
                    ? response2.data.data.tasa
                    : response2.data.data.tasa_fiat
                  : 1,
            });
          } else {
            state.commit("changeCurrencyFiat", {
              fiat: response2.data.data.currency_fiat,
              rate: response2.data.data.tasa_fiat,
            });
            localStorage.setItem(
              "bet_currency",
              response2.data.data.currency_fiat
            );
          }
        }
      } catch (error) {
        state.commit("setToken", {
          token: "",
          site_id: payload.site_id,
          site: payload.site,
          prefix: payload.prefix,
        });
        state.commit("renderSports", true);
      }
    },
    async getConfig(state, payload) {
      let rid = Date.now();
      try {
        const response = await axios.get(
          payload +
            "/setup/" +
            state.getters.getUserData.site +
            "/conf.json?" +
            rid
        );
        state.commit(
          "jbets_socket_connection",
          state.getters.getUserData.site_id
        );
        state.commit("set_config", response.data);
        state.commit("socket_connection");
      } catch (error) {
        console.log(error);
        const response = await axios.get(payload + "/setup/conf.json?" + rid);
        state.commit(
          "jbets_socket_connection",
          state.getters.getUserData.site_id
        );
        state.commit("set_config", response.data);
        state.commit("socket_connection");
      }
    },
    async getSkin(state) {
      try {
        let formated = router.currentRoute.query.target;
        state.commit("setHostName", formated);
        let site_id = formated.split("-")[0] + "-" + formated.split("-")[1];
        const response = await axios.get(
          process.env.VUE_APP_DATA_SERVER + "get_skin?site_id=" + site_id
        );
        if (response.data.length > 0) {
          if (response.data[0].open_view != null) {
            if (response.data[0].open_view == "live") {
              router.push({ name: "LiveMainEcuabets" });
            }
          } else if (response.data[0].prematch != null) {
            if (!response.data[0].prematch) {
              router.push({ name: "LiveMainEcuabets" });
            }
          }
          state.commit("setMainView", response.data);
          state.dispatch("setToken");
        }
        const response2 = await axios.get(
          process.env.VUE_APP_DATA_SERVER + "get_translations"
        );
        state.commit("setTranslations", response2.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getExternalEvents(state) {
      try {
        let formated = router.currentRoute.query.target;
        let site_id = formated.split("-")[0] + "-" + formated.split("-")[1];
        const response = await axios.get(
          process.env.VUE_APP_DATA_SERVER +
            "get_external_events?site_id=" +
            site_id
        );
        state.commit("setExternalEvents", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async socket_connection(state) {
      await state.commit("socket_connection");
    },
    async jbets_socket_connection(state) {
      await state.commit("jbets_socket_connection");
    },
    async setLobbyView(state, payload) {
      await state.commit("setLobbyView", payload);
    },
    async newBooking(state, payload) {
      try {
        let bets = state.getters.getBets;
        let data = {
          odds: bets,
          amount: payload,
          domain: state.getters.getDomain,
        };
        const response = await axios.post(
          process.env.VUE_APP_BET_SERVER + "new_booking",
          data
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getBooking(state, payload) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_BET_SERVER + "get_booking?code=" + payload
        );
        state.commit("setBetsFromBooking", response.data);
        return response.data.amount || 0;
      } catch (error) {
        console.log(error);
      }
    },
    async getTracking(state, payload) {
      try {
        let timeZoneOffset = new Date().getTimezoneOffset() / 60;
        let timezoneFormated =
          (Math.abs(timeZoneOffset) < 9
            ? -1 * timeZoneOffset < 0
              ? "-0" + timeZoneOffset
              : "0" + timeZoneOffset
            : -1 * timeZoneOffset) + ":00";
        const response = await axios.post(
          state.state.domain +
            "/" +
            state.state.prefix +
            "/scommand?access_token=" +
            state.state.user_token +
            "&site=" +
            state.state.site,
          {
            command: "bet_history",
            params: {
              where: {
                time_shift: parseInt(timezoneFormated),
                from_date: payload.from,
                to_date: payload.to,
                bets_client: payload.user_id,
                bet_id: payload.bet_id,
              },
            },
          }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getOddData(state, payload) {
      try {
        const response = await axios.post(
          process.env.VUE_APP_BET_SERVER + "get_odd_data",
          { site_id: state.state.config.main.site_id, ids: payload }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async doCashOut(state, payload) {
      try {
        const response = await axios.post(
          state.state.domain +
            "/" +
            state.state.prefix +
            "/scommand?access_token=" +
            state.state.user_token +
            "&site=" +
            state.state.site,
          {
            command: "cashout",
            params: {
              bet_id: payload.bet_id,
              price: payload.price,
              codigo: payload.code,
              vat: payload.vat,
              platform: "desktop",
              site_id: state.state.config.main.site_id,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async login(state, payload) {
      try {
        const response = await axios.get(
          state.state.domain +
            "/oauth/v2/token?username=" +
            payload.username +
            "&password=" +
            payload.password +
            "&client_id=1_5i50wo24kpcscc0okw0ww4gsc8kwg0k8gs0ok44skooww4swcg&client_secret=18qxs6584gw08scg8wsk8gow44oc4gcw40c4o8w44880g0gkcg&grant_type=password"
        );
        let site = "";
        let site_id = state.state.site_id;
        let token = response.data.access_token;
        let prefix = "api";
        site = "jugar";
        let router_data = {
          token: token,
          site_id: site_id,
          site: site,
          prefix: prefix,
          type: "login",
        };
        state.commit("setToken", router_data);
        state.dispatch("getSiteDomain", router_data);
        return "success";
      } catch (error) {
        console.log(error);
        return "Usuario o clave invalidos! Intenta nuevamente.";
      }
    },
    async logOut(state) {
      state.commit("logOut");
      let main_site_data = JSON.parse(localStorage.getItem("user_data"));
      main_site_data.token = "";
      localStorage.setItem("user_data", JSON.stringify(main_site_data));
      state.commit("setLobbyView", "main");
      state.commit("renderSports", false);
      let rerender = setTimeout(function () {
        state.commit("renderSports", true);
        clearTimeout(rerender);
      }, 300);
    },
    async copyTicket(state, payload) {
      const response = await axios.post(
        state.state.domain + "/swarm/remote/copyjget",
        { id: payload }
      );
      if (!response.data.error) {
        let data = {};
        response.data.data.bets.forEach((odd) => {
          data[odd.event_id] = odd;
        });
        state.commit("setBetsFromBooking", { odds: data });
        return "ok";
      }
      return "error";
    },
    // async getAllOdds(state) {
    //   state.commit("clearOdds");
    //   try {
    //     let config = {
    //       headers: { Authorization: `Bearer ${state.state.user_data.token}` },
    //     };
    
    //     const initialResponse = await axios.post("https://jbets.online/api/get_bets", {
    //       headers: {
    //         Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmUyZTFjYmFhOWMxMjc5ZjRkODZlYWUiLCJuYW1lIjoiQWxlamFuZHJvIiwiZW1haWwiOiJscGVkcmVyb3NAdW5hbC5lZHUuY28iLCJyb2xlIjoidXNlciIsImlhdCI6MTY5OTU3MTgzMX0.ajmUubpcwEJiSsC3N6ImRM_nAlydhKZ_hG60UDdPJek",
    //       },
    //       filters: {
    //         order: { name: "start_time", order: "asc" },
    //         test: "",
    //       },
    //       page: 1, // Cambiado a page 1 inicialmente
    //     });
    
    //     const totalPages = initialResponse.data.totalPages;
    
    //     // Crear un array de promesas para las solicitudes de las páginas restantes
    //     const promises = [];
    //     for (let page = 2; page <= totalPages; page++) {
    //       promises.push(
    //         axios.post("https://jbets.online/api/get_bets", {
    //           headers: {
    //             Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmUyZTFjYmFhOWMxMjc5ZjRkODZlYWUiLCJuYW1lIjoiQWxlamFuZHJvIiwiZW1haWwiOiJscGVkcmVyb3NAdW5hbC5lZHUuY28iLCJyb2xlIjoidXNlciIsImlhdCI6MTY5OTU3MTgzMX0.ajmUubpcwEJiSsC3N6ImRM_nAlydhKZ_hG60UDdPJek",
    //           },
    //           filters: {
    //             order: { name: "start_time", order: "asc" },
    //             test: "",
    //           },
    //           page: page,
    //         })
    //       );
    //     }
    
    //     // Esperar a que todas las promesas se resuelvan
    //     const responses = await Promise.all(promises);
    
    //     // Concatenar los resultados de todas las páginas
    //     const allDocs = [initialResponse.data.docs, ...responses.map(response => response.data.docs)];
    
    //     // Aplanar el array de arrays a un solo array
    //     const flattenedDocs = allDocs.flat();
    //     console.log(flattenedDocs);

    
    //     // Actualizar el estado con todos los documentos
    //     state.commit("setOdds", { docs: flattenedDocs });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    
    async getAllOdds({ commit, state }, payload) {
      commit('clearOdds');
      try {
        const config = {
          headers: { Authorization: `Bearer ${state.user_data.token}` },
        };

        console.log("Authorization header:", config.headers); // Verificación adicional

        const response = await axios.post(
          `${process.env.VUE_APP_DATA_RESULTS}get_odds`,
          {
            filters: {
              order: { name: 'start_time', order: 'asc' },
              text: payload,
            },
            page: 1,
          },
          config
        );

        commit("setOdds", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    
    async getGames(state,payload) {
      state.commit("clearOdds");
      try {
        let config = {
          headers: { Authorization: `Bearer ${state.state.user_data.token}` },
        };
        // console.log(payload, config);
        const response = await axios.post(process.env.VUE_APP_DATA_RESULTS+"get_games", 
          {
            "sport":payload.sport,
            "league":payload.league,
            "startDate":payload.startDate,
            "endDate":payload.endDate
          }
        );
        // console.log(response.data);
        state.commit("setGames", response.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {
    live,
    prematch,
    prematch_2,
  },
  getters: {
    getHeaderHeight: (state) => state.headerHeight,
    getCurrency: (state) => state.currency,
    getBetType: (state) => state.bet_type,
    getRenderSportsMenu: (state) => state.renderSports,
    getRateType: (state) => state.rate_type,
    getCurrentSkin: (state) => state.skins[state.currentSkin],
    getSkinCharged: (state) => state.skin_charged,
    getHostName: (state) => state.hostName,
    getLanguage: (state) => state.language,
    getBets: (state) => state.bets,
    getTranslation: (state) => state.translation,
    getCanBet: (state) => state.can_bet,
    getToken: (state) => state.user_token,
    getDomain: (state) => state.domain,
    getSiteId: (state) => state.site_id,
    getUserData: (state) => {
      return {
        token: state.user_token,
        site_id: state.site_id,
        domain: state.domain,
        site: state.site,
        prefix: state.prefix,
        user_data: state.user_data,
        vivoOperatorId: state.config.SkinCConfig.vivoOperatorId,
        vivoServerId: state.config.SkinCConfig.vivoServerId,
      };
    },
    getJbetsModifieOdds: (state) => state.jbets_modified_odds,
    getView: (state) => state.view,
    getSocket: (state) => state.websocket,
    getConfig: (state) => state.config,
    getPrematchTarget: (state) => state.prematch_target,
    getLobbyView: (state) => state.lobby_view,
    getExternaEvents: (state) => {
      let sports = {};
      if (state.loadedExternalData && state.external_events.data) {
        Object.keys(state.external_events.data.data.sport).forEach((sport) => {
          if (
            state.external_events.data.data.sport[sport] &&
            Object.keys(state.external_events.data.data.sport[sport]).length > 0
          ) {
            sports[sport] = state.external_events.data.data.sport[sport];
          }
        });
      }
      return sports;
    },
    getAllOdds: (state) => state.odds,
    getOddsLoaded: (state) => state.odds_loaded,
    getGames : (state) => state.games
  },
});
