<template>
  <div class="p-1 dropdown">
        <div :style="skin.main_leagues.header" class="m-auto p-1 btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <span :class="'phoca-flag ' + getFlagClass(language)" style="width:30px; height:20px; font-size:small"></span>
        </div>
        <ul class="dropdown-menu">
            <li v-for="(lang, index) in Object.keys(skin.languages)" :key="index"><button v-if="skin.languages[lang].enabled" class="dropdown-item" type="button" @click="change_language(lang)">{{ skin.languages[lang].name }}</button></li>
        </ul>
    </div>
</template>

<script>
export default {
    name:"language_selector",
    props:['skin','language'],
    data(){
        return {
            
        }
    },
    methods:{
        change_language(lg){
            this.$store.dispatch('setLanguage', lg);
        },
        getFlagClass(language) {
            console.log(language)
            // Define las clases de las banderas según los códigos de idioma
            const flagClasses = {
            'es': 'es',
            'en': 'gb',
            'fr': 'fr', // Agrega la clase correspondiente para francés
            'pt': 'br',
            // Agrega más códigos y clases según sea necesario
            };

            return flagClasses[language] || 'default-flag'; // Si no se encuentra, devuelve una clase de bandera predeterminada
        },
    }
}
</script>

<style>
</style>