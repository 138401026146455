<template>
  <th class="py-2">
    <div class="py-2 pb-2 px-0 pe-0 text-center"
        :style="Skin.borde_round3"
        :class="Skin.text1+(active_state?' hover':'')"
        :id="logro.id+'button'"
        style="cursor:pointer;min-width:50px;"
        @mouseover="active_state?'':hover(logro.id+'button')"
        @mouseleave="active_state?'':hover2(logro.id+'button')"
    >
        {{ rate[rate.length-4]=="."?rate.slice(0, -1):rate }}
    </div>
  </th>
</template>

<script>
export default {
  name: 'odd',
  props:["Skin","rate","logro","active_state"],
  data(){
    return {
  
    }
  },
  methods:{
    hover(id){
            document.getElementById(id).classList.add('hover');
        },
    hover2(id){
        document.getElementById(id).classList.remove('hover');
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .hover {
      background-color: #ffd029;
      color:black;
  }
</style>