<template>
  <div>
    <div v-if="check_game" :style="Skin.prematch_all_odds.fondo">
      <ul class="nav p-1" :style="Skin.prematch_all_odds.nav_bar">
        <li
          v-for="(group, key) in markets"
          v-bind:key="key"
          style="cursor:pointer"
          :style="nav_activeCss"
          class="nav-item mk-1"
          :class="Object.keys(markets).indexOf(key)==0 ? 'nav_active': ''"
          :id="'pills-' + key.replace(/\s/g).replace(/\(|\)/g, '') + '-tab-' + game.id"
          data-bs-toggle="pill"
          :data-bs-target="'#pills-' + key.replace(/\s/g).replace(/\(|\)/g, '') + '-' + game.id"
          role="tab"
          :aria-controls="'pills-' + key.replace(/\s/g).replace(/\(|\)/g, '')"
          aria-selected="true"
          v-on:click="active('pills-' + key.replace(/\s/g).replace(/\(|\)/g, '') + '-tab-' + game.id)"
        >
          <span class="m-2">{{ translate(key) == 'Pronostico' ? 'Crear Pronóstico' :  translate(key)}}</span>
        </li>
      </ul>
      <div class="tab-content m-2" id="pills-tabContent">
          <div
            v-for="(group, key) in markets"
            v-bind:key="key.replace(/\s/g).replace(/\(|\)/g, '') + 'content'"
            class="tab-pane fade"
            :class="Object.keys(markets).indexOf(key)==0 ? 'show active' : ''"
            :id="'pills-' + key.replace(/\s/g).replace(/\(|\)/g, '') + '-' + game.id"
            role="tabpanel"
            :aria-labelledby="'pills-' + key.replace(/\s/g).replace(/\(|\)/g, '') + '-tab-' + game.id"
          >
            <div class="row">
              <div v-for="(column,idx) in group" 
              :key="key+'-'+idx" 
              :class="group.length>1 ? 'col-6' : 'col-12'"
              >
                <table
                  v-for="(market, idx) in column"
                  v-bind:key="idx"
                  :style="Skin.prematch_all_odds.odd_frame"
                  style="overflow:hidden"
                  class="
                    table
                    table-borderless
                    align-middle
                  "
                >
                  <thead class="align-middle" :style="Skin.prematch_all_odds.t_head">
                    <tr>
                      <th class="text-start p-0">
                        <span style="color: white; font-size: medium" class="p-2" :style="Skin.prematch_all_odds.t_head_text">{{
                          translate(market.name)
                        }}</span>
                        <span v-if="cashoutEnabled(market.type)" :style="Skin.prematch_all_odds.t_head_text" title="Cashout enabled">
                          <i class="fas fa-coins"></i>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody :style="Skin.prematch_all_odds.t_body_text">
                    <tr>
                      <td>
                        <div class="row px-2 py-2 pt-0 pb-0" :style="hoverCss">
                          <div
                            v-for="(logro) in market.events"
                            v-bind:key="logro.id"
                            class="col col-4 pe-2 px-2 py-1 pb-1"
                            style="cursor:pointer"
                            :style="Skin.prematch_all_odds.t_body_frame"
                            :class="(selected_odds[logro.id] ? ' hover' : '')+ columns(Object.entries(market.event).length)"
                            :id="logro.id + 'content'"
                            @mouseover="
                              selected_odds[logro.id]
                                ? ''
                                : hover(logro.id + 'content')
                            "
                            @mouseleave="
                              selected_odds[logro.id]
                                ? ''
                                : hover2(logro.id + 'content')
                            "
                            @click="
                              validate_odd(logro) == '1.00' || game.is_blocked ? '' :
                              odd_selected(
                                game.id,
                                logro,
                                market.name,
                                market.type,
                                market.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                key
                              )
                            "
                          >
                            <div v-if="screen_width>Skin.break_point">
                              <div v-if="logro" class="row px-2 pe-2">
                                <div class="col-9 text-left p-0"><span :title="(logro ? odd_header(logro.name) : 'error') + (logro.base ? '('+(logro.base)+')' : '' )" class="long_text">{{ logro ? odd_header(logro.name) : "error" }} {{ logro.base ? '('+(logro.base)+')' : "" }}</span></div>
                                  <div class="col-3 text-end p-0" v-if="key!='Pronostico' && key!='Teaser'"> 
                                    <span
                                      v-if="validate_odd(logro) != '1.00' && !game.is_blocked"
                                    >
                                      {{ validate_odd(logro)  }}
                                    </span>
                                    <span v-else style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                                  </div>
                              </div>
                            </div>
                            <div v-else class="text-center">
                              {{
                                logro ? odd_header(logro.name) : "error" }} {{ logro.base ? '('+(logro.base)+')' : ""
                              }}<br />
                              <div v-if="key!='Pronostico' && key!='Teaser'">
                                <span 
                                  v-if="validate_odd(logro) != '1.00' && !game.is_blocked">
                                  {{ validate_odd(logro)  }}
                                </span>
                                <span v-else style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div v-if="!check_game" class="text-center p-5" style="height: 80vh">
      <p>{{translate("warning1")}}<br> {{translate("not_Accept_bets")}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "All_odds",
  props: ["Skin", "translation", "language","game","league"],
  data() {
    return {
      qty_columns:1,
      screen_width:0,
    };
  }, 
  computed:{
      markets(){
          let data = [];
          let data2= {};
          let data3 = [];
          let markets_banned = this.$store.getters['getConfig'].SkinConfig.main.removeMarketPrematch??[];
          let first_option_banned = this.$props.Skin.prematch_all_odds.first_option_banned ?? [];
          let last_option_banned = this.$props.Skin.prematch_all_odds.last_option_banned ?? [];
          let combinations =  this.$store.getters['getUserData'].user_data.data ? this.$store.getters['getUserData'].user_data.data.combinaciones : [];
          let sport = this.$props.game.sport_alias;

          Object.entries(this.$props.game.market).forEach(market => {
              if(market[1] != 'undefined' && market[1]){
                data[market[1].group_name || 'Otros'] = {"order":market[1].group_order,"markets":[]};
              }
          });
          if(combinations[sport]){
            data['Pronostico'] = {"order":4,"markets":[]};
          }

          let order = Object.keys(data).sort((a,b)=>{return data[a].order - data[b].order});
          order.forEach(item=>{
            data3[item] = data[item]; 
          })
          Object.entries(this.$props.game.market).forEach(market => {
              if(market[1] != 'undefined' && market[1]){
                if(!markets_banned.includes(market[1].market_type)){
                  market[1]['market_id'] = market[0];
                  let last_event = this.$props.game.last_event ? this.$props.game.last_event.type_id : "0";
                  if(!((last_event == "21" || last_event =="4") && market[1].type == "TeamToScore")){
                    data3[market[1].group_name || 'Otros'].markets.push(market[1]);
                    let combined = combinations[sport] ? combinations[sport][0].includes(market[1].type) : false;
                    if(combined){
                      data3['Pronostico'].markets.push(market[1]);
                    }
                  }
                } 
              }
          });
          Object.entries(data3).forEach(element =>{
            element[1].markets.forEach(main_data => {
              let events = [];
              if(main_data.event){
                Object.entries(main_data.event).forEach(event =>{
                  if(event[1]){
                    let asian_base=event[1].base?(event[1].base.toString().split(".")[1] == "75" || event[1].base.toString().split(".")[1] == "25"?true:false):false;
                    if(!asian_base){
                      events.push(event[1]);
                    }
                  }
                });
                events.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
                if(events.length>0){
                  main_data.events = events
                }
              }
            });
          });
          Object.entries(data3).forEach(element => {
            element[1].markets.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            element[1].markets = element[1].markets.filter(market=>market.events?.length>0);
            first_option_banned.forEach(target=>{
              let index = element[1].markets.findIndex( m => m.type === target);
              if(index!=-1){
                element[1].markets.splice(index,1); 
              }
              let index2 = element[1].markets.findIndex( m => (m.type === target.name && sport === target.sport));
              if(index2!=-1){
                element[1].markets.splice(index2,1); 
              }
            })
            last_option_banned.forEach(target=>{
              let reverseIndex = element[1].markets.slice().reverse().findIndex(m => m.type === target);
              let index = reverseIndex !== -1 ? element[1].markets.length - 1 - reverseIndex : -1;

              if (index !== -1) {
                element[1].markets.splice(index, 1); 
              }

              let reverseIndex2 = element[1].markets.slice().reverse().findIndex(m => m.type === target.name && sport === target.sport);
              let index2 = reverseIndex2 !== -1 ? element[1].markets.length - 1 - reverseIndex2 : -1;

              if (index2 !== -1) {
                element[1].markets.splice(index2, 1); 
              }
            })
            let columns = [];
            let mid = Math.ceil(element[1].markets.length / this.qty_columns);
            for (let col = 0; col < this.qty_columns; col++) {
              columns.push(element[1].markets.slice(col * mid, col * mid + mid))
            }
            data2[element[0]] = columns;
          });
          //console.log((data2)
          return data2;
      },
      factorAjuste(){
        return this.$store.getters['getConfig'].SkinConfig.main.deltalogro ? this.$store.getters['getConfig'].SkinConfig.main.deltalogro : 1;
      },
      selected_odds(){
        let odds = {};
        let data = this.$store.getters['getBets'];
        Object.keys(data).forEach(bet => {
          if(data[bet]){
            odds[bet] = true;
          }
        });
        return odds;
      },
      check_game(){
        let game = this.$props.game;
        if(game.markets_count == 0){
          return false;
        }
        else{
          return true;
        }
      },
      nav_activeCss(){
        return {
          '--bar':this.$props.Skin.prematch_all_odds.nav_active_bar,
          '--text':this.$props.Skin.prematch_all_odds.nav_active_text,
        }
      },
      hoverCss(){
        return {
          '--fondoH':this.$props.Skin.prematch_all_odds.hover_fondo,
          '--textH':this.$props.Skin.prematch_all_odds.hover_text,
        }
      },
      rate_type(){
          return this.$store.getters['getRateType'];
      },
      jbets_modified_odds(){
        return this.$store.getters['getJbetsModifieOdds'];
      }
  },
  methods: {
    cashoutEnabled(market){
      let cashoutMarkets = this.$store.getters['getConfig'].SkinConfig.main.removeCashout;
      if(cashoutMarkets.includes(market)){
        return true
      }
      return false
    },
    sizeControl(){
      this.screen_width=window.innerWidth;
      if(window.innerWidth<450){
        this.qty_columns = 1;
      }
      else{
        this.qty_columns = 1;
      }
    },
    hover: function (id) {
      document.getElementById(id).classList.add('hover');
    },
    hover2: function (id) {
      document.getElementById(id).classList.remove('hover');
    },
    translate(text) {
      if(text){
        if (this.$props.translation[text]) {
          return this.$props.translation[text][this.$props.language];
        }
        else{
          var new_text_serched = text.replace(/ /g, "_");
          if (this.$props.translation[new_text_serched]) {
            return this.$props.translation[new_text_serched][this.$props.language];
          }
        }
        return text;
      }
    },
    format_date: function (date) {
      var date_str = this.safe_date(date)+" UTC";
      date = new Date(date_str);
      var formated =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        date.getHours() +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2);
      return formated;
    },
    safe_date(date){
      var parsed_array_1 = date.split(" ");
      var parsed_array_2 = parsed_array_1[0].split("-");
      var date_str = parsed_array_2[1]+"/"+parsed_array_2[2]+"/"+parsed_array_2[0]+" "+parsed_array_1[1];
      return date_str;
    },
    active: function (id) {
      var all = document.getElementsByClassName("mk-1");
      [...all].forEach((element) => {
        element.classList.remove('nav_active');
        element.classList.remove('active');
      });
      document.getElementById(id).classList.add('nav_active');
      document.getElementById(id).classList.add('active');
    },
    columns(len){
      if(len == 3){
        return ' col-4';
      }
      else if(len == 2){
        return ' col-6';
      }
      else{return ' col-4'}
    },
    odd_header(header){
      if(header){
        if(header.includes("W1")){
          header = header.replace("W1",this.$props.game.team1_name);
        }
        if(header.includes("Equipo 1")){
          header = header.replace("Equipo 1",this.$props.game.team1_name);
        }
        if(header.includes("W2")){
          header = header.replace("W2",this.$props.game.team2_name);
        }
        if(header.includes("Equipo 2")){
          header = header.replace("Equipo 2",this.$props.game.team2_name);
        }
        if(header.includes(" X")){
          header = header.replace("X"," Empate");
        }
        return header;
      }
      return "error";
    },
    validate_odd(odd){
      if(odd){
        if(odd.price){
          let value = odd.price*this.factorAjuste<1?1.00:odd.price*this.factorAjuste;
          let jbets_modified_odd = this.jbets_modified_odds.filter(odd_modified=>odd_modified.event_id == odd.id);
          if(jbets_modified_odd.length==1){
            value = jbets_modified_odd[0].blocked?1.00:value*jbets_modified_odd[0].factor<1?1.00:value*jbets_modified_odd[0].factor;
          }
          if(this.rate_type == 'americano'){
            value = parseFloat(value);
            if(value >= 2){
                let result = (value-1)*100;
                return '+'+(Math.round(result));
            }
            else{
                let result = (-100)/(value-1);
                return Math.round(result);
            }
          }
          return value.toFixed(2)
        }
        else{
          return null;
        }
      }
    },
    odd_selected: function (
      event_id,
      logro,
      categoria,
      market_type,
      market_id,
      home,
      away,
      event_date,
      market
    ) {
      this.$root.$emit('bet_loading');
      if (this.selected_odds[logro.id]) {
        this.$store.dispatch('live/unsuscribe_odd',logro.id);
        return;
      }
      const UTC = new Date().toISOString();
      const currentTime = Math.round(new Date(UTC).getTime() / 1000);
      let timeZoneOffset = new Date().getTimezoneOffset() / 60;
      let timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
      let deltaCombinada = this.$store.getters['getConfig'].SkinConfig ? this.$store.getters['getConfig'].SkinConfig.main.deltacombinada || 0.8 : 0.8;
      let price = (logro.price*this.factorAjuste<1?1.00:logro.price*this.factorAjuste);
      let jbets_modified_odd = this.jbets_modified_odds.filter(odd_modified=>odd_modified.event_id == logro.id);
      if(jbets_modified_odd.length==1){
        price = jbets_modified_odd[0].blocked?1.00:price*jbets_modified_odd[0].factor<1?1.00:price*jbets_modified_odd[0].factor;
      }
      var odd_data = {
          "provider":"mode" in this.$props.game ? "client" : "betconstruct",
          "mode":"mode" in this.$props.game ? "external" : "socket",
          "game_id": event_id, //id del evento ok
          "event_id": logro.id,//id de la apuesta ok
          "price": price.toFixed(2), //cuota ok
          "equipoa": home, //ok
          "equipob": away, //ok
          "market_id":market_id,// ID del marcado
          "referencia":market_type, //referencia de la apuesta
          "seleccion": this.odd_header(logro.name)+(logro.base ? ' ('+(logro.base)+')' : ""), //logro ok
          "seleccion_ref":logro,
          "start_ts": event_date, // fecha encuentro ok
          "islive": false, //ok
          "alias":this.$props.game.sport_alias, //ok
          "timestamp": currentTime, // fecha apuesta ok
          "blocked": Boolean(this.$props.game.is_blocked), //ok
          "blocked_reason":"",
          "deltaCombinada":market=='Pronostico'?deltaCombinada:1,
          "extra_data":{
            "apuesta": this.translate(categoria).toUpperCase(), //tipo apuesta ok
            "market": market, //nombre del mercado
            "pais": this.$props.game.region_alias, //ok
            "liga": this.$props.league,//ok
            "deporte": this.$props.game.sport_alias,//ok
            "format": this.rate_type == 'americano' ? 'american' : 'decimal',
            "current_state": this.$props.game.info.current_game_state, //ok
            "lang": this.$props.Skin.languages[this.$props.language].code,//ok
            "timezone": timezoneFormated,//ok
            "geo":{},
            "score":{
                "team1":null,
                "team2":null,
            },
            "text_info":"",
            "detallesItem":""
          }
      };
      this.$store.dispatch('live/suscribe_odd', odd_data);
    }
  },
  created() {
    this.sizeControl();
  }, 
  mounted(){
      window.addEventListener('resize', ()=>{this.sizeControl();});
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.long_text {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.nav_active{
  color:var(--text);
  border-bottom:var(--bar);
}
.hover{
  background-color: var(--fondoH) !important;
  color:var(--textH) !important;
}
</style>
