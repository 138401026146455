import { render, staticRenderFns } from "./main_external.vue?vue&type=template&id=71e0cace&scoped=true&"
import script from "./main_external.vue?vue&type=script&lang=js&"
export * from "./main_external.vue?vue&type=script&lang=js&"
import style0 from "./main_external.vue?vue&type=style&index=0&id=71e0cace&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e0cace",
  null
  
)

export default component.exports