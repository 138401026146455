<template>
  <div>
    <div class="p-2" :style="skin.my_account.menu_item_border">
      <h5>{{translate("Reports")}}</h5>
    </div>
    <div class="row p-2" style="width:100%;">
      <div class="p-1 col-12 col-sm col-md col-lg-4">
        <date-picker v-model="date_range" type="datetime" range placeholder="" style="max-width:100%"></date-picker>
      </div>
      <div class="p-1 col-12 col-sm col-md col-lg-4 text-center">
        <button @click="loadReport()" :style="skin.qbet.booking_btn" class="p-1 text-center mb-2" style="width:50%;color:white" :disabled="loading_report">{{translate('show')}}</button>
      </div>
    </div>
    <div v-if="loading_report" style="width:100%" class="text-center p-2" :style="skin.qbet.fondo3">
      <div class="spinner-border" :style="skin.loader" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="report_data.inicio" class="table-responsive">
      <table class="table" :style="skin.my_account.close_btn">
        <thead>
          <tr :style="skin.my_account.main_menu_background">
            <th style="min-width:150px">{{translate("ticket_sales")}}</th><th>{{ parseFloat(report_data.ventatickets??0).toFixed(2) }}</th>
            <th style="min-width:150px">{{translate("sale_refills")}}</th><th>{{ parseFloat(report_data.recargasvendidas??0).toFixed(2) }}</th>
            <th style="min-width:150px">{{translate("prizes_generated")}}</th><th>{{ parseFloat(report_data.premiosgenerados??0).toFixed(2) }}</th>
            <th style="min-width:150px">{{translate("prizes_paid")}}</th><th>{{ parseFloat(report_data.premiospagados??0).toFixed(2) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{translate("paid_withdrawals")}}</td><td>{{ report_data.retirospagados??0 }}</td><td></td><td></td><td></td><td></td>
            <td>{{translate("net_parley")}}</td><td>{{ parseFloat(report_data.netoParley??0).toFixed(2) }}</td>
          </tr>
          <tr>
            <td></td><td></td><td></td><td></td><td></td><td></td>
            <td>{{translate("utility")}}</td><td>{{ parseFloat(report_data.utilidad??0).toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table" :style="skin.my_account.close_btn">
        <thead>
          <tr>
            <th :style="skin.my_account.main_menu_background" colspan="8">{{translate("cash_report")}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style="min-width:150px">{{translate("ticket_sales")}}</th><th>{{ parseFloat(report_data.ventatickets??0).toFixed(2) }}</th>
            <th style="min-width:150px">{{translate("sale_refills")}}</th><th>{{ parseFloat(report_data.recargasvendidas??0).toFixed(2) }}</th>
            <th style="min-width:150px">{{translate("prizes_generated")}}</th><th>{{ parseFloat(report_data.premiosgenerados??0).toFixed(2) }}</th>
            <th style="min-width:150px">{{translate("prizes_paid")}}</th><th>{{ parseFloat(report_data.premiospagados??0).toFixed(2) }}</th>
          </tr>
          <tr>
            <td></td><td></td><td></td><td></td><td></td><td></td>
            <td>{{translate("net_parley")}}</td><td>{{ (report_data.netoParley2??0).toFixed(2) }}</td>
          </tr>
          <tr>
            <td></td><td></td>
            <td>{{translate("revenue")}}</td><td>{{ parseFloat(report_data.ingreso??0).toFixed(2) }}</td>
            <td>{{translate("expenses")}}</td><td>{{ parseFloat(report_data.egreso??0).toFixed(2) }}</td>
            <td>BALANCE</td><td>{{ parseFloat(report_data.balanceEnFecha??0).toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table" :style="skin.my_account.close_btn">
        <thead>
          <tr>
            <th :style="skin.my_account.main_menu_background" colspan="8">{{translate("pro_ticket_office_daate")}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="min-width:150px">{{translate("box_office_bets")}}</td><td>{{ report_data?.apostadoTaquilla??0 }}</td>
            <td style="min-width:150px">{{translate("best_web_users")}}</td><td>{{ report_data.usuariosWeb?.apuestasTotal??0 }}</td>
            <td style="min-width:150px">{{translate("paid_box_office")}}</td><td>{{ report_data?.premiospagados??0 }}</td>
            <td style="min-width:150px">{{translate("paid_web")}}</td><td>{{ report_data.usuariosWeb?.apuestasPagadasTotal??0 }}</td>
          </tr>
          <tr>
            <td>{{translate("total_sales")}}</td><td>{{ (parseFloat(report_data?.apostadoTaquilla??0) + parseFloat(report_data.usuariosWeb?.apuestasTotal??0)).toFixed(2) }}</td>
            <td>{{translate("total_paid")}}</td><td>{{ (parseFloat(report_data?.premiospagados??0) + parseFloat(report_data.usuariosWeb?.apuestasPagadasTotal??0)).toFixed(2) }}</td>
            <td></td><td></td>
            <td>{{translate("cost_effectiveness")}}</td><td>{{ ((parseFloat(report_data?.apostadoTaquilla??0) + parseFloat(report_data.usuariosWeb?.apuestasTotal??0)) - (parseFloat(report_data?.premiospagados??0) + parseFloat(report_data.usuariosWeb?.apuestasPagadasTotal??0))).toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table" :style="skin.my_account.close_btn">
        <thead>
          <tr>
            <th :style="skin.my_account.main_menu_background" colspan="8">{{translate("pro_ticket_payment_daate")}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="min-width:150px">{{translate("box_office_bets")}}</td><td>{{ report_data?.apostadoTaquilla??0 }}</td>
            <td style="min-width:150px">{{translate("best_web_users")}}</td><td>{{ report_data.usuariosWeb?.apuestasTotal??0 }}</td>
            <td style="min-width:150px">{{translate("paid_box_office")}}</td><td>{{ report_data?.apuestasEnFechaPagadasTotal??0 }}</td>
            <td style="min-width:150px">{{translate("paid_web")}}</td><td>{{ report_data.usuariosWeb?.apuestasEnFechaPagadasTotal??0 }}</td>
          </tr>
          <tr>
            <td>{{translate("total_sales")}}</td><td>{{ (parseFloat(report_data?.apostadoTaquilla??0) + parseFloat(report_data.usuariosWeb?.apuestasTotal??0)).toFixed(2) }}</td>
            <td>{{translate("total_paid")}}</td><td>{{ (parseFloat(report_data?.apuestasEnFechaPagadasTotal??0) + parseFloat(report_data.usuariosWeb?.apuestasEnFechaPagadasTotal??0)).toFixed(2) }}</td>
            <td></td><td></td>
            <td>{{translate("cost_effectiveness")}}</td><td>{{ ((parseFloat(report_data?.apostadoTaquilla??0) + parseFloat(report_data.usuariosWeb?.apuestasTotal??0)) - (parseFloat(report_data?.apuestasEnFechaPagadasTotal??0) + parseFloat(report_data.usuariosWeb?.apuestasEnFechaPagadasTotal??0))).toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table" :style="skin.my_account.close_btn">
        <thead>
          <tr>
            <th :style="skin.my_account.main_menu_background" colspan="8">{{translate("games_report_taquilla")}}</th>
          </tr>
        </thead>
        <tbody v-if="report_data.casino?.providers.length>0">
          <template v-for="provider in report_data.casino?.providers">
            <tr :key="provider.provider">
              <td colspan="8">{{ provider.provider }}</td>
            </tr>
            <tr v-for="(data,currency) in provider.resumen" :key="currency+provider.provider">
              <td style="min-width:150px">{{translate("coin")}}</td><td>{{ currency }}</td>
              <td style="min-width:150px">{{translate("staked")}}</td><td>{{ parseFloat(data.venta||0).toFixed(2) }}</td>
              <td style="min-width:150px">{{translate("total_paid")}}</td><td>{{ parseFloat(data.pagado||0).toFixed(2) }}</td>
              <td style="min-width:150px">{{translate("net")}}</td><td>{{ (parseFloat(data.venta||0) - parseFloat(data.pagado||0)).toFixed(2) }}</td>
            </tr>
          </template>
        </tbody>
      </table>
      <table class="table" :style="skin.my_account.close_btn">
        <thead>
          <tr>
            <th :style="skin.my_account.main_menu_background" colspan="8">{{translate("games_report")}}</th>
          </tr>
        </thead>
        <tbody v-if="report_data.casinoUsuariosWeb?.providers.length>0">
          <template v-for="provider in report_data.casinoUsuariosWeb?.providers">
            <tr :key="provider.provider">
              <td colspan="8">{{ provider.provider }}</td>
            </tr>
            <tr v-for="(data,currency) in provider.resumen" :key="currency+provider.provider">
              <td style="min-width:150px">{{translate("coin")}}</td><td>{{ currency }}</td>
              <td style="min-width:150px">{{translate("staked")}}</td><td>{{ parseFloat(data.venta||0).toFixed(2) }}</td>
              <td style="min-width:150px">{{translate("total_paid")}}</td><td>{{ parseFloat(data.pagado||0).toFixed(2) }}</td>
              <td style="min-width:150px">{{translate("net")}}</td><td>{{ (parseFloat(data.venta||0) - parseFloat(data.pagado||0)).toFixed(2) }}</td>
            </tr>
          </template>
        </tbody>
      </table>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es/es';
export default {
    name:"reports",
    props:['skin','translation','language'],
    components:{DatePicker},
    data(){
      return {
        date_range:[],
        loading_report:false,
        report_data:{}
      }
    },
    methods:{
      translate(text){
        if (this.$props.translation[text]) {
          return this.$props.translation[text][this.$props.language];
        }
        return text;
      },
      async loadReport(){
        this.loading_report = true
        let dateFrom =this.date_range[0];
        let dateTo = this.date_range[1];
        let timeZoneOffset = new Date().getTimezoneOffset() / 60;
        let timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
        let data = {
          "command":"reporte_history",
          "params":{
            "where":{
              "time_shift":timezoneFormated,
              "from_date":Math.floor(dateFrom.getTime()/1000),
              "to_date":Math.floor(dateTo.getTime()/1000)
              }
            }
          }
        const response = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data);
        if(response.data.result == 'Ok'){
          this.report_data = response.data
        }
        this.loading_report = false;
      },
      format_date: function (ts) {
        let date = new Date(ts * 1000);
        var formated =
          date.getFullYear() +
          "-" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date.getDate()).slice(-2) +
          " " +
          date.getHours() +
          ":" +
          ("0" + date.getMinutes()).slice(-2) +
          ":" +
          ("0" + date.getSeconds()).slice(-2);
        return formated;
      },
    },
    computed:{
      user(){
        return this.$store.getters['getUserData'];
      },
    },
    mounted(){
        let dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate());
        let dateTo = new Date();
        dateTo.setDate(dateTo.getDate());
        this.date_range=[dateFrom,dateTo];
    },
}
</script>

<style>

</style>