<template>
  <div class="mb-1" v-if="skin.header_desktop.enabled" id="mainHeader">
    <div style="position: relative;">
        <span class="m-1" style="position: absolute; right: 20px; top: 10px;">
            <a v-if="skin.redes.facebook.enabled" target="_blank" :href="skin.redes.facebook.url" class="me-2" :style="skin.redes.facebook.btn_style"><i class="fab fa-facebook-square" aria-hidden="true"></i></a>
            <a v-if="skin.redes.instagram.enabled" target="_blank" :href="skin.redes.instagram.url" class="me-2" :style="skin.redes.instagram.btn_style"><i class="fab fa-instagram-square" aria-hidden="true"></i></a>
            <a v-if="skin.redes.tiktok.enabled" target="_blank" :href="skin.redes.tiktok.url" class="me-2" :style="skin.redes.tiktok.btn_style"><i class="fab fa-tiktok"></i></a>
            <a v-if="skin.redes.twitter.enabled" target="_blank" :href="skin.redes.twitter.url" class="me-2" :style="skin.redes.twitter.btn_style"><i class="fab fa-twitter-square" aria-hidden="true"></i></a>
            <a v-if="skin.redes.youtube.enabled" target="_blank" :href="skin.redes.youtube.url" class="me-2" :style="skin.redes.youtube.btn_style"><i class="fab fa-youtube-square" aria-hidden="true"></i></a>
        </span>
    </div>
    <div class="text-center" :style="skin.header_desktop.padding_logo">
        <img v-if="skin.header_desktop.logo != ''" :src="skin.header_desktop.logo" :width="skin.header_desktop.logo_width" alt="">
    </div>
    <div>
        <my-account
            v-bind:skin="skin"
            v-bind:language="language"
            v-bind:translation="translation"
        />
        <!-- Button trigger modal -->
        <button id="login_btn" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#login" style="display:none;"></button>
        <button id="register_btn" class="btn btn-primary" data-bs-toggle="modal" href="#registerModalToggle" style="display:none;"></button>
        <login
            v-bind:skin="skin"
            v-bind:language="language"
            v-bind:translation="translation"
        />
        <button id="chanpass_btn" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#changePassword" style="display:none;"></button>
        <change-password
            v-bind:skin="skin"
            v-bind:language="language"
            v-bind:translation="translation"
            v-bind:user="user"
        />
        <register
            ref="register"
            v-bind:skin="skin"
            v-bind:language="language"
            v-bind:translation="translation"
        />
        <div class="center_div d-flex" :style="skin.header_desktop.nav_bar">
                <div v-if="skin.home!=null?skin.home:true" :style="'width:'+(100/header_productsQty)+'%'" style="cursor:pointer;" class="py-1 pb-1 px-2 pe-2 mx-3 text-center">
                    <div v-if="skin.front_lobby.enabled" @click="change_view('lobby')" style="cursor:pointer;">
                        <span :style="skin.header_desktop.nav_bar_text"><i class="fas fa-home"></i><br>{{ translate('home') }}</span>
                    </div>
                    <router-link v-else
                        :to="user.token == '' ? { name: 'MainEcuabets',query: { token: user.token+user.site_id}} : { name: 'Main_ecuabets' ,  query: { token: user.token+user.site_id} }"
                    >
                        <span :style="skin.header_desktop.nav_bar_text"><i class="fas fa-home"></i><br>{{ translate('home') }}</span>
                    </router-link>
                </div>
                <div v-if="skin.prematch!=null?skin.prematch:true" :style="'width:'+(100/header_productsQty)+'%'" @click="change_view('main')" style="cursor:pointer;display:table;" class="px-2 pe-2 text-center">
                    <router-link style="display:table-cell;vertical-align:middle;"
                        :to="user.token == '' ? { name: 'MainEcuabets',query: { token: user.token+user.site_id}} : { name: 'Main_ecuabets' ,  query: { token: user.token+user.site_id} }"
                    >
                        <span :style="skin.header_desktop.nav_bar_text">{{translate("sports_forecasts")}}</span>
                    </router-link>
                </div>
                <div v-if="skin.live!=null?skin.live:true" :style="'width:'+(100/header_productsQty)+'%'" style="cursor:pointer;display:table;" @click="change_view('main')" class="px-2 pe-2 text-center">
                    <router-link style="display:table-cell;vertical-align:middle;"
                        :to="user.token == '' ? { name: 'LiveMainEcuabets',query: { token: user.token+user.site_id}} : { name: 'Live_ecuabets' ,  query: { token: user.token+user.site_id} }"
                    >
                        <span :style="skin.header_desktop.nav_bar_text">{{translate("live_forecasts")}}</span>
                    </router-link>
                </div>
                <div v-if="skin.poker" :style="'width:'+(100/header_productsQty)+'%'" @click="user.token != '' ? change_view('poker') : login()" style="cursor:pointer;display:table;" class="px-2 pe-2 text-center">
                    <router-link style="display:table-cell;vertical-align:middle;"
                        :to="user.token == '' ? { name: 'MainEcuabets',query: { token: user.token+user.site_id}} : { name: 'Main_ecuabets' ,  query: { token: user.token+user.site_id} }"
                    >
                        <span :style="skin.header_desktop.nav_bar_text">POKER</span>
                    </router-link>
                </div>
                <div v-if="skin.casinoN" :style="'width:'+(100/header_productsQty)+'%'" @click="user.token != '' ? change_view('casino') : login()" style="cursor:pointer;display:table;" class="px-2 pe-2 text-center">
                    <router-link style="display:table-cell;vertical-align:middle;"
                        :to="user.token == '' ? { name: 'MainEcuabets',query: { token: user.token+user.site_id}} : { name: 'Main_ecuabets' ,  query: { token: user.token+user.site_id} }"
                    >
                        <span :style="skin.header_desktop.nav_bar_text">{{translate("casino")}}</span>
                    </router-link>
                </div>
                <div v-if="skin.casino_live" :style="'width:'+(100/header_productsQty)+'%'" @click="user.token != '' ? change_view('casino_live') : login()" style="cursor:pointer;display:table;" class="px-2 pe-2 text-center">
                    <span :style="skin.header_desktop.nav_bar_text" style="display:table-cell;vertical-align:middle;">{{translate("casino_live")}}</span>
                </div>
                <div v-if="skin.hipicas" :style="'width:'+(100/header_productsQty)+'%'" @click="user.token != '' ? change_view('hipicas') : login()" style="cursor:pointer;display:table;" class="px-2 pe-2 text-center">
                    <span :style="skin.header_desktop.nav_bar_text" style="display:table-cell;vertical-align:middle;">{{translate("horse_riding")}}</span>
                </div>

                <div v-if="user.token == '' && skin.register" @click="register" style="cursor:pointer;display:table;" :style="skin.header_desktop.register_btn+'width:'+(100/header_productsQty)+'%'" class="px-2 pe-2 text-center">
                    <span style="display:table-cell;vertical-align:middle;">{{translate("sign_in")}}</span>
                </div>
                <div v-if="user.token != ''" :style="'width:'+(100/header_productsQty)+'%;'+skin.header_desktop.saldo" class="py-0 pb-0 px-2 pe-2 text-center" style="cursor:pointer;display:table;">
                    <div @click="changeBalanceView" style="display:table-cell;vertical-align:middle;">
                        <span>{{translate(balance.view=="SALDO"?"balance":"available")}}</span><br>
                        <div v-if="skin.conversion ? (skin.conversion.enabled ? true : false) : false">
                            <span :style="skin.conversion.text">{{ user.user_data.data ? new Intl.NumberFormat("de-DE").format(balanceParsed(balance.saldo,currency.fiat),{minimumFractionDigits: skin.qBet_config.decimalsQty,maximumFractionDigits: skin.qBet_config.decimalsQty})+' '+currency.fiat : 0 }}</span>
                        </div>
                        <span>{{ user.user_data.data ? new Intl.NumberFormat("de-DE",{minimumFractionDigits: skin.qBet_config.decimalsQty,maximumFractionDigits: skin.qBet_config.decimalsQty}).format(balance.saldo)+' '+user.user_data.data.currency : 0 }}</span>
                        <!-- <span :style="skin.conversion.text">{{ user.user_data.data ? new Intl.NumberFormat("de-DE").format(user.user_data.data.bonus_balance)+' '+user.user_data.data.currency : 0 }}</span> -->
                    </div>
                </div>
                
                <div v-if="user.token == '' && skin.login" @click="login" style="cursor:pointer;display:table;" :style="skin.header_desktop.login_btn+'width:'+(100/header_productsQty)+'%'" class="px-2 pe-2 text-center">
                    <span style="display:table-cell;vertical-align:middle;">{{translate("log_in")}}</span>
                </div>
                <div v-if="user.token != ''" :style="'width:'+(100/header_productsQty)+'%'" style="cursor:pointer;" class="py-1 me-2 pb-1 px-2 pe-2 text-center nav-item dropdown">
                    <a :style="skin.header_desktop.nav_bar_text" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {{translate("services")}}
                    </a>
                    <div :style="skin.header_desktop.servicios" class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <div class="px-2 pe-2">
                            <span :style="skin.header_desktop.servicios_text">{{ user.user_data.data ? user.user_data.data.name : '' }}</span><br>
                            <hr class="m-1 p-0" style="color:white">
                        </div>
                        <div class="px-2 pe-2 p-1" :style="skin.header_desktop.servicios_text2" data-bs-toggle="modal" data-bs-target="#myAccount"> <span>{{translate("my_account")}}</span> </div>
                        <div @click="terms" class="px-2 pe-2 p-1" :style="skin.header_desktop.servicios_text2"> <span>{{translate("terms_and_conditions")}}</span> </div>
                        <a @click="change_view('results')" class="px-2 pe-2 p-1" :style="skin.header_desktop.servicios_text2"> <span>{{translate("results")}}</span> </a>
                        <div v-if="skin.logout" @click="chanpass" class="px-2 pe-2 p-1" :style="skin.header_desktop.servicios_text2"> <span>{{translate("change_password")}}</span> </div>
                        <div v-if="skin.logout" @click="logout" class="px-2 pe-2 p-1" :style="skin.header_desktop.servicios_text2"> <span>{{translate("leave")}}</span> </div>
                    </div>
                </div>
                <div style="cursor:pointer;" class="px-2 pe-2 text-center nav-item dropdown">
                    <language-selector
                        v-bind:skin="skin"
                        v-bind:language="language"
                    />
                </div>
        </div>
    </div>
  </div>
</template>

<script>
import myAccount from "./my_account.vue";
import login from "./login.vue";
import register from './register.vue';
import changePassword from './change_password.vue';
import languageSelector from './language.vue';
export default {
    name:"mainHeader",
    props:['skin','language','translation'],
    data(){
        return {
            balance:{
                saldo:0,
                conversion:0,
                view:"SALDO",
                switch:1
            }
        }
    },
    components:{
        myAccount,
        login,
        register,
        changePassword,
        languageSelector
    },
    methods:{
        balanceParsed(val,fiat){
            let tasa = 1;
            if(this.$props.skin.conversion ? (this.$props.skin.conversion.enabled ? true : false) : false){
                tasa = this.currency.rate;
            }
            return (fiat!='BETC'?(fiat=='USDT'?val*tasa:val*tasa*this.user.user_data.data.tasa):val).toFixed(2);
        },
        terms(){
            let url = "";
            let lang = this.$props.language=='es'?'spa':(this.$props.language=='en'?'eng':(this.$props.language=='pt'?'pt-br':'spa'));
            if(this.user.prefix=="external"){
                url = "https://docs.betsw3.xyz/terms/"+lang+"/?site="+this.user.site.toUpperCase()+"&link="+this.user.site;
            }
            else{
                let domain_formated = this.user.domain.split('//')[1]; 
                //let domain_formated = "admin.championred.bet";
                let domain_name1 = domain_formated.split('.')[1].toUpperCase();
                let domain_name2 = domain_formated.split('.')[1]+'.'+domain_formated.split('.')[2];
                url = "https://docs.betsw3.xyz/terms/"+lang+"/?site="+domain_name1+"&link="+domain_name2;
            }
            
            window.open(url, '_blank').focus();
        },
        login(){
            document.getElementById('login_btn').click();
        },
        register(){
            document.getElementById('register_btn').click();
        },
        chanpass(){
            document.getElementById('chanpass_btn').click();
        },
        logout(){
            this.$store.dispatch('logOut');
            this.$root.$emit('forceRender');
        },
        my_account(){
            window.parent.postMessage(
            {
                action: 'openSlider',
                tab: 'recentBets'
            },
            '*'
            );
        },
        change_view(view){
            if(view == "casino_live"){
                window.postMessage({view: 'casino_live'},'*');
            }
            else if(view == "hipicas"){
                // let url = this.config.SkinCConfig.racing.horse.url+'?tokenUser='+this.user.token+this.user.site_id+'&tokenClient=72b0fc93-fdd1-4c08-b1fd-0be81726a5d2&lang=es&cashierUrl='+this.user.domain+'&homeUrl='+this.user.domain;
                //  window.open(url,'_blank');
                window.postMessage({view: 'hipicas'},'*');
            }
            else{
                this.$store.dispatch('setLobbyView',view);
                this.$store.dispatch('setView',view);
                window.postMessage({view: view},'*');
            }
        },
        translate(text) {
            if(text){
                if (this.$props.translation[text]) {
                    return this.$props.translation[text][this.$props.language];
                }
                else{
                    var new_text_serched = text.replace(/ /g, "_");
                    if (this.$props.translation[new_text_serched]) {
                        return this.$props.translation[new_text_serched][this.$props.language];
                    }
                }
                return text;
            }
        },
        changeBalanceView(){
            let balance = 0;
            let disponible = 0
            if(this.config.SkinConfig.main.newbonus ? this.$store.getters['getConfig'].SkinConfig.main.newbonus : false){
                balance = this.user.user_data.data.available_balance + this.user.user_data.data.bonus + this.user.user_data.data.frozen;
                disponible = this.user.user_data.data.available_balance;
            }
            else{
                balance = this.user.user_data.data.balance;
                disponible = this.user.user_data.data.bonus_balance;
            }
            let tasa = 1;
            if(this.$props.skin.conversion ? (this.$props.skin.conversion.enabled ? true : false) : false){
                tasa = this.currency.rate;
            }
            if(this.balance.switch == 0){
                this.balance.saldo = balance;
                this.balance.conversion = (this.currency.fiat!='BETC'?(this.currency.fiat=='USDT'?balance*tasa:balance*tasa*this.user.user_data.data.tasa):balance).toFixed(2);
                this.balance.view = "SALDO";
                this.balance.switch = 1;
            }
            else{
                this.balance.saldo = disponible;
                this.balance.conversion = (this.currency.fiat!='BETC'?(this.currency.fiat=='USDT'?balance*tasa:balance*tasa*this.user.user_data.data.tasa):balance).toFixed(2);
                this.balance.view = "DISPONIBLE";
                this.balance.switch = 0;
            }
        }
    },
    computed:{
         user(){
            let config = this.$store.getters['getConfig'];
            let data = this.$store.getters['getUserData'];
            if(data.user_data.data){
                this.balance.saldo = config.SkinConfig.main.newbonus ? data.user_data.data.available_balance + data.user_data.data.bonus + data.user_data.data.frozen: data.user_data.data.balance;
                this.balance.conversion = data.user_data.data.balance * data.user_data.data.tasa;
            }
            return data;
         },
         currency(){
            return this.$store.getters['getCurrency'];
         },
         header_productsQty(){
            let products = [this.$props.skin.poker,this.$props.skin.prematch,this.$props.skin.casinoN,this.$props.skin.casino_live,this.$props.skin.hipicas,this.$props.skin.login,this.$props.skin.logout];
            return products.filter(x => x==true).length + 1;
        },
        config(){
            return this.$store.getters['getConfig'];
        },
     },
     mounted(){
        this.$store.dispatch("setHeaderHeight",document.getElementById("mainHeader").offsetHeight);
     }
}
</script>

<style scoped>
    .center_div{
        width: 90%; /* Can be in percentage also. */
        height: auto;
        margin: 0 auto;
        padding: 0px;
        position: relative;
    }
</style>