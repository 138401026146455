<template>
  <div :style="skin.sport_view.frame" class="m-1">
      <div class="m-1">
        <navigation-bar
          v-bind:skin="skin"
          v-bind:translation="translation"
          v-bind:language="language"
          v-bind:navigation="[{name:sport_selected.name,link:''},{name:translate('now_upcoming'),link:''}]"
        />
      </div>
      <div :style="nav_activeCss">
        <ul class="nav p-1 mx-1 me-1" :style="skin.sport_view.navigation">
            <li 
                :id="'pills-prematch'"
                data-bs-toggle="pill"
                :data-bs-target="'#pills-prematch-tab'"
                role="tab"
                :aria-controls="'pills-prematch'"
                aria-selected="true"
                style="cursor:pointer"
                v-on:click="active('pills-prematch')"
                class="nav_active1 item nav-item">
                <span class="m-2">{{translate("upcoming").toUpperCase()}}</span>
            </li>
            <li 
                :id="'pills-live'"
                data-bs-toggle="pill"
                :data-bs-target="'#pills-live-tab'"
                role="tab"
                :aria-controls="'pills-live'"
                aria-selected="true"
                style="cursor:pointer"
                v-on:click="active('pills-live')"
                class="item nav-item">
                <span class="m-2">{{translate("live").toUpperCase()}}</span>
            </li>
        </ul> 
      </div>
      <div>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-prematch-tab"
            role="tabpanel"
            aria-labelledby="pills-prematch"
          >
            <prematch
                v-if="prematchTarget==1"
                v-bind:skin="skin"
                v-bind:language="language"
                v-bind:time="time"
                v-bind:translation="translation"
                v-bind:menu_visible="false"
                ref="prematch"
            ></prematch>
            <prematch-2
                v-else
                v-bind:skin="skin"
                v-bind:language="language"
                v-bind:translation="translation"
                v-bind:menu_visible="false"
                ref="prematch2"
            >
            </prematch-2>
          </div>
          <div
            class="tab-pane fade"
            id="pills-live-tab"
            role="tabpanel"
            aria-labelledby="pills-live"
          >
            <live
                v-bind:skin="skin"
                v-bind:language="language"
                v-bind:translation="translation"
                v-bind:menu_visible="false"
                ref="live"
            ></live>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import navigationBar from './navigation_bar.vue';
import prematch from '../../main_lobby/main_data/main_prematch.vue';
import prematch2 from '../../main_lobby/main_data/main_prematch2.vue';
import live from '../../main_lobby/main_data/main_live.vue';
export default {
  name:"sport_view",
  components:{navigationBar,prematch,live,prematch2},
  props:["skin","translation","language","sport_selected"],
  methods:{
    active: function (id) {
      let items = document.getElementsByClassName("item");
      [...items].forEach(item=>{
          item.classList.remove('nav_active1');
          item.classList.remove('active');
      });
      document.getElementById(id).classList.add('nav_active1');
      document.getElementById(id).classList.add('active');
    },
    sport(){
      this.$refs.liga.forEach(element => {
        element.check_liga();
      });
    },
    translate(text) {
        if (this.$props.translation[text]) {
            return this.$props.translation[text][this.$props.language];
        }
        return text;
    }
  },
  computed:{
    time(){
      return this.$store.getters['prematch/getTime'];
    },
    prematchTarget(){
      return this.$store.getters['getPrematchTarget'];
    },
    nav_activeCss(){
      return {
        '--bar':this.$props.skin.sport_view.nav_active_bar,
        '--text':this.$props.skin.sport_view.nav_active_text,
      }
    },
  },
  mounted(){
    if(this.$refs.prematch){
      this.$refs.prematch.select_sport(this.$props.sport_selected.id_prematch);
    }
    if(this.$refs.prematch2){
      this.$refs.prematch2.select_sport(this.$props.sport_selected.id_live);
    }
    this.$refs.live.select_sport(this.$props.sport_selected.id_live);
  }
}
</script>

<style>
  .nav_active1{
    color:var(--text);
    border-bottom:var(--bar);
  }
</style>