<template>
  <div>
    <div class="m-1" v-if="user_id.nav_bar">
        <navigation-bar
          v-bind:skin="skin"
          v-bind:translation="translation"
          v-bind:language="language"
          v-bind:navigation="[{name:'HISTORIAL',link:''}]"
        />
    </div>
    <div class="m-1 p-1 text-center" :style="skin.qbet.fondo3">
        <div class="row p-2">
            <div class="col-12 col-lg-4"><input class="form-control p-1 mb-1" :placeholder="translate('ticket_id')" type="number" v-model="ticket_id"/></div>
            <div class="col-12 col-lg-4"><date-picker v-model="date_range" type="date" range placeholder="" style="max-width:100%;" class="mb-2" ></date-picker></div>
            <div class="col-12 col-lg-4"><button @click="get_tracking('from_scratch')" :style="skin.qbet.booking_btn" class="p-1 text-center mb-2" style="width:50%;color:white" :disabled="loading_tracking">{{translate("show")}}</button></div>
        </div>
        <div :style="hoverCss" class="row px-2">
            <div 
                :class="ticket_status==-1?'hover':''" 
                @click="select_status(-1)" style="cursor:pointer;min-width:100px;" 
                id="allTickets"
                @mouseover="ticket_status==-1?'':hover('allTickets')" 
                @mouseleave="ticket_status==-1?'':hover2('allTickets')" 
                :style="skin.casino.button" class="m-1 mx-0 mx-0 p-1 text-center col-4 col-lg-2">{{translate("all")}}
            </div>
            <div 
                :class="ticket_status==1?'hover':''" 
                @click="select_status(1)" style="cursor:pointer;min-width:100px;" 
                id="pendingTickets" 
                @mouseover="ticket_status==1?'':hover('pendingTickets')" 
                @mouseleave="ticket_status==1?'':hover2('pendingTickets')" 
                :style="skin.casino.button" class="m-1 mx-0 p-1 text-center col-4 col-lg-2">{{translate("pendings")}}
            </div>
            <div 
                :class="ticket_status==2?'hover':''" 
                @click="select_status(2)" style="cursor:pointer;min-width:100px;" 
                id="wonTickets" 
                @mouseover="ticket_status==2?'':hover('wonTickets')" 
                @mouseleave="ticket_status==2?'':hover2('wonTickets')" 
                :style="skin.casino.button" class="m-1 mx-0 p-1 text-center col-4 col-lg-2">{{translate("won")}}
            </div>
            <div 
                :class="ticket_status==4?'hover':''" 
                @click="select_status(4)" style="cursor:pointer;min-width:100px;" 
                id="lostTickets" 
                @mouseover="ticket_status==4?'':hover('lostTickets')" 
                @mouseleave="ticket_status==4?'':hover2('lostTickets')" 
                :style="skin.casino.button" class="m-1 mx-0 p-1 text-center col-4 col-lg-2">{{translate("losses")}}
            </div>
            <div 
                :class="ticket_status==7?'hover':''" 
                @click="select_status(7)" style="cursor:pointer;min-width:100px;" 
                id="cashoutTickets" 
                @mouseover="ticket_status==7?'':hover('cashoutTickets')" 
                @mouseleave="ticket_status==7?'':hover2('cashoutTickets')" 
                :style="skin.casino.button" class="m-1 mx-0 p-1 text-center col-4 col-lg-2">CashOut
            </div>
            <div 
                :class="ticket_status==0?'hover':''" 
                @click="select_status(0)" style="cursor:pointer;min-width:100px;" 
                id="cancelledTickets" 
                @mouseover="ticket_status==0?'':hover('cancelledTickets')" 
                @mouseleave="ticket_status==0?'':hover2('cancelledTickets')" 
                :style="skin.casino.button" class="m-1 mx-0 p-1 text-center col-4 col-lg-2">{{translate("returned")}}
            </div>
        </div>
    </div>
    <div v-if="Object.keys(bet_tracking.tickets).length>0" class="py-1 m-1" :style="skin.qbet.fondo3" style="min-height:76vh;">
        <div class="p-2 text-center" v-if="user.user_data.data">
            <span>{{ user.user_data.data.rol=='RESELLER' ? translate('sold') : translate('staked') }} {{ sold }}</span>&nbsp;&nbsp;
            <span>{{ user.user_data.data.rol=='RESELLER' ? translate('paid') : translate('won2') }} {{ paid }}</span>&nbsp;&nbsp;
            <span v-if="user.user_data.data.rol=='RESELLER'">Total: {{ (sold - paid).toFixed(2) }}</span>
        </div>
        <div v-if="filtered_tracking.length>0">
            <tracking
                v-for="(ticket, index) in filtered_tracking" :key="ticket.id"
                v-bind:ticket_data="ticket"
                v-bind:Skin="skin"
                v-bind:language="language"
                v-bind:translation="translation"
                v-bind:index="index"
                v-bind:currentOdds="bet_tracking.currentOdds"
                v-bind:source="source"
            />
        </div>
        <div v-else class="text-center p-3">
            {{translate("no_search_results")}}
        </div>
    </div>
    <div v-else style="width:100%" class="text-center p-2" :style="skin.qbet.fondo3">
        <div class="spinner-border" :style="skin.loader" role="status">
        <span class="visually-hidden">Loading...</span>
        </div>
    </div>
  </div>
</template>

<script>
import navigationBar from './navigation_bar.vue';
import tracking from '../../main_bet/tracking.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es/es';
export default {
    name:"main_records",
    props:['skin','translation','language','user_id','source'],
    components:{navigationBar,tracking,DatePicker},
    data(){
        return {
            interval:"",
            bet_tracking:{
                "tickets":{},
                "currentOdds":{}
            },
            loading_tracking:false,
            filtered_tracking:{},
            ticket_status:-1,
            date_range:[],
            ticket_id:"",
            sold:0,
            paid:0
        }
    },
    computed:{
        hoverCss(){
            return {
                '--fondoH':this.$props.skin.prematch_all_odds.hover_fondo,
                '--textH':this.$props.skin.prematch_all_odds.hover_text,
            }
        },
        user(){
            return this.$store.getters['getUserData'];
        },
    },
    methods:{
        async get_tracking(type){
            let dateFrom =this.date_range[0];
            let dateTo = this.date_range[1];
            let data = {
                from:Math.floor(dateFrom.getTime()/1000),
                to:Math.floor(dateTo.getTime()/1000),
            }
            if(type=="from_scratch"){
                this.bet_tracking = {
                    "tickets":{},
                    "currentOdds":{}
                }
            }
            if(this.$props.user_id.id){
                data['user_id'] = this.$props.user_id.id;
            }
            if(this.ticket_id!=""){
                data['bet_id'] = this.ticket_id;
            } 
            let tracking = await this.$store.dispatch('getTracking',data);
            let odd_ids = [];
            this.sold = parseFloat(tracking.data.vendido ? tracking.data.vendido : 0).toFixed(this.$props.skin.qBet_config.decimalsQty);
            this.paid = parseFloat(tracking.data.pagado ? tracking.data.pagado : 0).toFixed(this.$props.skin.qBet_config.decimalsQty);
            tracking.data.bets.forEach(pendingT=>{
                pendingT.events.forEach(odd=>{
                    if(odd.status==1){
                        odd_ids.push(odd.id);
                    }
                })
            })
            odd_ids = [...new Set(odd_ids)];
            let currentOddData = await this.$store.dispatch('getOddData',odd_ids);
            tracking.data.bets = tracking.data.bets.reverse()
            this.bet_tracking.tickets = tracking;
            this.bet_tracking.currentOdds = currentOddData;
            let filtered = [];
            if(this.ticket_status != -1){
                tracking.data.bets.forEach(ticket=>{
                    if(this.ticket_status == 2){
                        if(ticket.status==2 || ticket.status==5 || ticket.status==3){
                            filtered.push(ticket);
                        }
                    }
                    else{
                        if(ticket.status==this.ticket_status){
                            filtered.push(ticket);
                        }
                    }
                });
                this.filtered_tracking = filtered;
            }
            else{
                this.filtered_tracking = tracking.data.bets;
            }
        },
        hover: function (id) {
            document.getElementById(id).classList.add('hover');
        },
        hover2: function (id) {
            document.getElementById(id).classList.remove('hover');
        },
        select_status(status){
            clearInterval(this.interval);
            this.ticket_status = status;
            let self = this;
            if(this.ticket_status==-1 || this.ticket_status == 1){
                this.interval = setInterval(function(){
                    self.get_tracking('');
                },60000)
            }
            this.get_tracking('from_scratch');
        },
        translate(text) {
            if(text){
                if (this.$props.translation[text]) {
                    return this.$props.translation[text][this.$props.language];
                }
                else{
                    var new_text_serched = text.replace(/ /g, "_");
                    if (this.$props.translation[new_text_serched]) {
                        return this.$props.translation[new_text_serched][this.$props.language];
                    }
                }
                return text;
            }
        }
    },
    mounted(){
        let dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate()-1);
        let dateTo = new Date();
        dateTo.setDate(dateTo.getDate());
        this.date_range=[dateFrom,dateTo];
        this.get_tracking('');
        let self = this;
        this.interval = setInterval(function(){
            self.get_tracking('');
        },60000)
    },
    destroyed(){
        clearInterval(this.interval);
    }
}
</script>

<style scoped>
.hover{
    background-color: var(--fondoH) !important;
    color:var(--textH) !important;
}
</style>